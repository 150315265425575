//const publicUrl= process.env.PUBLIC_URL;

/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import icon24 from "../assets/images/icons/24h.svg";
import air from "../assets/images/icons/air.svg";
import calendar from "../assets/images/icons/calendar.svg";
import email from "../assets/images/icons/email.svg";
import euro from "../assets/images/icons/euro-sign.svg";
import facebook from "../assets/images/icons/facebook.svg";
import instagram from "../assets/images/icons/instagram.svg";
import insonorise from "../assets/images/icons/insonorise.svg";
import linkedin from "../assets/images/icons/linkedin.svg";
import location from "../assets/images/icons/location.svg";
import location2 from "../assets/images/icons/location2.svg";
import time from "../assets/images/icons/time.svg";
import wifi from "../assets/images/icons/wifi.svg";
import clock from "../assets/images/icons/clock.svg";
import map from "../assets/images/icons/map.svg";
import phone from "../assets/images/icons/phone.svg";
import phone2 from "../assets/images/icons/phone2.svg";
import mastercard from "../assets/images/icons/mastercard.svg";
import twitter from "../assets/images/icons/twitter.svg";
import prise from "../assets/images/icons/prise.svg";
import tv from "../assets/images/icons/tv.svg";
import profile from "../assets/images/icons/profile.svg";
import place from "../assets/images/icons/place.svg";

import mastercard2 from "../assets/images/icons/mastercard2.svg";
import cb from "../assets/images/icons/cb.svg";
import amex from "../assets/images/icons/amex.svg";
import visa from "../assets/images/icons/visa.svg";

export const Mastercard2Icon = () => {
  return <img src={mastercard2} className="w-7 h-7" alt="mastercard2-icon" />;
};

export const CBIcon = () => {
  return <img src={cb} className="w-7 h-7" alt="cb-icon" />;
};

export const AmexIcon = () => {
  return <img src={amex} className="w-7 h-7" alt="amex-icon" />;
};

export const VisaIcon = () => {
  return <img src={visa} className="w-7 h-7" alt="visa-icon" />;
};

export const H24Icon = () => {
  return <img src={icon24} alt="24h-icon" />;
};

export const AirIcon = () => {
  return <img src={air} alt="air-icon" />;
};

export const CalendarIcon = () => {
  return <img src={calendar} alt="calendar-icon" />;
};

export const EmailIcon = () => {
  return <img src={email} alt="email-icon" />;
};

export const EuroIcon = () => {
  return <img src={euro} alt="euro-icon" />;
};

export const FacebookIcon = () => {
  return <img src={facebook} alt="facebook-icon" />;
};

export const InsonoriseIcon = () => {
  return <img src={insonorise} alt="insonorise-icon" />;
};

export const InstagramIcon = () => {
  return <img src={instagram} alt="instagram-icon" />;
};

export const LinkedinIcon = () => {
  return <img src={linkedin} alt="linkedin-icon" />;
};

export const LocationIcon = () => {
  return <img src={location} alt="location-icon" />;
};

export const Location2Icon = () => {
  return <img src={location2} alt="location-icon" />;
};

export const TimeIcon = () => {
  return <img src={time} alt="time-icon" />;
};

export const PhoneIcon = () => {
  return <img src={phone} alt="phone-icon" />;
};

export const Phone2Icon = () => {
  return <img src={phone2} alt="phone-icon" />;
};

export const PriseIcon = () => {
  return <img src={prise} alt="prise-icon" />;
};

export const PlaceIcon = () => {
  return <img src={place} alt="place-icon" />;
};

export const ProfileIcon = () => {
  return <img src={profile} width="85" height="85" alt="profile-icon" />;
};

export const TvIcon = () => {
  return <img src={tv} alt="tv-icon" />;
};

export const TwitterIcon = () => {
  return <img src={twitter} alt="twitter-icon" />;
};

export const WifiIcon = () => {
  return <img src={wifi} alt="wifi-icon" />;
};

export const ArrowLeftIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-8 cursor-pointer h-8"
    >
      <path
        fillRule="evenodd"
        d="M7.72 12.53c.19-.19.29-.44.29-.71s-.1-.52-.29-.71L4.95 8.21a1.01 1.01 0 0 1 0-1.42l1.41-1.42a1.01 1.01 0 0 1 1.42 0l3.59 3.59a1.01 1.01 0 0 1 0 1.42l-3.59 3.59a1.01 1.01 0 0 1-1.42 0L6.01 13c-.19-.19-.29-.44-.29-.71z"
      />
    </svg>
  );
};

export const ArrowRightIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className="w-8 h-8 cursor-pointer"
    >
      <path
        fillRule="evenodd"
        d="M16.28 11.47a.75.75 0 010 1.06l-7.5 7.5a.75.75 0 01-1.06-1.06L14.69 12 7.72 5.03a.75.75 0 011.06-1.06l7.5 7.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const SearchIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="w-6 h-6"
    >
      <circle cx="11" cy="11" r="8" />
      <path d="M21 21l-4.35-4.35" />
    </svg>
  );
};

export const ClockIcon = () => {
  return <img src={clock} alt="clock-icon" width={35} />;
};

export const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-6 h-6 text-lightGreen"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M4.5 12.75l6 6 9-13.5"
      />
    </svg>
  );
};

export const CloseIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className={`w-8 h-8 ${color ? color : "text-lightGreen"}`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  );
};

export const BackIcon = () => {
  return (
    <div className="border-2 border-lightGreen  rounded-full p-1 text-darkBlue reverse">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-7 h-7"
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        />
      </svg>
    </div>
  );
};

export const RemoveIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="w-7 h-7 text-lightGreen"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
      />
    </svg>
  );
};

export const MapIcon = () => {
  return <img src={map} className="w-7 h-7" alt="map-icon" />;
};

export const MastercardIcon = () => {
  return <img src={mastercard} className="w-7 h-7" alt="card-icon" />;
};

export const ArrowBottom = () => {
  return (
    <svg
      className="w-5 h-5 "
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 15l7-7 7 7"
      />
    </svg>
  );
};

export const ArrowTop = () => {
  return (
    <svg
      className="w-5 h-5"
      fill="none"
      stroke="currentColor"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M19 9l-7 7-7-7"
      />
    </svg>
  );
};

export const ShowBox = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#2271b1"
      height="1.5em"
      width="1.5em"
      className="showPassword"
    >
      <path d="M12 9a3.02 3.02 0 00-3 3c0 1.642 1.358 3 3 3 1.641 0 3-1.358 3-3 0-1.641-1.359-3-3-3z" />
      <path d="M12 5c-7.633 0-9.927 6.617-9.948 6.684L1.946 12l.105.316C2.073 12.383 4.367 19 12 19s9.927-6.617 9.948-6.684l.106-.316-.105-.316C21.927 11.617 19.633 5 12 5zm0 12c-5.351 0-7.424-3.846-7.926-5C4.578 10.842 6.652 7 12 7c5.351 0 7.424 3.846 7.926 5-.504 1.158-2.578 5-7.926 5z" />
    </svg>
  );
};

export const HideBox = () => {
  return (
    <svg
      viewBox="0 0 24 24"
      fill="#2271b1"
      height="1.5em"
      width="1.5em"
      className="showPassword"
    >
      <path d="M12 19c.946 0 1.81-.103 2.598-.281l-1.757-1.757c-.273.021-.55.038-.841.038-5.351 0-7.424-3.846-7.926-5a8.642 8.642 0 011.508-2.297L4.184 8.305c-1.538 1.667-2.121 3.346-2.132 3.379a.994.994 0 000 .633C2.073 12.383 4.367 19 12 19zm0-14c-1.837 0-3.346.396-4.604.981L3.707 2.293 2.293 3.707l18 18 1.414-1.414-3.319-3.319c2.614-1.951 3.547-4.615 3.561-4.657a.994.994 0 000-.633C21.927 11.617 19.633 5 12 5zm4.972 10.558l-2.28-2.28c.19-.39.308-.819.308-1.278 0-1.641-1.359-3-3-3-.459 0-.888.118-1.277.309L8.915 7.501A9.26 9.26 0 0112 7c5.351 0 7.424 3.846 7.926 5-.302.692-1.166 2.342-2.954 3.558z" />
    </svg>
  );
};
