/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { Close } from "@mui/icons-material";
import Button from "./Button";
import {NavLink } from "react-router-dom";
import { useState } from "react";
import { useTranslation } from "react-i18next";
//import ReactGA from "react-ga4";
//const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // TRACKING_ID

const Cookie = ()=>{
    const {t} = useTranslation();
    const [showCookieConsent,setCookieConsent] = useState(localStorage.getItem("CookieConsent"));


    const onAccept = ()=>{
        localStorage.setItem("CookieConsent","agree")
        setCookieConsent("agree")
      //  ReactGA.initialize(TRACKING_ID);
       // report page view
     // ReactGA.send({ hitType: "pageview", page: window.location.pathname});

     const scriptHead = `
     (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MJJQ72N');
      `;   
      
      
      const scriptElement = document.createElement('script');
      scriptElement.innerHTML =scriptHead;
      const headElement = document.getElementsByTagName('head')[0];
      headElement.insertBefore(scriptElement, headElement.firstChild);
    
     // Create a new noscript element
    let noscript = document.createElement('noscript');
    const iframe = document.createElement("iframe");
    iframe.src="https://www.googletagmanager.com/ns.html?id=GTM-MJJQ72N"
    iframe.height=0;
    iframe.width=0;
    iframe.style.display="none";
    iframe.style.visibility="hidden";
    
    
    noscript.appendChild(iframe);
    // Set the noscript content
    
    
    // Select the body element
    const body = document.body || document.getElementsByTagName('body')[0];
    
    // Insert the noscript at the beginning of the body
    body.insertBefore(noscript, body.firstChild);

    }

    const onRefuse = ()=>{
        localStorage.setItem("CookieConsent","decline")
        setCookieConsent("decline")
    }
    return(
    
<>{!showCookieConsent && (<div className="fixed rounded-md bottom-0 right-0 lg:bottom-2 lg:right-2  py-3 pb-8 sm:pb-3 px-4 bg-white border border-['#f2f2f2'] text-black boxShadowCookie lg:w-45 ll:w-33 ">
<div className="flex justify-between align-middle">
<span className="text-bg-modalLang-1 mr-2 text-lg mention-title">{t("cookie.gerer")}</span>
<Close onClick={onRefuse} className="cursor-pointer !text-3xl bg-whiteSmoke hover:bg-[#e0e0e0] text-black"/>
</div>
<p className="my-4 text-sm text-['#222222']">
{t("cookie.text")}
</p>
<div className="flex flex-col lg:flex-row justify-center align-middle">
<Button onClick={onAccept} btnContent={t("cookie.accepter")} customStyle="bg-lightGreen my-2 lg:m-2 text-black hover:bg-darkGreen rounded capitalize"></Button>
<Button onClick={onRefuse} btnContent={t("cookie.refuser")} customStyle="bg-[#f9f9f9] my-2 rounded lg:m-2 hover:bg-[#e0e0e0] text-black"></Button>
</div>
<NavLink className="text-darkGreen hover:text-lightGreen underline font-bold mt-4 block text-center" to="/cgu">{t("cookie.cgu")}</NavLink>
</div>
   )}
      </>
    );
  };
    


export default Cookie;