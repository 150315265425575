/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useState, useEffect } from "react";
import Button from "../ui/Button";
import { createPortal } from "react-dom";
import Loader from "../ui/Loader";
import Toast from "../ui/Toast";
import { useNavigate } from "react-router";
//import { useOutsideClick } from "../../utils/hooks";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { isValidPhoneNumber } from "../../utils/utils";
import PhoneInput from "react-phone-input-2";
import { useTranslation } from "react-i18next";
import "react-phone-input-2/lib/style.css";

const ModalTwoSteps = ({ show, onClose, type, newPhone }) => {
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(show);
  const loader = false;
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [codeConfirmation, setCodeConfirmation] = useState("");
  const axiosPrivate = useAxiosPrivate();
  const [country, setCountry] = useState();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [phoneNumber, setPhoneNumber] =
    useState();
    // currentUser?.phoneNumber ? currentUser?.phoneNumber : ""
  const navigate = useNavigate();

  // type of modal to show modalRemove or modalPhone
  const { modalType, title } = type;
  const modalRemove = modalType === "modalRemove" ? true : false;
  const modalPhone = modalType === "modalPhone" ? true : false;

  //TOAST vars
  // responseType => "success" or "error"
  const [responseType, setResponseType] = useState("success");
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const time = 5000;

  //Close toast

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  // Display toast
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  // Handle CLick outside
  /* const handleClickOutside = (event) => {
    if (
      ref.current &&
      !ref.current.contains(event.target) &&
      !event.target.closest(".toast") &&
      !event.target.closest(".country-list")
    ) {
      closeModal();
    }
  }; 

  const ref = useOutsideClick(handleClickOutside); */

  // overflow-y-hidden when modal is opened

  useEffect(() => {
    if (showModal && document.querySelector(".modalRemove")) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [showModal]);

  // Show or null modal
  useEffect(() => {
    setShowModal(show);
  }, [show]);

  const closeModal = () => {
    setShowModal(false);
    onClose(false);
    setShowConfirmation(false);
    document.body.classList.remove("overflow-hidden");
  };

  const phoneWithoutDialCode = () => {
    if (country) {
      if (country.length === 1) {
        return phoneNumber.substring(1);
      } else if (country.length === 2) {
        return phoneNumber.substring(2);
      } else if (country.length === 3) {
        return phoneNumber.substring(3);
      }
    }
  };

  // Request sendEmail to remove account // Request SendSms to add phone

  //sendEmail
  const emailToRemove = async () => {
    const email = JSON.parse(localStorage.getItem("user"))?.email || "";
    try {
      const res = await axiosPrivate.post("user/sendDeleteEmail", { email });

      if (res) {
        setShowConfirmation(true);
        displayToast(t("compte.verifierCodeEmail"), "success");
      }
    } catch (error) {
      if (error.response.data.error) {
        displayToast(t("compte.verifierCodeEmail"), "success");
        setShowConfirmation(true);
      } else {
        displayToast(t(error.message), "error");
      }
    }
  };

  //sendSms
  //validatePhonenumberStep1

  const sendSmsToAddPhone = async () => {
    if (currentUser.phoneNumber === phoneNumber) {
      displayToast(t("compte.telephoneUsed"), "error");
      return;
    }

    // 33

    let phone = phoneNumber;

    if (
      parseInt(phone.charAt(3)) === 0 ||
      parseInt(phone.charAt(2)) === 0 ||
      parseInt(phone.charAt(1)) === 0
    ) {
      if (parseInt(phone.charAt(2)) === 0) {
        phone = phone.slice(0, 2) + phone.slice(3);
      } else if (parseInt(phone.charAt(3)) === 0) {
        phone = phone.slice(0, 3) + phone.slice(4);
      } else if (parseInt(phone.charAt(1)) === 0) {
        phone = phone.slice(0, 1) + phone.slice(2);
      }
    }

    const res = await axiosPrivate.post("user/validatePhonenumberStep1", {
      phoneNumber: phone,
    });
    try {
      if (res) {
        displayToast(t("compte.verifierCodePhone"), "success");
        setShowConfirmation(true);
      }
    } catch (error) {
      displayToast("Erreur..", "error");
    }
  };

  // Request to remove account with code confirmation // Add sms with code confirmation

  const removeAccount = async () => {
    try {
      const email = JSON.parse(localStorage.getItem("user"))?.email || "";
      const otd = codeConfirmation;
      const refreshToken =
        JSON.parse(localStorage.getItem("user"))?.refreshToken || "";

      const res = await axiosPrivate.delete("user/deleteUser", {
        data: { email, otd, refreshToken },
      });

      if (res) {
        displayToast(t("compte.votreCompteSupprimer"), "success");
        setTimeout(() => {
          const i18nextLngValue = localStorage.getItem("i18nextLng");
          const CookieConsent = localStorage.getItem("CookieConsent");
          localStorage.clear();
          if (CookieConsent) {
            localStorage.setItem("CookieConsent", CookieConsent);
          }
          localStorage.setItem("i18nextLng", i18nextLngValue);
          navigate("/", { replace: true });
        }, 4000);
      }
    } catch (error) {
      displayToast(t(error.message), "error");
    }
  };

  //sendSms
  //validatePhonenumberStep2

  const validateAddPhone = async () => {
    let phone = phoneNumber;
    if (phone.charAt(2) == 0 || phone.charAt(1) == 0) {
      if (phone.charAt(2) === 0) {
        phone = phone.slice(0, 3) + phone.slice(4);
      } else {
        phone = phone.slice(0, 2) + phone.slice(3);
      }
    }
    const otd = codeConfirmation;
    const res = await axiosPrivate.post("user/validatePhonenumberStep2", {
      phoneNumber: phone,
      otd,
    });

    try {
      if (res) {
        displayToast(t("compte.sucessPhone"), "success");

        currentUser.phoneNumber = phoneNumber;
        newPhone(phoneNumber);
        const userToJson = JSON.stringify(currentUser);
        localStorage.setItem("user", userToJson);

        setTimeout(() => {
          closeModal();
          handleCloseToast();
        }, 1500);
      }
    } catch (error) {
      displayToast("Erreur", "error");
    }
  };

  // On click btn to send email to remove account OR confirm phoneNumber to send sms
  const confirmBtn = (e) => {
    // email code for remove OR Sms to add phoneNumber
    if (e.target.classList.contains("step-2")) {
      // if modal remove
      if (modalRemove) {
        emailToRemove();
      } else {
        sendSmsToAddPhone().catch((error) => {
          displayToast(t(error.message), "error");
        });
      }
    }
    // Remove the account after confirmation OR change phoneNumber
    else {
      if (modalRemove) {
        removeAccount();
      } else {
        validateAddPhone().catch((error) => {
          if (error.response.data.message) {
            displayToast(t("compte.errorCode"), "error");
          } else {
            displayToast("error", "error");
          }
        });
      }
    }
  };

  if (!showModal) {
    return null;
  }

  return createPortal(
    <div
      className={`fixed  modal-twoSteps z-50 inset-0 overflow-y-auto modalRemove bg-blackRgba`}
    >
      <div className="flex items-center justify-center h-full w-full">
        {/* Modal Content */}
        <div
          // ref={ref}
          className="bg-white rounded-lg shadow-lg p-8 w-95 max-w-sm relative"
        >
          <div className="flex items-center justify-bettween ">
            <h2 className="font-bold text-darkBlue">{title}</h2>

            <button
              onClick={closeModal}
              type="button"
              className=" text-gray-400 bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-800 dark:hover:text-white"
              data-modal-hide="authentication-modal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clipRule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="border-b  border-solid boder-black -mx-8 mt-4 "></div>

          {modalPhone && !showConfirmation ? (
            <div className="mt-4">
              <PhoneInput
                // disableCountryCode={true}

                country={"fr"}
                value={phoneNumber}
                name="phoneNumber"
                enableSearch={true}
                enableLongNumbers={true}
                countryCodeEditable={false}
                inputProps={{
                  maxLength: 20,
                }}
                onChange={(phone, objCountry) => {
                  setCountry(objCountry.dialCode);
                  setPhoneNumber(phone);
                }}
              />
            </div>
          ) : null}
          {modalPhone &&
            !showConfirmation &&
            phoneNumber?.length > 12 &&
            isValidPhoneNumber(phoneWithoutDialCode(), 2) && (
              <span className="text-red-400  text-xs">
                {t("compte.saisirValidePhone")}
              </span>
            )}
          <div
            className={`input-confirmation mt-4 ${
              showConfirmation ? "block" : "hidden"
            }`}
          >
            <label
              htmlFor="last_name"
              className="block mb-2 text-sm font-medium text-gray-900 "
            >
              {modalRemove
                ? "Email confirmation code"
                : t("compte.smsConfirmationCode")}
            </label>
            <input
              type="text"
              onChange={(e) => {
                setCodeConfirmation(e.target.value);
              }}
              value={codeConfirmation}
              name="emailConfirmation"
              id="last_name"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              placeholder=""
            />
          </div>
          <Button
            disabled={
              modalPhone &&
              !showConfirmation &&
              isValidPhoneNumber(phoneWithoutDialCode())
            }
            onClick={confirmBtn}
            btnContent={`${
              showConfirmation && modalRemove
                ? t("compte.supprimer")
                : t("compte.save")
            }`}
            customStyle={`text-white mt-5 w-full mr-auto ${
              showConfirmation
                ? modalRemove
                  ? "bg-redDanger remove-user"
                  : "bg-lightGreen"
                : !modalRemove
                ? "bg-lightGreen step-2"
                : "bg-lightGreen step-2"
            }`}
          />
          <div className={`mt-2  justify-center ${loader ? "flex" : "hidden"}`}>
            <Loader />
          </div>
        </div>

        {/* //MOdal content */}
      </div>

      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
    </div>,
    document.getElementById("root")
  );
};

export default ModalTwoSteps;
