/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { userRequest, publicRequest } from "./requestMethods";

import {
  getReservations,
  ReservationsFailure,
} from "../redux/reservationSlice";
import { transformSchedule } from "../utils/utils";
import {
  addPaymentFailure,
  addPaymentStart,
  addPaymentSuccess,
  getPaymentFailure,
  getPaymentStart,
  getPaymentSuccess,
} from "../redux/paymentSlice";

//Get user

export const STRIPE_PH = process.env.REACT_APP_STRIPE_PH; // 'pk_test_51OgNnPJRSvJTKOc8MnALDTY5cusyHkSeXcRaYzY9jw9lBhUWQHjp6qBHpnSvsSLzD4RykBqB4GUU3VzwGIomRhDC00HrjgjMTt'

export const getUserInfo = async (callback) => {
  try {
    const res = await userRequest.get("user");

    if (res) {
      return callback(null, res);
    }
  } catch (error) {
    return callback(error, null);
  }
};

//

// Get email remove user

export const getDeleteEmail = async (email, callback) => {
  try {
    const res = await userRequest.post("user/sendDeleteEmail", {
      email: email,
    });

    if (res) {
      return callback(null, res);
    }
  } catch (error) {
    return callback(error, null);
  }
};

/// Remove User

export const deleteUSer = async (email, otd, refreshToken, callback) => {
  try {
    const res = await userRequest.delete("user/deleteUser", {
      data: { email, otd, refreshToken },
    });

    if (res) {
      return callback(null, res);
    }
  } catch (error) {
    return callback(error, null);
  }
};

/***
 * Update user info
 */

export const updateUser = async (
  { name, email, phoneNumber, newsLetterSub, invoiceSub },
  callback
) => {
  try {
    const res = await userRequest.patch("user", {
      name: { first: name.first, last: name.last },
      email,
      phoneNumber,
      newsLetterSub,
      invoiceSub,
    });

    if (res) {
      return callback(null, res);
    }
  } catch (error) {
    return callback(error, null);
  }
};

// Get reservation not available dates

export const getNotAvailableDates = async (dispatch) => {
  try {
    const res = await publicRequest.get("reservation/unavailable");

    if (res) {
      dispatch(getReservations(transformSchedule(res.data)));
    }
  } catch (error) {
    dispatch(ReservationsFailure(error));
  }
};

/// Add reservation

export const reserver = async (startDate, endDate, callback) => {
  try {
    const res = await userRequest.post("reservation/reserve", {
      startDate: startDate,
      endDate: endDate,
    });

    if (res) {
      return callback(null, res);
    }
  } catch (error) {
    return callback(error, null);
  }
};

// Cancel réservation :

export const cancelReservation = async (id, callback) => {
  try {
    const res = await userRequest.delete(`reservation/cancelPending/${id}`);

    if (res) {
      callback(null, res);
    }
  } catch (error) {
    callback(error, null);
  }
};


// Final step reserver 

export const reserveFinal = async (data, callback) => {
  try {
    const res = await userRequest.post("reservation", {
      "isTermsOfUseAccepted":data.isTermsOfUseAccepted,
      'pendingReservationId':data.pendingReservationId,
      'cardId':data.cardId
      
    });

    if (res) {
      return callback(null, res);
    }
  } catch (error) {
    return callback(error, null);
  }
};


// Add billing address : 

export const addBillingAddress = async (infos, callback) => {

  try {
    const res = await userRequest.post("user/billingAddress", {
      "label": infos.label,
      "address": infos.address,
      "zipCode": infos.zipCode,
      "city": infos.city,
      "country": infos.country,
      "type": infos.type
    });

    if (res) {
      return callback(null, res);
    }
  } catch (error) {
    return callback(error, null);
  }
};



// Add a new card (payment)
export const addCard = async (data, callback, dispatch) => {
  try {
    dispatch(addPaymentStart());
    const res = await userRequest.post("user/card", data);

    if (res) {
      dispatch(addPaymentSuccess(res.data.data));
      callback(null, res);
    }
  } catch (error) {
    callback(error, null);
    dispatch(addPaymentFailure());
  }
};

/**
 * Get all cards of a giving a user
 *
 * @param {*} callback
 * @return Array of cards [{cardExternalId, alias, label, currency}]
 */
export const getCards = async (callback, dispatch) => {

  try {
    dispatch(getPaymentStart());
    const res = await userRequest.get("user/card");

    if (res) {
      dispatch(getPaymentSuccess(res.data));
      callback(null, res);
    }
  } catch (error) {
    callback(error, null);
    dispatch(getPaymentFailure());
  }
};

/**
 * Deactivate (delete from database) a card
 *
 * @param String cardId, {*} callback
 * @return Boolean success
 */
export const deleteCard = async (cardId, callback) => {
  try {
    const res = await userRequest.delete("user/card", { cardId: cardId });

    if (res) {
      callback(null, res);
    }
  } catch (error) {
    callback(error, null);
  }
};
