/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import Toast from "../ui/Toast";
import { loginEmailWithPwd, preRegister } from "../../api/Auth";
import { formateDate, isvalidEmail } from "../../utils/utils";
import Button from "../ui/Button";
import { useOutsideClick } from "../../utils/hooks";
import Loader from "../ui/Loader";
import { useTranslation } from "react-i18next";
import { START_AND_END_HOURS_KEY, calculateTimeDifference } from "./reservation/hours";
import { reserver } from "../../api/apiCalls";
import { setReservationDetails } from "../../redux/reservationSlice";
import { useDispatch } from "react-redux";

const PopupLogin = ({
  type,
  openModal,
  closeMenu,
  inReservation,
  inMobile,
  onCreateAccount,
  onResetPwd,
}) => {
  const {t} = useTranslation();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [time, setTime] = useState(3000);
  // responseType => "success" or "error"
  const [responseType, setResponseType] = useState("success");


  const dispatch = useDispatch()

  const handleClickOutside = (event) => {
    const box = document.querySelector("#popup-container");
    const boxPopUp = document.querySelector(".mobile .box-popup");
    const boxReservation = document.querySelector(".paiementBox .boxpopup");

    if (
      box &&
      !ref.current.contains(event.target) &&
      !event.target.closest(".reserver-btn") &&
      !event.target.closest(".inscription") &&
      !event.target.closest(".connexion") &&
      !event.target.closest(".toast")
    ) {
      if (!boxPopUp?.contains(event.target)) {
        openModal();
        return;
      }

      if (boxReservation) {
        if (!boxReservation.contains(event.target)) {
          openModal();
        }
      } else {
      }

      //openModal();
    }
  };
  const ref = useOutsideClick(handleClickOutside);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
  }, []);

  // message of toast + type "error" or "success"
  const displayToast = (message, type) => {
    // prepare data for toast
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  // callback to close toast
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  // CLick buttons

  const handleClick = async (e) => {
    e.preventDefault();

    /////// Inscription
    // not valid email (frontend check)
    if (!isvalidEmail(email)) {
      displayToast(t("other.enterValidEmail"), "error");
      setEmail("");
    }

    // valid email call preRegister(email)
    else {
      setLoader(true);

      // PreRegister
      let dataToSend={
        email,
        password,
      };
      // if(inReservation){
      //   dataToSend={email,...inReservation}
        
      // }
      // else{
      //   dataToSend={email};
       
        
      // }
      await loginEmailWithPwd(dataToSend, async (err, res) => {
        
        if (err) {
          // console.log('Error : ', err)
          // if (!err.response) {
          //   displayToast(err.message, "error");
          //   setLoader(false);
          // } else {
          //   displayToast(t('compte.invalidCredentail'), "error");
          //   setLoader(false);
          // }
          displayToast(t('compte.invalidCredentail'), "error");
          setLoader(false);

          // if true
        } else if (res.success === false) {
          console.log('Res : ', res)
          displayToast(t('compte.invalidCredentail'), "error");
          setLoader(false);
        } else {
          console.log('Res : ', res)
          setTimeout(() => {
            setLoader(false);
          }, 2000);

          setTime(5000)
          // displayToast(t("menu.messageInscription"), "success");

          // setTimeout(()=>{
            openModal();
            setEmail("");
            setPassword("");
            if (closeMenu) {
              closeMenu();
            }
            // await submitReservation()
            window.location.reload()
          // },5000)
        }
      });
    }
  };

  const onCreateAccountPress = () => {
    if (onCreateAccount) {
      onCreateAccount()
    }
  }

  const onResetPwdPress = () => {
    if (onResetPwd) {
      onResetPwd()
    }
  }

  const submitReservation = async () => {
    // if (
    //   allowUserToReserve ||
    //   (hoursHide &&
    //     (hoursHide[endHour.substring(0, 5)] ? true : false) &&
    //     isTimeDifferance15MIn(startHour, endHour))
    // ) {
      //reserver()

      //transform to backend data

      const startEnd = localStorage.getItem(START_AND_END_HOURS_KEY);

      if (!startEnd){
        window.location.replace(window.location.href)
         return false;
      }

      const seArr = startEnd.split('|')

      const startHour = seArr[0]
      const endHour = seArr[1]

      const start =
        startHour.substring(6) +
        " " +
        startHour.substring(0, 5).replace("h", ":");
      const end =
        endHour.substring(6) + " " + endHour.substring(0, 5).replace("h", ":");

      setLoader(true);
      await reserver(start, end, (err, res) => {
        if (!err) {
          if (res.data.success) {
            displayToast(t("reservationStep1.operation"), "success");
            const reservationInfo = {
              start: startHour,
              end: endHour,
              price: calculateTimeDifference(startHour, endHour, "price"),
              duration: calculateTimeDifference(startHour, endHour, "duration"),
              date: formateDate(startHour.substring(6)),
              id: res?.data?.id,
              checkout_url: res?.data?.checkout_url,
              checkoutId: res?.data?.checkoutId,
              clientSecret: res?.data?.clientSecret,
              currency: res?.data?.currency,
            };
            setTimeout(() => {
              localStorage.setItem(
                "reservationDetails",
                JSON.stringify(reservationInfo)
              );
              dispatch(setReservationDetails(reservationInfo));
              window.location.replace(window.location.href + '?tempReservation=' + reservationInfo.id)
              // window.location.href = window.location.href + '?tempReservation=' + reservationInfo.id

              setLoader(false);
            }, 1500);
          } else if (!res?.data?.success || res?.data?.message === 'error.past.reservations' || err.message === 'error.past.reservations') {
            displayToast(t("other.creneauNonDisponible"), "error");
            setLoader(false);
            window.location.replace(window.location.href )
          }
        } else {
          setLoader(false);
          if (!res?.data?.success || res?.data?.message === 'error.past.reservations' || err.message === 'error.past.reservations') {
            displayToast(t("other.creneauNonDisponible"), "error");
            return;
          }
          displayToast(err.message, "error");
          window.location.replace(window.location.href)
        }
      });
      return true;
    // } else {
    //   displayToast(t("other.creneauNonDisponible"), "error");
    // }
  };

  return (
    <div
      id="popup-container"
      className={`px-5 transition duration-150 ease-in-out  flex flex-col   overflow-x-hidden overflow-y-auto fixed  z-50 b-0 left-0 right-0 ${
        inReservation
          ? "bg-[rgba(0,0,0,.3)] top-0 h-full"
          : "bg-darkBlue lg:bg-[rgba(0,0,0,.3)] lg:h-full lg:top-0"
      }`}
    >
      <div className={`relative w-auto ${inMobile?'my-6':''} mx-auto max-w-3xl justify-center items-center`}>
        <div
          ref={ref}
          className={`border-0 box-popup slide-in-top
            rounded-lg shadow-lg relative flex flex-col w-full bg-white ${
              inReservation
                ? "top-1/1 left-1/2 translate-y-1/1 -translate-x-1/2 reserve-popup"
                : "lg:top-1/1 lg:left-1/2 lg:translate-y-1/1 lg:-translate-x-1/2"
            } xs:py-5 py-3 lg:top-1/1 lg:left-1/2 lg:translate-y-1/1 lg:-translate-x-1/2`}
        >
          {/* Close popUp  */}
          <span
            className="closeBtn"
            onClick={() => {
              openModal();
              setOpenToast(false);
              setMessage("");
            }}
          ></span>
          {/* Close button */}

          <div className="flex items-start justify-between p-5  rounded-t ">
            <p className="text-[14px] text-darkBlue font-bold m-auto text-center max-w-80">
              {t("reservationStep1.popUpLogin")}
            </p>
          </div>
          <div className=" px-5 py-2 items-center">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              name="email"
              id="email"
              placeholder={
                (type?.toLowerCase() === "login"
                  ? t("other.saiseEmail")
                  : t("other.saiseEmail")) + "*"
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
            <div style={{height: 10}}></div>
            <input
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              type="password"
              name="password"
              id="password"
              placeholder={
                // type?.toLowerCase() === "login"
                //   ? t("other.saiseEmail")
                //   : 
                  t("compte.pwd") + "*"
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
          </div>
          
          <div className="flex flex-col items-center justify-center p-6  rounded-b">
            <Button
              onClick={handleClick}
              btnContent={t("compte.connect")}
              customStyle={"btn-principal text-white"}
            />

            <div
              className={`loader mt-4 absolute bottom-1 ${
                loader ? "block" : "hidden"
              }`}
            >
              <Loader />
            </div>
          </div>
          <div style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <div >
              <span className="font-bold text-darkBlue cursor-pointer" onClick={onResetPwdPress}>
                {t('compte.forgotPwd') + '?'}
              </span>
            </div>
            <div style={{height: 10}}></div>
            <div className="d-flex">
              <span className="text-[14px] text-darkBlue">
                {t('compte.noAccount') + ','}
              </span>
              <span className="p-5 text-[18px] text-green-500 cursor-pointer" onClick={onCreateAccountPress}>
                {t('compte.createAccount')}
              </span>
            </div>
            
          </div>
        </div>
      </div>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
    </div>
  );
};
export default PopupLogin;
