
const data = {
  fr: {
    // Menus
    menuBeforeConnexion: [
      // {title:"Nos partenaires",link:"/partenaires", id:1},
      //  {title:"Cgu",link:"/cgu", id:3},
      { title: "inscription", link: "#", id: 4 , key:"Inscription" },
      { title: "connexion", link: "#", id: 5 , key:"Connexion"},
      { title: "FAQ", link: "/faq", id: 2 },
    ],
    menuAfterConnexion: [
      // {title:"Nos partenaires",link:"/partenaires", id:1},
      // {title:"Cgu",link:"/cgu", id:3},
      {
        title: "dropDownMenu",
        content: [
          { title: "compte", link: "/compte", id: 10 },
          { title: "reservations", link: "/reservations", id: 20 },
          // { title: "paiement", link: "/moyens-paiement", id: 30 },
          {
            title: "facturation",
            link: "/adresses-facturation",
            id: 40,
          },
          { title: "deconnexion", link: "#", id: 50 },
        ],
        id: 4,
      },
      {
        title: "dropDownMenu",
        subtitle: "assistance",
        content: [
          {
            title: "email",
            link: "#",
            content: "contact@ecapsule.fr",
            id: 1400,
          },
          { title: "phone", link: "#", content: "05 82 95 09 69", id: 1500 },
        ],
        id: 1955,
      },
      { title: "FAQ", link: "/faq", id: 2 },
    ],

    menuFooter: [
      { title: "acceuil", link: "/", id: 1 },
      { title: "faq", link: "/faq", id: 2 },
      //{ title: "Recherchez une eCAPSULE", link: "/recherche-capsule", id: 3 },
      //{ title: "Contact", link: "/contact", id: 4 },
      { title: "conditions", link: "/cgu", id: 5 },
      { title: "mentions", link: "/mentions-legals", id: 6 },
    ],

    // Footer content


    nosEcapsules: {
      title: "eCAPSULE",
      content: [
        {
          title: "Lyon Aéroport",
          link: "/",
          id: 1,
        },
      ],
    },
    capsuleIconsTitle: {
      wifi: "Wifi Gratuit",
      tv: "Ecran tv",
      prise: "Prises USB/ 220V",
      hours: "24/24",
      insonorise: "Insonorisé",
    },

    // CapsuleDetails data

    capsuleDetails: {
      details: {
       
          phone: "+335 82 95 09 69",
     
        
      },

      around: {
        content: ["duty", "cafes", "restaurants", "toilettes"],
      },
    },
  },
};

export default data;

// Infos statics

export const infos = {
  email: "contact@ecapsule.fr",
  adresse: "31000, Toulouse",
  phone: "+33582950969",
};

export const inscriptionMessage = {
  content: [
    "Afin de procéder à la réservation d’une de nos eCAPSULES, il vous suffit de créer gratuitement un compte.",
    "You want to take advantage of our eCAPSULES? You just need to create your free account",
  ],
  placeholder: ["Saisissez votre Email", "Enter your Email"],
  btnTitle: ["Inscription", "Sign up"],
};

export const connexionMessage = {
  content: [
    "Vous êtes déjà inscrit ? Saisissez vos informations de connexion",
    "Already registered? Enter your login information",
  ],
  placeholder: ["Saisissez votre Email", "Enter your Email"],
  btnTitle: ["Connexion", "Connection"],
};

export const toastMessagesInscription = {
  enterValidEmail: ["Entrer un email valide", "Enter a valid Email"],
  checkEmail: ["Veuillez vérifier votre boîte de réception (y compris vos spams)."],
};

export const erroPageText = {
  text1: [
    "Désolé, nous n'avons pas trouvé cette page.",
    "Sorry, we couldn't find this page.",
  ],
  text2: [
    "Mais ne vous inquiétez pas, vous pouvez trouver beaucoup d'autres choses sur notre page d'accueil.",
    "But dont worry, you can find plenty of other things on our homepage.",
  ],
  btnText: ["Page d'accueil", "back to home"],
};