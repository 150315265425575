/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useState } from 'react';
import { useOutsideClick } from '../../utils/hooks';
import fr from "../../assets/images/fr.png"
import en from "../../assets/images/en.png"
import world from "../../assets/images/icons/world.svg"
import { CloseIcon } from '../../data/Icons';
import { useTranslation} from 'react-i18next';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";



const MultiLanguage = () => {
const {i18n ,t} = useTranslation();
const axiosPrivate = useAxiosPrivate();
const [showAvailableLanguages, setShowAvailableLanguages]= useState(false);
const [languageSwitcher,setLanguageSwitcher] = useState(false);
// hover open dropDownMenu:
const openLanguageSwitcher = ()=>{
setLanguageSwitcher(true);
document.body.classList.add("overflow-hidden");
}

//

const switchLang = (lang)=>{

  // set language in db for members
  const user = localStorage.getItem("user");
  if(user){
    const changeLang = async ()=>{
      await axiosPrivate.patch("user/language",{language:lang.toUpperCase()})
    
    }
    changeLang();
  }

  // member or visitor change language 
  i18n.changeLanguage(lang)
  document.documentElement.setAttribute('lang',lang);
}

const closeLanguageSwitcher = ()=>{
  setLanguageSwitcher(false);
  document.body.classList.remove("overflow-hidden");
}


const handleClickOutside = (event) => {
    if ((ref.current && !ref.current.contains(event.target))
    &&
     !event.target.closest(".currentLanguage")
   
   )
    {
      closeLanguageSwitcher();
      
    }
  };
  

  const ref = useOutsideClick(handleClickOutside);

return (
<div className="multiLanguage"  >
<div className="currentLanguage inline-block relative cursor-pointer mt-0.5"
onClick={()=>{
  openLanguageSwitcher();
}}

>
<img src={world} alt="flag" onClick={()=>setShowAvailableLanguages(!showAvailableLanguages)}/>

</div>



{languageSwitcher && <div className='transition duration-150 ease-in-out 	 bg-[rgba(0,0,0,.3)] z-50 fixed top-0 left-0 w-screen h-screen'> 
<div ref={ref} className='slide-in-top2 multi-lang fixed lg:rounded-t-3xl lg:rounded-b-3xl top-0 lg:top-1/2 left-0 lg:left-1/2 w-screen lg:w-1/2 h-screen lg:h-1/2 lg:-translate-y-1/2 lg:-translate-x-1/2 bg-langSwitch-1 z-50'>
<div className='flex lg:rounded-t-3xl justify-between items-center py-8 px-10 bg-langSwitch-2 '>
<h2 className='text-xl lg:text-2xl font-semibold'>{t("other.selectLang")}</h2>
<div className="cursor-pointer" onClick={()=>{
  closeLanguageSwitcher();
}}><CloseIcon   className={"text-white"}/> </div>
</div>
<div className='list-Languages flex-col xxs:flex-row flex justify-between'>
<div onClick={()=>{
 switchLang("fr");
  closeLanguageSwitcher();}
  } className='item-lang mr-lang cursor-pointer transition duration-150 ease-in-out  hover:bg-[rgba(0,0,0,0.7)]'>
<img src={fr} alt="france flag"/>
<span className='ml-5 text-xl font-semibold text-[#f4f4f4]' >Français</span>
</div>
<div  onClick={()=>{

  switchLang("en");
  closeLanguageSwitcher();
  }} className='item-lang ml-lang cursor-pointer transition duration-150 ease-in-out  hover:bg-black'>
<img src={en} alt="france flag"/>
<span className='ml-5 text-xl font-semibold text-[#f4f4f4]'>UK</span>
</div>
</div>
</div> </div>}
</div>
);
};

export default MultiLanguage;