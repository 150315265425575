/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import Toast from "../ui/Toast";
import { emailPasswordRegister, preRegister, resetPasswordNow, sendPwdResetrequest, validateEmailWithCode } from "../../api/Auth";
import { isvalidEmail } from "../../utils/utils";
import Button from "../ui/Button";
import { useOutsideClick } from "../../utils/hooks";
import Loader from "../ui/Loader";
import { useTranslation } from "react-i18next";

const PopupResetPassword = ({
  type,
  openModal,
  closeMenu,
  inReservation,
  inMobile,
  onValidated,
  onConnect,
}) => {
  const {t} = useTranslation();
  const [email, setEmail] = useState("");
  const [lastname, setLastname] = useState("");
  const [firstname, setFirstname] = useState("");
  const [password, setPassword] = useState("");
  const [temppwd, setTemppwd] = useState("");
  const [newpwd, setNewpwd] = useState("");
  const [newpwdConfirm, setNewpwdConfirm] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [time, setTime] = useState(3000);
  // responseType => "success" or "error"
  const [responseType, setResponseType] = useState("success");
  const [validating, setValidating] = useState(false);
  const [code, setCode] = useState('');

  const handleClickOutside = (event) => {
    const box = document.querySelector("#popup-container");
    const boxPopUp = document.querySelector(".mobile .box-popup");
    const boxReservation = document.querySelector(".paiementBox .boxpopup");

    if (
      box &&
      !ref.current.contains(event.target) &&
      !event.target.closest(".reserver-btn") &&
      !event.target.closest(".inscription") &&
      !event.target.closest(".connexion") &&
      !event.target.closest(".toast")
    ) {
      if (!boxPopUp?.contains(event.target)) {
        openModal();
        return;
      }

      if (boxReservation) {
        if (!boxReservation.contains(event.target)) {
          openModal();
        }
      } else {
      }

      //openModal();
    }
  };
  const ref = useOutsideClick(handleClickOutside);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
  }, []);

  // message of toast + type "error" or "success"
  const displayToast = (message, type) => {
    // prepare data for toast
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  // callback to close toast
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  // CLick buttons

  const handleClick = (e) => {
    e.preventDefault();

    /////// Inscription
    // not valid email (frontend check)
    if (!isvalidEmail(email)) {
      displayToast(t("other.enterValidEmail"), "error");
      setEmail("");
    }

    // valid email call preRegister(email)
    else {
      setLoader(true);

      // PreRegister
      let dataToSend={
        email,
        // password,
        // name: {
        //   first: firstname,
        //   last: lastname,
        // }
      };
      // if(inReservation){
      //   dataToSend={email,...inReservation}
        
      // }
      // else{
      //   dataToSend={email};
       
        
      // }
      sendPwdResetrequest(dataToSend, (err, res) => {
        if (err) {
          if (!err.response) {
            displayToast(err.message, "error");
            setLoader(false);
          } else {
            displayToast(t('compte.requestNotFinished'), "error");
            setLoader(false);
          }

          // if true
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 2000);

          setValidating(true);

          setTime(5000)
          displayToast(t("compte.sentTempPwd"), "success");

          setTimeout(()=>{
            // openModal();
            // setEmail("");
            // setFirstname("");
            // setLastname('');
            // setPassword('');
            // if (closeMenu) {
            //   closeMenu();
            // }
          },5000)
        }
      });
    }
  };

  const handleValidate = (e) => {
    e.preventDefault();

    /////// Inscription
    // not valid email (frontend check)
    // if (!isvalidEmail(email)) {
    //   displayToast(t("other.enterValidEmail"), "error");
    //   setEmail("");
    // }
    // else {
    if (temppwd != '' && newpwd != '' && newpwd === newpwdConfirm) {
      setLoader(true);
      let dataToSend={
        email,
        temppwd: temppwd,
        newpwd: newpwd,
      };
      resetPasswordNow(dataToSend, (err, res) => {
        if (err) {
          if (!err.response) {
            displayToast(err.message, "error");
            setLoader(false);
          } else {
            displayToast('compte.invalidTempPwd', "error");
            setLoader(false);
          }

          // if true
        } else {
          if (res?.code && res?.code === 'CODE_INVALID') {
            displayToast('compte.invalidTempPwd', "error");
            setLoader(false);
            return;
          }
          if (res?.code && res?.code === 'CODE_EXPIRED') {
            displayToast('compte.expiredTempPwd', "error");
            setLoader(false);
            return;
          }
          setTimeout(() => {
            setLoader(false);
          }, 2000);
          setTime(5000)
          displayToast(t("compte.resetedpwd"), "success");

          setTimeout(()=>{
            openModal();
            // setEmail("");
            // setFirstname("");
            // setLastname('');
            // setPassword('');
            // setCode('');
            if (closeMenu) {
              closeMenu();
            }
            if (onValidated) {
              onValidated()
            }
          },5000)
        }
      });
    } else if (newpwd !== newpwdConfirm) {
      displayToast(t('compte.notSame'), "error");
    }
    // }
  };

  const onConnectPress = () => {
    if (onConnect) {
      onConnect()
    }
  }

  return (
    <div
      id="popup-container"
      className={` popup-new-account px-5 transition duration-150 ease-in-out  flex flex-col   overflow-x-hidden overflow-y-auto fixed  z-50 b-0 left-0 right-0 ${
        inReservation
          ? "bg-[rgba(0,0,0,.3)] top-0 h-full"
          : "bg-darkBlue lg:bg-[rgba(0,0,0,.3)] lg:h-full lg:top-0"
      }`}
    >
      <div className={`relative w-auto ${inMobile?'my-6':''} mx-auto max-w-3xl justify-center items-center`}>
        {!validating && <div
          ref={ref}
          className={`border-0 box-popup slide-in-top
            rounded-lg shadow-lg relative flex flex-col w-full bg-white ${
              inReservation
                ? "top-1/1 left-1/2 translate-y-1/1 -translate-x-1/2 reserve-popup"
                : "lg:top-1/1 lg:left-1/2 lg:translate-y-1/1 lg:-translate-x-1/2"
            } xs:py-5 py-0 lg:top-1/1 lg:left-1/2 lg:translate-y-1/1 lg:-translate-x-1/2`}
        >
          {/* Close popUp  */}
          <span
            className="closeBtn"
            onClick={() => {
              openModal();
              setOpenToast(false);
              setMessage("");
            }}
          ></span>
          {/* Close button */}

          <div className="flex items-start justify-between p-5  rounded-t ">
            <p className="text-[14px] text-darkBlue font-bold m-auto text-center max-w-80">
              {t("compte.resetReq")}
            </p>
          </div>
          <div className=" px-5 py-2 items-center">
            {/* <input
              onChange={(e) => setLastname(e.target.value)}
              value={lastname}
              type="text"
              name="lastname"
              id="lastname"
              placeholder={ 
                // 'Prénom'
                // type?.toLowerCase() === "login"
                //   ? t("other.saiseEmail")
                //   : 
                  t("compte.prenom")
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
            <div style={{height: 10}}></div>
            <input
              onChange={(e) => setFirstname(e.target.value)}
              value={firstname}
              type="text"
              name="firstname"
              id="firstname"
              placeholder={ 
                // 'Prénom'
                // type?.toLowerCase() === "login"
                //   ? t("other.saiseEmail")
                //   : 
                  t("compte.nom")
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
            <div style={{height: 10}}></div>
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              name="email"
              id="email"
              placeholder={
                (type?.toLowerCase() === "login"
                  ? t("other.saiseEmail")
                  : t("other.saiseEmail")) + "*"
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
            <div style={{height: 10}}></div> */}
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              name="email"
              id="email"
              placeholder={t("other.saiseEmail") + "*"}
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
          </div>
          {/* <div className="d-flex align-items-center ps-5">
            <span className="text-[14px] text-darkBlue">
              {t('compte.haveAccount') + ','}
            </span>
            <span className="p-5 text-[18px] text-darkBlue cursor-pointer" onClick={onConnectPress}>
              {t('compte.connect')}
            </span>
          </div> */}
          <div className="flex flex-col items-center justify-center p-6  rounded-b">
            <Button
              onClick={handleClick}
              btnContent={
                // type?.toLowerCase() === "login"
                //   ? t("menu.connexion")
                //   : 
                  t("compte.send")
              }
              customStyle={"btn-principal text-white"}
            />
            <div
              className={`loader mt-4 absolute bottom-1 ${
                loader ? "block" : "hidden"
              }`}
            >
              <Loader />
            </div>
          </div>
        </div>}
        {validating && <div
          ref={ref}
          className={`border-0 box-popup slide-in-top
            rounded-lg shadow-lg relative flex flex-col w-full bg-white ${
              inReservation
                ? "top-1/1 left-1/2 translate-y-1/1 -translate-x-1/2 reserve-popup"
                : "lg:top-1/1 lg:left-1/2 lg:translate-y-1/1 lg:-translate-x-1/2"
            } xs:py-5 py-0 lg:top-1/1 lg:left-1/2 lg:translate-y-1/1 lg:-translate-x-1/2`}
        >
          {/* Close popUp  */}
          <span
            className="closeBtn"
            onClick={() => {
              openModal();
              setOpenToast(false);
              setMessage("");
            }}
          ></span>
          {/* Close button */}

          <div className="flex items-start justify-between p-5  rounded-t ">
            <p className="text-[14px] text-darkBlue font-bold m-auto text-center max-w-80">
              {t("compte.validateyouraccount")}
            </p>
          </div>
          <div className=" px-5 py-2 items-center">
            <input
              onChange={(e) => setTemppwd(e.target.value)}
              value={temppwd}
              type="password"
              name="temppwd"
              id="temppwd"
              placeholder={
                // type?.toLowerCase() === "login"
                //   ? t("other.saiseEmail")
                //   : 
                  t("compte.tempPwd") + "*"
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
            <div style={{height: 10}}></div>
            <input
              onChange={(e) => setNewpwd(e.target.value)}
              value={newpwd}
              type="password"
              name="newpwd"
              id="newpwd"
              placeholder={
                // type?.toLowerCase() === "login"
                //   ? t("other.saiseEmail")
                //   : 
                  t("compte.newPassWord") + "*"
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
            <div style={{height: 10}}></div>
            <input
              onChange={(e) => setNewpwdConfirm(e.target.value)}
              value={newpwdConfirm}
              type="password"
              name="newpwdConfirm"
              id="newpwdConfirm"
              placeholder={
                // type?.toLowerCase() === "login"
                //   ? t("other.saiseEmail")
                //   : 
                  t("compte.confirmNew") + "*"
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
            
          </div>
          <div className="flex flex-col items-center justify-center p-6  rounded-b">
            <Button
              onClick={handleValidate}
              btnContent={
                // type?.toLowerCase() === "login"
                //   ? t("menu.connexion")
                //   : 
                  t("compte.saveNew")
              }
              customStyle={"btn-principal text-white"}
            />
            <div
              className={`loader mt-4 absolute bottom-1 ${
                loader ? "block" : "hidden"
              }`}
            >
              <Loader />
            </div>
          </div>
        </div>}
      </div>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
    </div>
  );
};
export default PopupResetPassword;
