/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { Route, Routes, useNavigate, useLocation } from "react-router";
import { useEffect, useState } from "react";
import "./App.css";
import Home from "./components/pages/Home";
import NotFound from "./components/pages/NotFound";
import Footer from "./components/widgets/Footer";
import Header from "./components/widgets/Header";
import Compte from "./components/pages/Compte";
import MentionsLegals from "./components/pages/MentionsLegals";
import AdresseFacturation from "./components/pages/AdresseFacturation";
import AdminLogin from "./components/pages/AdminLogin";
import AdminCoupons from "./components/pages/adminPages/AdminCoupons";
import AdminDashboard from "./components/pages/adminPages/AdminDashboard";
import AdminReservation from "./components/pages/adminPages/AdminReservations";
import { register } from "./api/Auth";
import Faq from "./components/pages/Faq";
import AdminPage from "./components/pages/adminPages/Admin";
import Cgu from "./components/pages/Cgu";
import MesReservations from "./components/pages/MesReservations";
import MoyensPaiement from "./components/pages/MoyensPaiement";
import {useDispatch } from "react-redux";
import { calculateTimeDifference, formateDate } from "./utils/utils";
import { setRegistredReservations, setReservationDetails } from "./redux/reservationSlice";
import useAxiosPrivate from "./hooks/useAxiosPrivate";
import { finishedPayment } from "./redux/paymentSlice";
import Toast from "./components/ui/Toast";
import { useTranslation } from "react-i18next";
import Cookie from "./components/ui/CookieConsent";
import { userRequest } from "./api/requestMethods";
import axios from "axios";
import { reserver } from "./api/apiCalls";
import { START_AND_END_HOURS_KEY } from "./components/widgets/reservation/hours";
// import ReactGA from "react-ga4";



const App = () => {


  const {i18n ,t} = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const isUser = JSON.parse(localStorage.getItem("user"));
  const location = useLocation();
  const isAdminLogin = location.pathname === "/ecapsule-login";
  const isAdminPages = location.pathname.includes("ecapsule-admin");


  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time = 3000;
  // Toast
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const axiosPrivate = useAxiosPrivate()

  const searchParams = new URLSearchParams(window.location.search);
  // const transactionId = searchParams.get("transactionId");
  // const checkoutId = searchParams.get("checkoutId");
  

  useEffect(() => {
    const finalizeThisReservation = searchParams.get("finalizeThisReservation");

    // console.log('finalizeThisReservation : ', finalizeThisReservation);

    axios.interceptors.request.use(
      config => {
          const currentUser = JSON.parse(localStorage.getItem("user"));
          const API_KEY = process.env.REACT_APP_SERVER_API_KEY
          // console.log('API_KEY : ', API_KEY)
          config.headers['apikey'] = API_KEY;
          if (!config.headers['Authorization'] && !config.url.includes("auth/logout") && !config.url.includes("admin/auth/logout")) {
              config.headers['Authorization'] = `Bearer ${currentUser?.accessToken}`;
          }
          return config;
      }, (error) => Promise.reject(error)
    );

    // Lang
    const lang = localStorage.getItem("i18nextLng");
    if(lang){
      document.documentElement.setAttribute('lang',lang!=="fr"&& lang!=="en"?"fr":lang);
    }

    const finalize = async () => {
      const reservationDetails = JSON.parse(
        localStorage.getItem("reservationDetails")
      );
      // console.log('reservationDetails', reservationDetails)
      if (finalizeThisReservation === '1') {
        try {
          const currentUser = JSON.parse(localStorage.getItem("user"));
          // console.log('currentUser : ', currentUser)
          const checkoutId = localStorage.getItem('checkoutId')
          // console.log('checkoutId', checkoutId)
  
          const toSend = {
            transactionId: '',
            pendingReservationId: reservationDetails.id,
            checkoutId: checkoutId,
            userId: currentUser.id
          };
          // TO DO call finalizeResa endpoint
          const res = await axiosPrivate.post("reservation", toSend);
          if (res.data.success) {
            // finishPaymentProcess = true;
            // navigate("/", { replace: true });
            displayToast(t("reservationStep2.reservationEnregistree"), "success");
            dispatch(setRegistredReservations([]));
            setTimeout(() => {
              
              localStorage.setItem("finishedPayment", true);
              dispatch(finishedPayment(true));
            }, 1500);
          } else {
            displayToast(t("other.paiementEchec"), "error");
            
          }
  
          navigate("/", { replace: true });
        } catch (error) {
          displayToast( t(error.response.data.message), 'error')
          navigate("/", { replace: true });
        }
      }
    };

    finalize()
  
    return () => {
      
    }
  }, [])
  

  // Check if we have two valid params email and token
  useEffect(() => {
    submitReservation()
    //document.body.classList.remove("overflow-y-hidden");
    const email = searchParams.get("email");
    const token = searchParams.get("token");
    const tempReservation = searchParams.get("tempReservation");
    const redirectToReservation = searchParams.get("redirectToReservation");
    
    const locationPath = location.pathname;




    if (
      locationPath ===
        ("/ecapsule-admin" || locationPath === "/ecapsule-admin/") &&
      isUser &&
      isUser.userType === "A"
    ) {
      navigate("/ecapsule-admin/dashboard", { replace: true });
    }
    if (
      (locationPath === "/" ||
        locationPath === "/compte" ||
        locationPath === "/reservations" ||
        locationPath === "/moyens-paiement" ||
        locationPath === "/adresses-facturation" ||
        locationPath === "/faq" ||
        locationPath === "/mentions-legals") &&
      isUser &&
      isUser.userType === "A"
    ) {
      navigate("/ecapsule-admin/dashboard", { replace: true });
    }
    // If true make request
    let lang = localStorage.getItem('i18nextLng').toUpperCase();
    if(lang!=="EN" && lang!=="FR"){
      lang="FR"
    }
   
    if (email && token) {
      let dataToSend;
      if (tempReservation) {
        dataToSend = { email, token, tempReservationId: tempReservation, language:lang };
      } else {
        dataToSend = { email, token,language:lang };
      }

      register(dataToSend, (err, res) => {
        // If response is okay do..
        if (!err) {

          const lang = res.data.language.toLowerCase();
          document.documentElement.setAttribute('lang',lang);
          i18n.changeLanguage(lang);

          if (redirectToReservation) {
            navigate("/reservations", { replace: true });
          } else {
            navigate("/", { replace: true });
          }

          if (res.data.tempReservation.startDate) {
            const reservationDetails = res.data.tempReservation;
            const startDate =
              reservationDetails.startDate.substring(11).replace(":", "h") +
              " " +
              reservationDetails.startDate.substring(0, 10);
            const endDate =
              reservationDetails.endDate.substring(11).replace(":", "h") +
              " " +
              reservationDetails.endDate.substring(0, 10);

            //localStorage
            const reservationInfo = {
              start: startDate,
              end: endDate,
              price: reservationDetails.amount,
              duration: calculateTimeDifference(startDate, endDate, "duration"),
              date: reservationDetails.startDate.substring(0, 10),
              id: reservationDetails.id,
              checkout_url: reservationDetails?.checkout_url,
              checkoutId: reservationDetails?.checkoutId,
              clientSecret: reservationDetails?.clientSecret,
              currency: reservationDetails?.currency || 'eur',
            };
            localStorage.setItem(
              "reservationDetails",
              JSON.stringify(reservationInfo)
            );

            // Dispatch
            dispatch(setReservationDetails(reservationInfo));
          }
        }
      });
    }

    // if (transactionId) {
      
      

    //   finalize()
    // }
  }, []);

  const submitReservation = async () => {
    // if (
    //   allowUserToReserve ||
    //   (hoursHide &&
    //     (hoursHide[endHour.substring(0, 5)] ? true : false) &&
    //     isTimeDifferance15MIn(startHour, endHour))
    // ) {
      //reserver()

      //transform to backend data

      const startEnd = localStorage.getItem(START_AND_END_HOURS_KEY);
      console.log('START_AND_END_HOURS_KEY' , startEnd)

      if (!startEnd){
        // window.location.replace(window.location.href)
         return false;
      }

      const seArr = startEnd.split('|')

      const startHour = seArr[0]
      const endHour = seArr[1]

      const start =
        startHour.substring(6) +
        " " +
        startHour.substring(0, 5).replace("h", ":");
      const end =
        endHour.substring(6) + " " + endHour.substring(0, 5).replace("h", ":");

      // setLoader(true);
      await reserver(start, end, (err, res) => {
        if (!err) {
          if (res.data.success) {
            displayToast(t("reservationStep1.operation"), "success");
            const reservationInfo = {
              start: startHour,
              end: endHour,
              price: calculateTimeDifference(startHour, endHour, "price"),
              duration: calculateTimeDifference(startHour, endHour, "duration"),
              date: formateDate(startHour.substring(6)),
              id: res?.data?.id,
              checkout_url: res?.data?.checkout_url,
              checkoutId: res?.data?.checkoutId,
              clientSecret: res?.data?.clientSecret,
              currency: res?.data?.currency,
            };
            setTimeout(() => {
              localStorage.setItem(
                "reservationDetails",
                JSON.stringify(reservationInfo)
              );
              dispatch(setReservationDetails(reservationInfo));
              // window.location.replace(window.location.href + '?tempReservation=' + reservationInfo.id)
              // window.location.href = window.location.href + '?tempReservation=' + reservationInfo.id

              // setLoader(false);
            }, 1500);
          } else if (!res?.data?.success || res?.data?.message === 'error.past.reservations' || err.message === 'error.past.reservations') {
            displayToast(t("other.creneauNonDisponible"), "error");
            // setLoader(false);
            // window.location.replace(window.location.href )
          }
        } else {
          // setLoader(false);
          if (!res?.data?.success || res?.data?.message === 'error.past.reservations' || err.message === 'error.past.reservations') {
            displayToast(t("other.creneauNonDisponible"), "error");
            return;
          }
          displayToast(err.message, "error");
          // window.location.replace(window.location.href)
        }
      });
      return true;
    // } else {
    //   displayToast(t("other.creneauNonDisponible"), "error");
    // }
  };

  return (
    <div className="App" id="app">
      {!isAdminLogin && !isAdminPages && <Header />}

      {/* { (isUser && isUser.userType === "A")  && <Header /> } */}

      <Routes>
        <Route path="*" element={<NotFound />} />
        {<Route path="/" exact element={<Home />} />}
        <Route path="/cgu" exact element={<Cgu />} />
        {!isUser && (
          <Route path="/ecapsule-login" exact element={<AdminLogin />} />
        )}
        {isUser && isUser.userType === "A" && (
          <Route path="/ecapsule-admin" element={<AdminPage />}>
            <Route
              path="/ecapsule-admin/dashboard"
              exact
              element={<AdminDashboard />}
            />
            <Route
              path="/ecapsule-admin/coupons"
              exact
              element={<AdminCoupons />}
            />
            <Route
              path="/ecapsule-admin/reservations"
              exact
              element={<AdminReservation />}
            />
          </Route>
        )}
        <Route path="/mentions-legals" exact element={<MentionsLegals />} />
        <Route path="/faq" exact element={<Faq />} />
        {isUser && !isUser.userType && (
          <Route path="/compte" exact element={<Compte />} />
        )}
        {isUser && !isUser.userType && (
          <Route
            path="/adresses-facturation"
            exact
            element={<AdresseFacturation />}
          />
        )}
        {isUser && !isUser.userType && (
          <Route path="/reservations" exact element={<MesReservations />} />
        )}
        {isUser && !isUser.userType && (
          <Route path="/moyens-paiement" exact element={<MoyensPaiement />} />
        )}
      </Routes>
      {!isAdminLogin && !isAdminPages && <Footer />}
       {!isAdminLogin && !isAdminPages    && <Cookie/> }
      {openToast && (
          <Toast
            responseType={responseType}
            message={message}
            open={openToast}
            close={handleCloseToast}
            duration={time}
          />
        )}
    </div>
  );
};

export default App;
