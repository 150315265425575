/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import data from "../../data/data"
import { EmailIcon, FacebookIcon, InstagramIcon, LocationIcon, PhoneIcon } from '../../data/Icons';
import Button from "../ui/Button";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
const publicUrl= process.env.PUBLIC_URL;
const imgUrl= publicUrl+ '/logo@2x.png';

const Footer = ()=>{

    const {t} = useTranslation();
   const scrollTopOnClick = ()=>{
    window.scrollTo({ top: 0, behavior: 'smooth' });
   }
      


// DATA


const {fr:{menuFooter}} = data;
const {fr:{nosEcapsules}} = data;









   return(
       <footer>

               {/* Footer part-1 row-large-screen */}
               <div className="bg-darkBlue text-white width-padding pt-5 pb-8 grid lg:grid-cols-4 lg:gap-5 ">
                   {/* A propos */}
                   <div className="a-propos ">
                   <h2 className="title-footer">{t("footer.a-propos")}</h2>
                  <div className="paragraphs max-w-95">
                  <p className=" text-sm font-light ">{t("footer.paragraph1")}</p>
                   <p className="text-sm font-light mt-2">{t("footer.paragraph2")}</p>
                   <p className="text-sm font-light mt-2">{t("footer.paragraph3")}</p>
                  </div>
                
                   </div>
                   {/* End A propos */}
                   {/* Nos Ecapsules */}
                   <div className="nos-ecapsules">
                   <h2 className="title-footer">{nosEcapsules.title}</h2>
                   <ul>
                       {
                           nosEcapsules.content.map((item)=>{
                               return(
                                   <li key={item.id} className="mb-3 font-light">
                                       <Link to={item.link} onClick={
                                        (()=>{
                                            scrollTopOnClick();
                                        })} > {item.title}</Link>
                                   </li>
                               )
                           })
                       }
                   </ul>
                      {/* Infos Contact */}
                      <div className="mt-6">
                      <a href="mailto:contact@ecapsule.fr" className="infos-contact hover:text-white"><EmailIcon/>
                        <span className="ml-1 font-medium">contact@ecapsule.fr</span></a>
                       <div className="infos-contact"><LocationIcon/>
                       <span className="ml-1 font-medium   ">31000, Toulouse</span></div>
                       <a href="tel:+33582950969" className="infos-contact hover:text-white"><span className="vibrate-1"><PhoneIcon/></span>
                       <span className="ml-1 font-medium   ">+33582950969</span></a>
                   </div>


                   </div>


                   {/* End Nos Ecapsules */}


                   {/* Menu */}
                   <div className="menu-items">
                   <h2 className="title-footer">Menu</h2>
                       <ul>
                       {
                           menuFooter.map((item)=>{
                               return(
                                   <li key={item.id} className="mb-3 font-light">
                                       <Link to={item.link} onClick={
                                        (()=>{
                                            scrollTopOnClick();
                                        })
                                       } >{t("footer."+item.title)}</Link>
                                   </li>
                               )
                           })
                       }
                       </ul>
                   </div>


                   {/* End Menu */}


                   {/* NewsLetter */}
                   <div className="newsletter">
                       <h2 className="title-footer">Newsletter</h2>
                       <p className=" text-sm font-light ">{t("footer.newsletterText")}</p>


                       <div className="newsletter-input">
                   <input placeholder="Email" type="email" id="email" pattern=".+@globex\.com" size="30" required
                   className="mt-4 mb-3 text-darkBlue border-style w-full px-6 py-3 input-footer focus:border-darkGreen"  />
               <Button btnContent={t("footer.inscrire")} customStyle={"rounded-lg btn-footer w-full btn-principal"}/>
                   </div>
                   </div>


                 


                   {/* End NewsLetter */}


                   {/* End foorter part-1 */}


               </div>
           {/* Footer part-2 */}
       <div className="part-2 bg-darkShadeBlue text-white flex flex-col width-padding
       md:flex-row  items-center justify-center md:justify-between pt-4 pb-5 font-semibold" >
           {/* Logo */}
       <Link to="/" onClick={scrollTopOnClick}> <img className="pt-5 sm:pt-0"  src={imgUrl} alt="logo" width="200" height="48"/></Link>
       {/* Paragraph touts droits */}
       <p className="text-center mt-5 mb-8 font-semibold">{`© Minutup ${new Date().getFullYear()} . ${t("footer.droits")}`}</p>
       {/* Social Icons */}
       <div className="flex">
       <a href="https://www.facebook.com/minutup/" rel="noopener noreferrer" target="_blank" className="pr-3 rotate-360deg"><FacebookIcon/></a>
       <a href="https://www.instagram.com/minutup/" rel="noopener noreferrer" target="_blank" className="pr-3 rotate-360deg"><InstagramIcon/></a>
       </div>
       </div>
       {/* End Footer part-2 */}
       </footer>
   )
}


export default Footer;
