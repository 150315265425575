import {createSlice} from '@reduxjs/toolkit';

const initialState = {
    currentUser : JSON.parse(localStorage.getItem('user')) || null,
    loading: false,
    error: false
}


export const userSlice = createSlice({
    name:"user",
    initialState,
    reducers:{

        loginStart : (state) => {
            state.loading=true
        },
        loginSuccess : (state,action) => {
            state.loading=false
            state.currentUser=action.payload
            // localStorage.setItem('user',JSON.stringify(state.currentUser))
        },
        loginFailure : (state) => {
            state.loading=false
            state.error=true
        },
        logoutUser : (state) => {
            state.currentUser = null
            state.loading=false
            state.error=false
            localStorage.setItem('user',JSON.stringify(state.currentUser))
        },
        setUser: (state, action) => {
            state.loading = false
            state.currentUser = action.payload
        } 
        
    }

})

export const {loginStart, loginSuccess, loginFailure, logoutUser} = userSlice.actions
export default userSlice.reducer;