import { configureStore,combineReducers } from "@reduxjs/toolkit";
import userReducer from './userSlice'
import reservationReducer from "./reservationSlice";
import paymentReducer from "./paymentSlice";
import billingAddressReducer from "./billingAddressSlice";
import codePromoSlice from "./codePromoSlice";

const rootReducer=combineReducers({
    user:userReducer,
    reservations:reservationReducer,
    payment: paymentReducer,
    billingAddress:billingAddressReducer,
    codePromo: codePromoSlice
  })
export const store = configureStore({
    reducer:rootReducer,
})

