
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useRef, useEffect } from "react";



// Click outside hook .. 


export const useOutsideClick = (callback) => {
    const ref = useRef();
  
    useEffect(() => {
        
      const handleClick = (event) => {
        
        callback(event);
      };
  
      document.addEventListener('click', handleClick);
  
      return () => {
        document.removeEventListener('click', handleClick);
      };
    }, []);
  
    return ref;
  };

