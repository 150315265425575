/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { userRequest } from "../api/requestMethods";
import { useEffect } from "react";
import useRefreshToken from "./useRefreshToken";
import { useNavigate } from "react-router-dom";
// import jwt_decode from 'jwt-decode';
import { useTranslation } from "react-i18next";

const useAxiosPrivate = () => {

    const {t}= useTranslation();
    const currentUser = JSON.parse(localStorage.getItem("user"));

    const refresh = useRefreshToken(currentUser);

    const navigate = useNavigate();

    useEffect(() => {



        const requestIntercept = userRequest.interceptors.request.use(
            config => {
                const API_KEY = process.env.REACT_APP_SERVER_API_KEY
                // console.log('API_KEY : ', API_KEY)
                config.headers['apikey'] = API_KEY;
                if (!config.headers['Authorization'] && !config.url.includes("auth/logout") && !config.url.includes("admin/auth/logout")) {
                    config.headers['Authorization'] = `Bearer ${currentUser?.accessToken}`;
                }
                return config;
            }, (error) => Promise.reject(error)
        );

        const responseIntercept = userRequest.interceptors.response.use(
            response => response,
            async (error) => {

                const prevRequest = error?.config;
                if ((error?.response?.status === 401 && !prevRequest?.sent)) {
                    // || error?.config?.url.includes("auth/logout") || error?.config?.url.includes("admin/auth/logout")
                    prevRequest.sent = true;

                    // const decodedData = jwt_decode(currentUser?.accessToken)

                    const infos = await refresh();

                    if (infos.accessToken) {

                        if (!error?.config?.url.includes("auth/logout") || !error?.config?.url.includes("admin/auth/logout")) {
                            const newAccessToken = infos.accessToken
                            prevRequest.headers['Authorization'] = `Bearer ${newAccessToken}`;
                        } else {
                            const newRefreshToken = infos.refreshToken
                            prevRequest.data = { "refreshToken": `Bearer ${newRefreshToken}` };
                        }
                        return userRequest(prevRequest);
                    }

                    error.message = t("compte.sessionExpired")
                    // displayToast(t("reservationStep2.promoCodeMessage"), "success");
                    setTimeout(() => {
                        const i18nextLngValue = localStorage.getItem('i18nextLng');
                        const CookieConsent = localStorage.getItem("CookieConsent");
                        localStorage.clear();
                        if(CookieConsent){
                            localStorage.setItem('CookieConsent', CookieConsent);
                        } 
                        localStorage.setItem('i18nextLng', i18nextLngValue);
                        localStorage.setItem('compte.sessionExpired', 'expired')
                        navigate("/", { replace: true })
                    }, 3000);

                    // return Promise.reject(error);

                }
                // console.log('Error response ', error)
                if (error?.response?.status === 400 && error?.response?.data?.message === 'error.startTime.or.and.endTime.are.not.valid') {
                    // return Promise.reject(error)
                    return {
                        success: false,
                        message: 'error.startTime.or.and.endTime.are.not.valid'
                    }
                }
                
                // error.message = `Votre session a expirée, merci de vous reconnecter!`
                // setTimeout(() => {
                //     localStorage.clear()
                //     navigate("/", { replace: true })
                // }, 3000);
                return Promise.reject(error);
            }
        );

        return () => {
            userRequest.interceptors.request.eject(requestIntercept);
            userRequest.interceptors.response.eject(responseIntercept);
        }
    }, [refresh])

    return userRequest;
}

export default useAxiosPrivate;