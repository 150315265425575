import {publicRequest} from '../api/requestMethods';


const useRefreshToken = (currentUser) => {
    // const { currentUser } = useSelector(state => state.user.currentUser)
    // const dispatch = useDispatch()
    

    const refresh = async () => {
        let route = ''
        if (currentUser?.userType && currentUser?.userType === 'A') {
            route = `admin/auth/accessToken`
        } else {
            route = `auth/accessToken`
        }
        try {
            const response = await publicRequest.post(route, { refreshToken: currentUser?.refreshToken });
            // setAuth(prev => {
            //     return { ...prev, accessToken: response.data.accessToken }
            // });
            // dispatch(
            //     loginSuccess({ ...currentUser, accessToken: response.data.accessToken })
            // )
            if (response.data) {
                // const user = JSON.parse(localStorage.getItem("user"));
                currentUser.accessToken = response.data.accessToken;
                
                localStorage.setItem('user', JSON.stringify(currentUser));
                
            }
            return response.data;
        } catch (error) {
            return {success : false};
            
        }
    }
    return refresh;
};

export default useRefreshToken;