import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import "./i18n"
import { Suspense } from 'react';


if(!window.location.href.includes("ecapsule-admin") && !window.location.href.includes("ecapsule-login")){
  const scriptMatomo = `
  var _mtm = window._mtm = window._mtm || [];
  _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
  (function() {
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='https://suivi.media-dom.fr/js/container_z1VBaG7k.js'; s.parentNode.insertBefore(g,s);
  })();
`;

const scriptElement = document.createElement('script');
scriptElement.innerHTML =scriptMatomo;
const headElement = document.getElementsByTagName('head')[0];
headElement.insertBefore(scriptElement, headElement.firstChild);
}

// import ReactGA from "react-ga4";
const cookieConsent =localStorage.getItem("CookieConsent");


//Initialize GA4
// const TRACKING_ID = process.env.REACT_APP_TRACKING_ID; // TRACKING_ID
if(cookieConsent==="agree" && !window.location.href.includes("ecapsule-admin")){
 //ReactGA.initialize(TRACKING_ID);
 const scriptHead = `
(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
 new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
 j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
 'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
 })(window,document,'script','dataLayer','GTM-MJJQ72N');
 `;   
 
 
 const scriptElement = document.createElement('script');
 scriptElement.innerHTML =scriptHead;
 const headElement = document.getElementsByTagName('head')[0];
 headElement.insertBefore(scriptElement, headElement.firstChild);

  // Create a new noscript element
  let noscript = document.createElement('noscript');
  const iframe = document.createElement("iframe");
  iframe.src="https://www.googletagmanager.com/ns.html?id=GTM-MJJQ72N"
  iframe.height=0;
  iframe.width=0;
  iframe.style.display="none";
  iframe.style.visibility="hidden";
  
  
  noscript.appendChild(iframe);
  // Set the noscript content
  
  
  // Select the body element
  const body = document.body || document.getElementsByTagName('body')[0];
  
  // Insert the noscript at the beginning of the body
  body.insertBefore(noscript, body.firstChild);
}


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
    <BrowserRouter>
    <Suspense>
      <App />
    </Suspense>
    </BrowserRouter>
    </Provider>
 
);

