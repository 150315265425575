/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useCallback, useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import { DateTime } from "luxon";
import { styled } from "@mui/material/styles";
import {
  Button,
  FormControlLabel,
  IconButton,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";
import { Delete, Edit } from "@mui/icons-material";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import Toast from "../../ui/Toast";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import DialogBox from "../../ui/DialogBox";
import AddCoupon from "./AddCoupon";
import { useTranslation } from "react-i18next";

const AdminCoupons = () => {

  const {t}= useTranslation();
  const creneaux = [15, 30, 45, 60];
  const states = ["1", "0"];
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [isDeleting, setIsDeleting] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [descriptionDialog, setDescriptionDialog] = useState("");
  const [titleDialog, setTitleDialog] = useState("");

  const axiosPrivate = useAxiosPrivate();


  const [startDate, setStartDate] = useState();
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const [time, setTime] = useState(5000);

  const [loader, setLoader] = useState(false);

  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const [endDate, setEndDate] = useState();
  // const [status, setStatus] = useState("1");

  useEffect(() => {
    const getCoupons = async () => {
      try {
        const res = await axiosPrivate.get("codepromo");

        if (res) {
          const coupons = [];
          res.data.map((coupon) => {
            coupons.push({
              id: coupon._id,
              libelle: coupon.libelle,
              value: coupon.value,
              startDate: DateTime.fromISO(coupon.startDate)
                .setLocale("fr")
                .toLocaleString(DateTime.DATE_SHORT),
              endDate: DateTime.fromISO(coupon.endDate)
                .setLocale("fr")
                .toLocaleString(DateTime.DATE_SHORT),
              status: coupon.status === 1 ? "Actif" : "Inactif",
            });
          });
          // dispatch(getCouponSuccess(res.data));
          setTableData(coupons);
        }
      } catch (error) {}
    };
    getCoupons();
    return () => {};
  }, []);

  const handleCreateNewRow = (values) => {
    tableData.unshift(values);
    setTableData([...tableData]);
  };

  const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
    if (!Object.keys(validationErrors).length) {
      setLoader(true);
      const newData = {
        couponId: values.id,
        libelle: values.libelle,
        value: values.value,
        startDate: startDate
          ? startDate
          : DateTime.fromFormat(
              row.original?.startDate,
              "dd/MM/yyyy"
            ).setLocale("fr"),
        endDate: endDate
          ? endDate
          : DateTime.fromFormat(row.original?.endDate, "dd/MM/yyyy").setLocale(
              "fr"
            ),
        status: values.status === "Actif" ? "1" : "0",
      };
      try {
        const res = await axiosPrivate.patch("codepromo", newData);
        if (res) {
          tableData[row.index] = values;
          setTableData([...tableData]);
          setLoader(false);
          displayToast("Modification réussie", "success");
          exitEditingMode();
        }
      } catch (error) {
        setLoader(false);
        displayToast(t(error.response.data.message), "error");
      }
    }
  };

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleValidate = () => {
    setIsDeleting(true);
    setOpenDialog(false);
  };

 

  const handleDeleteRow = useCallback(
    async (row) => {
      // setDescriptionDialog(
      //   `Êtes vous sûr de vouloir supprimer "${row.getValue("libelle")}" ?`
      // );
      // setTitleDialog("Suppression");
      // setOpenDialog(true);
      // if (isDeleting) {
      try {
        const res = await axiosPrivate.delete(`codepromo/${row.original?.id}`);
        if (res.data.success) {
          tableData.splice(row.index, 1);
          setTableData([...tableData]);
          displayToast("Suppression réussie", "success");
          // setOpenDialog(false)
        }
      } catch (error) {
        displayToast(t(error.response.data.message), "error");
      }
      // }
    },
    [tableData]
  );


  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  const columns = useMemo(
    () => [
      {
        accessorKey: "id",
        header: "ID",
        hiden: true,
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        Edit: ({ cell, row, column, table }) => <></>,
        // size: 80,
      },
      {
        accessorKey: "libelle",
        header: "Libelle",
        // size: 140,
        // Edit: ({ cell, column, table}) => <AddCoupon />,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "value",
        header: "Valeur",
        // size: 80,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
          select: true, //change to select for a dropdown
          children: creneaux.map((status) => (
            <MenuItem key={status} value={status}>
              {status}
            </MenuItem>
          )),
        }),
      },
      {
        accessorKey: "startDate",
        header: "Date de debut",
        dateSetting: {
          local: "fr-FR",
        },
        Edit: ({ cell, column, row, table }) => (
          // ...getCommonEditTextFieldProps(cell),
          // children:

          // <LocalizationProvider dateAdapter={AdapterLuxon}>
          //   <DatePicker value={column.accessorKey} />
          // </LocalizationProvider>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale="fr"
            key={"startDate-local"}
          >
            <DatePicker
              key={column.columnDef.accessorKey}
              label={column.columnDef.header}
              format="dd/MM/yyyy"
              closeOnSelect
              defaultValue={DateTime.fromFormat(
                row.original?.startDate,
                "dd/MM/yyyy"
              ).setLocale("fr")}
              // minDate={DateTime.now()}
              onChange={(e) => {
                setStartDate(DateTime.fromISO(e).setLocale("fr"));
              }}
              value={startDate}
            />
          </LocalizationProvider>
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell, column }) => ({
          // ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: "endDate",
        header: "Date de Fin",
        // size: 100,
        dateSetting: {
          local: "fr-FR",
        },
        muiTableBodyCellEditTextFieldProps: ({ cell, column }) => ({
          // ...getCommonEditTextFieldProps(cell),
          // children: (
          //   <LocalizationProvider dateAdapter={AdapterLuxon}>
          //     <DatePicker value={column.accessorKey} />
          //   </LocalizationProvider>
          // ),
        }),
        Edit: ({ cell, column, row, table }) => (
          // ...getCommonEditTextFieldProps(cell),
          // children:

          // <LocalizationProvider dateAdapter={AdapterLuxon}>
          //   <DatePicker value={column.accessorKey} />
          // </LocalizationProvider>
          <LocalizationProvider
            dateAdapter={AdapterLuxon}
            adapterLocale="fr"
            key={"endDate-local"}
          >
            <DatePicker
              key={column.columnDef.accessorKey}
              label={column.columnDef.header}
              format="dd/MM/yyyy"
              closeOnSelect
              disablePast
              defaultValue={DateTime.fromFormat(
                row.original?.endDate,
                "dd/MM/yyyy"
              ).setLocale("fr")}
              // minDate={DateTime.now()}
              onChange={(e) => {
                setEndDate(DateTime.fromISO(e).setLocale("fr"));
              }}
              value={endDate}
            />
          </LocalizationProvider>
        ),
      },
      {
        accessorKey: "status",
        header: "Status",
        editVariant: "select",
        size: 20,
        editSelectOptions: [
          { value: "1", text: "Actif" },
          { value: "0", text: "Inactif" },
        ],
        Cell: ({ cell }) => (
          <FormControlLabel
            control={
              <IOSSwitch
                sx={{ m: 1 }}
                checked={
                  cell.getValue() === "Actif" || cell.getValue() === "1"
                    ? true
                    : false
                }
              />
            }
          />
        ),
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          // ...getCommonEditTextFieldProps(cell),
          select: true, //change to select for a dropdown
          children: states.map((state) => (
            <MenuItem key={state} value={state === "1" ? "Actif" : "Inactif"}>
              {state === "1" ? "Actif" : "Inactif"}
            </MenuItem>
          )),
        }),
      },
    ],
    []
  );

  return (
    <div className="mt-6 box-border border-2 shadow-lg ml-[3%] max-w-95">
      <MaterialReactTable
        displayColumnDefOptions={{
          "mrt-row-actions": {
            muiTableHeadCellProps: {
              align: "left",
            },
            // size: 60,
          },
        }}
        enableTopToolbar={{ search: true, filtering: false }}
        muiTableHeadCellProps={{
          sx: {
            fontSize: 15,
            fontWeight: "bold",
            color: "rgba(47, 59, 89, 1)",
          },
        }}
        muiTablePaginationProps={{
          // rowsPerPageOptions: [5,10],
          labelRowsPerPage: "Coupons par page", //(<TextField label={'Reservations par page'}/>)
          labelDisplayedRows: ({ from, to, count }) => {
            return `${from}-${to} sur ${count}`;
          },
        }}
        initialState={{
          columnVisibility: { id: false },
        }}
        enableColumnActions={false}
        enableFilters={true}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        enableSorting={false}
        enablePagination={true}
        enableBottomToolbar={true}
        columns={columns}
        data={tableData}
        editingMode="modal" //default
        // enableEditing
        enableRowActions
        positionActionsColumn="last"
        onEditingRowSave={handleSaveRowEdits}
        onEditingRowCancel={handleCancelRowEdits}
        renderRowActionMenuItems={({ row, table }) => [
          <MenuItem key={`${row.original?.id}-edit`}>
            <Tooltip arrow placement="left" title="Modifier">
              <IconButton onClick={() => table.setEditingRow(row)}>
                <Edit />
              </IconButton>
            </Tooltip>
          </MenuItem>,
          <MenuItem key={`${row.original?.id}-delete`}>
            <Tooltip arrow placement="left" title="Supprimer">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </MenuItem>,
        ]}
        renderTopToolbarCustomActions={() => (
          <Button
            className=""
            sx={{
              // width: 360,
              backgroundColor: "lightgreen",
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",
              fontSize: 15,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "darkgreen",
                color: "white",
              },
              color: "rgba(47, 59, 89, 1)",
              marginBottom: 4,
              marginTop: 2,
            }}
            onClick={() => setCreateModalOpen(true)}
            variant="contained"
          >
            Ajouter
          </Button>
        )}
      />
      <AddCoupon
        columns={columns}
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        onSubmit={handleCreateNewRow}
      />
      <DialogBox
        isOpened={openDialog}
        description={descriptionDialog}
        title={titleDialog}
        onValidated={handleValidate}
        onClose={handleCloseDialog}
      />
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
    </div>
  );
};

// const validateRequired = (value) => !!value.length;

// const validateEndDate = (value) => true; //(start > end ? true : false);
// const validateStartDate = (value) => true;
// const validateStatus = (value) => (value === 1 || value === 0 ? true : false);
// const validateLibelle = (value) => (typeof value === "string" ? true : false);
// const validateValue = (value) => (typeof value === "number" ? true : false);

export default AdminCoupons;
