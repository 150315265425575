/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { MastercardIcon, RemoveIcon } from "../../data/Icons";
import Button from "../ui/Button";
import { useState } from "react";
import AddMethod from '../widgets/paiement/AddMethod';
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import Modal from "../ui/Modal";
import Toast from "../ui/Toast";
import { useDispatch } from "react-redux";
import {deletePaymentSuccess} from "../../redux/paymentSlice";
import { useTranslation } from "react-i18next";


const MoyensPaiement = ()=>{
    const {t}= useTranslation();
    const [openModal,setIsModalOpen] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem("user"));
    const cards = currentUser.cards;
    const axiosPrivate = useAxiosPrivate();
    const dispatch = useDispatch();

    //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time= 5000;
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };
 


    const handleOpenModal = () => {
        setIsModalOpen(true);
      };
    
      const handleCloseModal = () => {
        setIsModalOpen();
      };


    // Remove
const removeCard = (id)=>{

  const deleteCard = async ()=>{

    const res = await axiosPrivate.delete(`user/card`, {data:{'cardId':id}});
    try{
      if(res){
        displayToast(t("moyensPaiement.carteSupprimer"),"success");
        dispatch(deletePaymentSuccess(id))
        const newCards = cards.filter(item => item.cardExternalId !== id);
        currentUser.cards=newCards;
        const userToJson = JSON.stringify(currentUser);
        localStorage.setItem("user",userToJson)
      }
    }catch(error){
      displayToast(t("moyensPaiement.porblemeSupprimer"),"error")
    }

}

// run function
deleteCard().catch((error) => {
  // catch axios error

  displayToast(t(error.message), "error");
 
});;
}



// Add new card 

const addNewCard = ()=>{
    handleOpenModal();
}

    return (
         <div className="width-padding min-h-50vh relative  pb-28">
        <h2 className="text-darkBlue sm:text-center text-xl font-bold mb-2">{t("moyensPaiement.titre")}</h2>
        
        {(cards && cards.length > 0)?
        cards.map((card)=>{
          return(
          <div key={card.cardExternalId} className="box md:max-w-80 semimd:max-w-45 mx-auto my-4 bg-white p-4 shadow-box border  border-whisperGreey">
        {/* Single card */}
        <div className="the-card flex flex-wrap justify-between items-center font-semibold text-darkBlue">
        <div className="flex gap-2 items-center">
        <MastercardIcon/>
        <span className="label">{card.label}</span>
        </div>
        <div className="flex gap-2 items-center">
        <span className="alias">{card.alias}</span>
        <div className="cursor-pointer" onClick={()=>{
          removeCard(card.cardExternalId);
        }}>
            <RemoveIcon/>
        </div>
        </div>
        </div>
        </div>
        );
  }) 
      
        : <div className="box md:max-w-80  semimd:max-w-45 mx-auto my-4 bg-white px-4 py-8 shadow-box border mt-10  border-whisperGreey">
    <h2 className="text-center font-semibold text-darkBlue">{t("moyensPaiement.aucunCard1")}</h2>
    <p className="mt-2 text-darkBlue text-center font-medium">({t("moyensPaiement.aucunCard2")}) </p>
  </div>
        }
        {/* Ajouter */}
<div className=" mt-20 mb-4 w-5/6  sm:w-full absolute -translate-x-1/2  left-1/2 bottom-1">
<Button disabled={cards?.length===3} btnContent={t("moyensPaiement.ajouterMoyen")}
 customStyle={"bg-lightGreen px-4 sm:px-8 rounded-2xl hover:bg-darkGreen text-xs sm:text-base  flex  mx-auto text-white"}
onClick={addNewCard}
/>
</div>
<Modal isOpen={openModal} onClose={handleCloseModal}>
    <div>
    <AddMethod close={handleCloseModal}/>
    </div>
</Modal>
 {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
        </div>
 
        
    )
}


export default MoyensPaiement;