/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import Button from "../../ui/Button";
import Toast from "../../ui/Toast";
import Loader from "../../ui/Loader";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import {useDispatch } from "react-redux";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";
import {
  setAddresses,
  setChoosedAddress,
} from "../../../redux/billingAddressSlice";

const AddFacture = ({ close, modify }) => {
  const dispatch = useDispatch();
  const {t}= useTranslation();

  const {
    register,
    handleSubmit,
    setError,
    clearErrors,
    formState: { errors, isValid },

  } = useForm({
    defaultValues: {
libelle:modify?.adresse?.label?  modify.adresse.label : "",
adresse:modify?.adresse?.address? modify.adresse.address  : "",
codePostal:modify?.adresse?.zipCode? modify.adresse.zipCode : "",
ville:modify?.adresse?.city? modify.adresse.city : "",
pays:modify?.adresse?.country? modify.adresse.country : "",
type: modify?.adresse?.type? modify.adresse.type : "INDIV", // set default value for the "type" field to "PRO"

    },
  });
  const axiosPrivate = useAxiosPrivate()
  const [isSending, setIsSending] = useState(false)
  const [newAddress, setNewAddress] = useState(null)
  const currentUser = JSON.parse(localStorage.getItem('user'))


  const [loader, setLoader] = useState(false);
  //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };



  // inputName, condition to check
  const onChangeSetError = (inputName, condition) => {

    if (condition) {

      setError(inputName, true)
    }
    else {
      clearErrors(inputName)

    }
  }
  const handleSend = (data) => {
    setIsSending(true)
    setNewAddress({ ...data })
  }

  useEffect(() => {

    const controller = new AbortController()

    if (!isSending) {
      return () => {
        controller.abort()
      }
    }

    const addBillingAddress = async (data) => {
      try {
        const res = await axiosPrivate.post("user/billingAddress", {
          "label": data.label,
          "address": data.address,
          "zipCode": data.zipCode,
          "city": data.city,
          "country": data.country,
          "type": data.type
        });


        if (res) {
          if(currentUser.billingAddress){
            currentUser.billingAddress.push(res.data.billingAddressId)
           
          }
          else{
            currentUser.billingAddress=[res.data.billingAddressId]
           
          }
          const jsonFormatUser= JSON.stringify(currentUser);
          localStorage.setItem("user",jsonFormatUser);
          dispatch(setAddresses(currentUser.billingAddress))
          displayToast(t("compte.informationsSaved"), "success");
          dispatch(setChoosedAddress(res.data.billingAddressId));

          setTimeout(() => {
            
              close();
            setLoader(false);
          }, 1500);
        }
      } catch (error) {
        if (error.response.status !== 400) {
          displayToast(t(error.message), "error")
          setLoader(false);
        }
        if (error.response.status === 400) {
          displayToast(t("adresseFacturation.maxAdresseMessage"), "error")

          setLoader(false);
        }
      }
    };

    setLoader(true)
   if(!modify){
    addBillingAddress({
      label: newAddress.libelle,
      address: newAddress.adresse,
      zipCode: newAddress.codePostal,
      city: newAddress.ville,
      country: newAddress.pays,
      type: newAddress.type,
    })
   }
   //Patch 
   else{
    const modifyBillingAddress = async (data) => {
      
      setLoader(true);
      const newData = { "label": data.label,
      "address": data.address,
      "zipCode": data.zipCode,
      "city": data.city,
      "country": data.country,
      "type": data.type};
      try {
        const res = await axiosPrivate.patch(`user/billingAddress/${modify.adresse._id}`, {
          ...newData
        });
        if(res){
          const billingAddress = currentUser.billingAddress;
          const index = billingAddress.findIndex(item=>item._id===modify.adresse._id)
          if (index !== -1) {
            billingAddress[index] = { ...billingAddress[index], ...newData };
            currentUser.billingAddress=billingAddress;
            const jsonFormatUser= JSON.stringify(currentUser);
            localStorage.setItem("user",jsonFormatUser);
            dispatch(setAddresses(currentUser.billingAddress))
            displayToast(t("compte.informationsSaved"), "success");
            dispatch(setChoosedAddress(res.data.billingAddressId));
            setTimeout(() => {
            
              close();
            setLoader(false);
          }, 1500);
          }
        }

        }
        catch(error){

          displayToast(t(error.message),"error");

        }
        setLoader(true);
       }
       modifyBillingAddress({ label: newAddress.libelle,
        address: newAddress.adresse,
        zipCode: newAddress.codePostal,
        city: newAddress.ville,
        country: newAddress.pays,
        type: newAddress.type,});
      }

  },[isSending, newAddress]);

  return (
    <div>
      <h3 className="text-darkBlue text-lg font-semibold mb-4">
       {modify?t("adresseFacturation.modifierAdresse"):t("adresseFacturation.nouvelleAdresse")}
      </h3>
      {/* Checkboxs */}
      <form onSubmit={handleSubmit(handleSend)}>
        {/* Pro or perso */}
        <div className="flex gap-x-20 flex-wrap">
          <div className="flex items-center">
            <input
              className="h-5 w-5 accent-darkBlue"
              name="PRO"
              value="PRO"
              type="radio"
              {...register("type", { required: true })}
            />
            <label className="text-darkBlue font-semibold ml-2">
              {t("adresseFacturation.professionnelle")}
            </label>
          </div>
          <div className="flex items-center">
            <input
              className="h-5 w-5 accent-darkBlue"
              name="INDIV"
              value="INDIV"
              type="radio"
              {...register("type", { required: true })}
            />
            <label className="text-darkBlue font-semibold ml-2">
            {t("adresseFacturation.personnelle")}
            </label>
          </div>
        </div>

        {/* Libellé */}
        <div className="form-fields mt-5">
          <div className="mt-3">
            <label className="text-darkBlue block mb-2 font-semibold">
            {t("adresseFacturation.libelle")}
            </label>
            <input
              type="text"
              name="libelle"
              {...register("libelle", {
                required: true, onChange: ((e) => {
                  onChangeSetError("libelle", e.target.value.length < 1);
                })
              })}
              className="text-darkBlue block w-full placeholder-gray-500
          placeholder-opacity-75 font-medium border-style3 
       focus:outline-none focus:border-darkGreen 
       "
            />
            {errors.libelle && (
              <span className="text-red-400 text-sm">
              {t("moyensPaiement.champObligatoire")}
              </span>
            )}
          </div>
          {/* Adresse */}
          <div className="mt-3">
            <label className="text-darkBlue block mb-2 font-semibold">
            {t("adresseFacturation.adresse")}
            </label>
            <input
              type="text"
              name="adresse"
              {...register("adresse", {
                required: true, onChange: ((e) => {
                  onChangeSetError("adresse", e.target.value.length < 1);
                })
              })}
              className="text-darkBlue block w-full 
           font-medium border-style3 
       focus:outline-none focus:border-darkGreen
       "
            />
            {errors.adresse && (
              <span className="text-red-400 text-sm">
              {t("moyensPaiement.champObligatoire")}
              </span>
            )}
          </div>
          {/* Code postal */}
          <div className="mt-3">
            <label className="text-darkBlue block mb-2 font-semibold">
            {t("adresseFacturation.codePostal")}
            </label>
            <input
              type="number"
              name="codePostal"
              {...register("codePostal", {
                required: true, pattern: /^\d{5}$/, onChange: ((e) => {

                  onChangeSetError("codePostal", !(/^\d{5}$/.test(e.target.value)));
                })
              })}
              className="text-darkBlue block w-full font-medium border-style3 
       focus:outline-none focus:border-darkGreen"
            />
            {errors.codePostal && (
              <span className="text-red-400 text-sm">
              {t("adresseFacturation.messageErrPostal")}
              </span>
            )}
          </div>
          {/* Ville */}
          <div className="mt-3">
            <label className="text-darkBlue block mb-2 font-semibold">
            {t("adresseFacturation.ville")}
            </label>
            <input
              type="text"
              name="ville"
              {...register("ville", {
                required: true, onChange: ((e) => {

                  onChangeSetError("ville", e.target.value.length < 1);
                })
              })}
              className="text-darkBlue block w-full 
           font-medium border-style3 
       focus:outline-none focus:border-darkGreen
       "
            />
            {errors.ville && (
              <span className="text-red-400 text-sm">
              {t("moyensPaiement.champObligatoire")}
              </span>
            )}
          </div>
          {/* Pays */}
          <div className="mt-3">
            <label className="text-darkBlue block mb-2 font-semibold">
            {t("adresseFacturation.pays")}
            </label>
            <input
              type="text"
              name="pays"
              {...register("pays", {
                required: true, onChange: ((e) => {
                 
                  onChangeSetError("pays", e.target.value < 1);
                })
              })}
              className="text-darkBlue block w-full 
           font-medium border-style3 
       focus:outline-none focus:border-darkGreen
       "
            />
            {errors.pays && (
              <span className="text-red-400 text-sm">
              {t("moyensPaiement.champObligatoire")}
              </span>
            )}
          </div>
        </div>
        <div className="flex gap-2 items-center justify-center my-5 flex-wrap">
          <Button
            disabled={!isValid || loader}
            btnContent={t("compte.save2")}
            customStyle={
              "btn-principal inline-block text-white rounded-lg px-10 sm:px-14"
            }
            type="submit"
          />

        </div>
        {loader && (
          <div className="flex items-center justify-center my-2 mb-2">
            <Loader />
          </div>
        )}
      </form>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={5000}
        />
      )}
    </div>
  );
};

export default AddFacture;
