/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React from 'react'

// rounded
const Button = ({btnContent,customStyle,onClick,type,disabled}) => {
 return (
  
  <button onClick={onClick} type={type} disabled={disabled} className={`btn  px-8 py-3 text-base leading-5 font-semibold font-inter  ${customStyle} ${disabled ? 'opacity-50 cursor-not' : ''}`}>
  {btnContent}
</button>
 )
}


export default Button
