
import { createSlice } from "@reduxjs/toolkit";



const initialState = {
    addresses: [],
    choosedAddress:null,
    error: false
  };

  export const  billingAddressSlice= createSlice({
    name: "billingAddress",
    initialState,
    reducers: {
      setAddresses: (state,action)=>{
        state.addresses = action.payload;
       
      },
      setChoosedAddress:(state,action)=>{
        state.choosedAddress=action.payload;
      }
    },
    
   });


    export const {
      setAddresses,
      setChoosedAddress
    } = billingAddressSlice.actions;


    export default billingAddressSlice.reducer;