import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  coupons: [],
  loading: false,
  error: false,
};

export const couponSlice = createSlice({
  name: "coupon",
  initialState,
  reducers: {
    
    getCouponStart: (state) => {
        state.loading = true
    },

    getCouponSuccess: (state, action) => {
      state.loading = false;
      if (action.payload) { 
        state.coupons = [...state.coupons, ...action.payload].filter((value, index, self) =>
          index === self.findIndex((t) => (
            t._id === value._id
          ))
        )
      }
    },

    getCouponFailure: (state, action) => {
      state.loading = false
      state.error = true
    },

    deleteCouponStart: (state) => {
        state.loading = true
    },

    deleteCouponSuccess: (state, action) => {
        state.loading = false
        state.coupons.splice(
            state.coupons.findIndex((item) => item._id === action.payload),
            1
        )
    },

    deleteCouponFailure: (state) => {
        state.loading = false
        state.error = true
    },

    addCouponStart: (state) => {
        state.loading = true
    },

    addCouponSuccess: (state, action) => {
        state.loading = false
        state.coupons.push(action.payload)
    },

    addCouponFailure: (state) => {
        state.loading = false
        state.error = true
    },
    updateCouponStart: (state) => {
        state.loading = true
    },
    updateCouponSuccess: (state, action) => {
        state.loading = false
        state.coupons[
            state.coupons.findIndex((item) => item._id === action.payload._id)
        ] = action.payload
    },
    updateCouponFailure: (state) => {
        state.loading = false
        state.error = true
    }
  },
});

export const {
  getCouponStart,
  getCouponSuccess,
  getCouponFailure,
  deleteCouponStart,
  deleteCouponSuccess,
  deleteCouponFailure,
  addCouponStart,
  addCouponSuccess,
  addCouponFailure,
  updateCouponStart,
  updateCouponSuccess,
  updateCouponFailure
} = couponSlice.actions;

export default couponSlice.reducer;
