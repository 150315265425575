/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import Button from "../ui/Button";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useState, useEffect } from "react";
import {
  formateDate,
  frenchFormat,
  formatTime,
  isCurrentTimeBetween,
} from "../../utils/utils";
import unLock1 from "../../assets/images/unlock1.gif";
import unLock2 from "../../assets/images/unlock2.png";
import { useDispatch } from "react-redux";
import { setRegistredReservations } from "../../redux/reservationSlice";
import Toast from "../ui/Toast";
import Loader from "../ui/Loader";
import DialogBox from "../ui/DialogBox";
import { useTranslation } from "react-i18next";
import moment from 'moment';
import momentTz from 'moment-timezone';

const MesReservations = () => {
  const {t}= useTranslation();
  const axiosPrivate = useAxiosPrivate();
  const [aVenir, setaVenir] = useState([]); //1
  const [enCours, setEnCours] = useState([]); //2
  const [passed, setPassed] = useState([]); //3
  const [annulled, setAnnulled] = useState([]); //4
  const [selectedStatus, setSelectedStatus] = useState();
  const [selectedObj, setSelectedObj] = useState([]);
  const [loader, setLoader] = useState({ value: false, showedPlace: null });
  const [showUnlock, setShowUnlock] = useState({ value: false, phase: 1 });
  /*const registredReservations = useSelector(
    (state) => state.reservations.registredReservations
  );*/
  const dispatch = useDispatch();

  const [isCanceling, setIsCanceling] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const descriptionDialog =
    t("mesReservations.certainAnnuler");
  const titleDialog = t("mesReservations.annulation");
  const [choosedResa, setChoosedResa] = useState();

  //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time=5000;
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  //const [isGettingInvoice, setIsGettingInvoice] = useState(false)
  //const [fileDownloadURL, setFileDownloadURL] = useState(null)
  //const [fileName, setFileName] = useState("")
  //const [reservId, setReservationId] = useState("")

  useEffect(() => {
    if (showUnlock.value) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }, [showUnlock]);

  // test length of array array.length>0 (not void array)
  const testLengthArray = (array) => {
    if (array.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  const filterData = (data) => {
    const encours = data
      .filter((reservation) => reservation.status === 2)
      .reverse();
    const avenir = data
      .filter((reservation) => reservation.status === 1)
      .reverse();
    const passed = data
      .filter((reservation) => reservation.status === 3)
      .reverse();
    const annuled = data
      .filter((reservation) => reservation.status === 4)
      .reverse();
    setaVenir(avenir);
    setEnCours(encours);
    setPassed(passed);
    setAnnulled(annuled);

    setSelectedStatus(testLengthArray(encours) ? 2 : 1);
    setSelectedObj(testLengthArray(encours) ? encours : avenir);
  };

  const btnStyle = (textOrClass, startDate, endDate) => {
    if (selectedStatus === 1) {
      if (isCurrentTimeBetween(startDate, endDate)) {
        return textOrClass === "text" ? t("mesReservations.ouvrireCapsule").toUpperCase() : "bg-lightGreen";
      } else {
        /*if(isStartDateCloseToOneHour(startDate)){
            return textOrClass === "text" ? "OUVRIR LA CAPSULE" : "bg-lightGreen";
        }*/

        return textOrClass === "text" ? t("mesReservations.annuler").toUpperCase() : "bg-redDanger";
      }
    } else if (selectedStatus === 2) {
      return textOrClass === "text" ? t("mesReservations.ouvrireCapsule").toUpperCase() : "bg-lightGreen";
    } else if (selectedStatus === 3) {
      return textOrClass === "text" ? t("mesReservations.telechargerFacture").toUpperCase() : "bg-darkBlue";
    } else if (selectedStatus === 4) {
      return textOrClass === "text" ? t("mesReservations.annuler").toUpperCase() : "bg-redDanger";
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const res = await axiosPrivate.get("reservation");
      try {
        if (res) {
          filterData(res.data);
          dispatch(setRegistredReservations(res.data));
        }
      } catch (error) {
        return;
      }
    };

    fetchData().catch((error) => {
      displayToast(t(error.message), "error");
    });
  }, []);

  // ouvrire capsule (attached to the button)

  const ouvrirCapsule = (id) => {
    setShowUnlock({ value: true, phase: 1 });
    const open = async () => {
      setLoader({ value: true, showedPlace: id });
      const res = await axiosPrivate.post("capsule/open", {
        reservationId: id,
      });

      try {
        if (res) {
          if (res.data.success) {
            displayToast(t("reservationStep1.operation"), "success");
            setShowUnlock({ value: true, phase: 2 });
            const DataReservations = res.data.reservations;
            filterData(DataReservations);
            setTimeout(() => {
              setShowUnlock({ value: false, phase: 1 });
            }, 5000);
          } else {
            if (res.data.message) {
              displayToast(res.data.message, "error");
              setShowUnlock({ value: false, phase: 1 });
            }
          }
          setLoader({ value: false, showedPlace: null });
        }
      } catch (error) {
        //catch error request
        setShowUnlock({ value: false, phase: 1 });
        displayToast("Error", "error");
        setLoader({ value: false, showedPlace: null });
      }
    };
    //run
    open().catch((error) => {
      // catch axios error
      setTimeout(() => {
        setShowUnlock({ value: false, phase: 1 });
        setLoader({ value: false, showedPlace: null });
        if (error) {
          displayToast(t(error.response.data.message), "error");
        } else {
        }
      }, 600);
    });
  };

  // downloadInvoice

  const downloadInvoice = (id) => {
    setLoader({ value: true, showedPlace: id });
    const getInvoice = async (id) => {
      try {
        const res = await axiosPrivate.get(`/reservation/invoice/?id=${id}`, {
          responseType: "blob",
        });
        if (res) {
          // const blob = await res.blob()
          const file = new Blob([res.data]);
          // window.open(fileURL)
          // saveAs(file, 'invoice.pdf')
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = "invoice.pdf";
          link.click();
          setLoader({ value: false, showedPlace: id });
        }
      } catch (error) {
        setLoader({ value: false, showedPlace: id });
      }
    };

    getInvoice(id).catch((error) => {
      setLoader({ value: false, showedPlace: id });
      displayToast(t(error.message), "error");
    });
  };

  const handleCancel = () => {
    setOpenDialog(true);
  };

  useEffect(() => {
    // Cancel reservation
    const cancelReservation = () => {
      setLoader({ value: true, showedPlace: choosedResa });
      const cancel = async () => {
        const res = await axiosPrivate.delete(
          `/reservation/cancelReservation/?id=${choosedResa}`
        );
        try {
          if (res) {
            setChoosedResa(res.data.reservations);
            filterData(res.data.reservations);
            displayToast(t("mesReservations.reservationAnnuler"), "success");
            setLoader({ value: false, showedPlace: choosedResa });
          }
        } catch (error) {
          displayToast("Erreur..", "error");
          setLoader({ value: false, showedPlace: choosedResa });
        }
      };
      cancel().catch((error) => {
        setTimeout(() => {
          setLoader({ value: false, showedPlace: null });
          displayToast(t(error.message), "error");
        }, 600);
      });
    };

    if (choosedResa) {
      cancelReservation();
      setChoosedResa();
    }

    return () => {
      setOpenDialog(false);
      setIsCanceling(false);
    };
  }, [isCanceling]);

  return (
    <div className="width-padding min-h-50vh">
      <h2 className="text-darkBlue sm:text-center text-xl font-bold mb-2">
        {t("mesReservations.titre")}
      </h2>
      <div className="flex flex-wrap justify-center font-bold mt-2  mb-4 text-darkBlue">
        <h3
          className={`mx-2 mt-2 cursor-pointer hover:text-lightGreen pr-5 border-r-2 border-r-darkGreey
       ${selectedStatus === 2 ? "text-lightGreen" : ""}`}
          onClick={() => {
            setSelectedStatus(2);
            setSelectedObj(enCours);
          }}
        >
         {t("mesReservations.enCours")}
        </h3>
        <h3
          className={`mx-2 mt-2 cursor-pointer hover:text-lightGreen pr-5 border-r-2 border-r-darkGreey
       ${selectedStatus === 1 ? "text-lightGreen" : ""}`}
          onClick={() => {
            setSelectedStatus(1);
            setSelectedObj(aVenir);
          }}
        >
          {t("mesReservations.A-venir")}
        </h3>
        <h3
          className={`mx-2 mt-2 cursor-pointer border-r-2 border-r-darkGreey hover:text-lightGreen pr-5 
       ${selectedStatus === 3 ? "text-lightGreen" : ""}`}
          onClick={() => {
            setSelectedStatus(3);
            setSelectedObj(passed);
          }}
        >
         {t("mesReservations.passees")}
        </h3>
        <h3
          className={`mx-2 mt-2 cursor-pointer hover:text-lightGreen pr-5 
       ${selectedStatus === 4 ? "text-lightGreen" : ""}`}
          onClick={() => {
            setSelectedStatus(4);
            setSelectedObj(annulled);
          }}
        >
          {t("mesReservations.annulees")}
        </h3>
      </div>

      <div>
        {testLengthArray(selectedObj) ? (
          selectedObj.map((reservation) => {
            return (
              <div
                key={reservation._id}
                className="box md:max-w-80 semimd:max-w-45 mx-auto my-4 bg-white p-4 shadow-box border  border-whisperGreey"
              >
                <div className="flex flex-wrap items-center justify-between  gap-3  sm:gap-0 text-darkBlue font-semibold">
                  <h3>{t("mesReservations.aero")}</h3>
                  <h3 className="bg-lightGreen text-white rounded-xl  py-2 px-5">
                  {t("mesReservations.reservationN")}{reservation.reservationNumber}
                  </h3>
                </div>
                <div className="content-reservation flex flex-col  mt-12 text-darkBlue font-semibold">
                  <h4 className="mb-2 ">{t("mesReservations.reservationDate")}</h4>
                  <span className="font-bold">
                    {frenchFormat(formateDate(reservation.startDate)).replace(/-/g, "/")}{" "}
                    <span className="font-semibold">{t("reservationStep2.de")}</span>{" "}
                    {formatTime(momentTz(reservation.startDate).utc().tz("Europe/Paris").format('yyyy-MM-dd HH:mm'))} {t("reservationStep1.to")}{" "}
                    {formatTime(momentTz(reservation.endDate).utc().tz("Europe/Paris").format('yyyy-MM-dd HH:mm'))}
                  </span>
                  {formateDate(reservation.startDate) !==
                  formateDate(reservation.endDate) ? (
                    <span className="font-bold">
                      &nbsp;({frenchFormat(formateDate(reservation.endDate)).replace(/-/g, "/")})
                    </span>
                  ) : null}
                  <h4 className="mt-4 mb-2">{t("mesReservations.montant")}</h4>
                  <span className="font-bold">{reservation.amount}€ TTC</span>
                </div>
                {selectedStatus && (
                  <div className="flex  justify-center sm:justify-start mt-5 lg:max-w-80 mx-auto">
                    {selectedStatus !== 4 && (
                      <Button
                        onClick={(e) => {
                          if (selectedStatus === 3) {
                            downloadInvoice(reservation._id);
                          } else if (selectedStatus === 1) {
                            if (
                              isCurrentTimeBetween(
                                reservation.startDate,
                                reservation.endDate
                              )
                            ) {
                              ouvrirCapsule(reservation._id);
                              return;
                            }
                            // cancelReservation(reservation._id);
                            setChoosedResa(reservation._id);
                            // setIsCanceling(true);
                            handleCancel();
                          } else if (selectedStatus === 2) {
                            ouvrirCapsule(reservation._id);
                          }
                        }}
                        disabled={
                          selectedStatus === 2 && reservation.isOpened
                          // !isCurrentTimeBetween(
                          //   reservation.startDate,
                          //   reservation.endDate
                          // )
                        }
                        btnContent={btnStyle(
                          "text",
                          reservation.startDate,
                          reservation.endDate
                        )}
                        customStyle={`${btnStyle(
                          "class",
                          reservation.startDate,
                          reservation.endDate
                        )} w-full rounded-xl text-white`}
                      />
                    )}
                  </div>
                )}
                {loader.value && loader.showedPlace === reservation._id && (
                  <div className="flex items-center justify-center my-3 -mb-2">
                    <Loader />
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className="box mt-10 md:max-w-80 semimd:max-w-45 mx-auto my-4 bg-white p-4 shadow-box border  border-whisperGreey">
            <p>
              {selectedStatus === 1 &&
                t("mesReservations.desoleNoReservations")}
              {selectedStatus === 2 &&
                t("mesReservations.noReservations")}
              {selectedStatus === 3 &&
                t("mesReservations.aucunPassee")}
              {selectedStatus === 4 &&
                t("mesReservations.aucuneAnnulee")}
            </p>
          </div>
        )}
      </div>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}

      {showUnlock.value && (
        <div
          className={`${
            showUnlock.phase === 1 ? "bg-greenUnlock1" : "bg-greenUnlock2"
          } fixed flex flex-col justify-center items-center  left-0 top-0 h-screen w-full`}
        >
          <figure>
            <img
              src={showUnlock.phase === 1 ? unLock1 : unLock2}
              width="400px"
              alt="unlock"
            />
          </figure>
          {showUnlock.phase === 1 && (
            <p className="text-white text-xl  font-bold text-center">
            {t("mesReservations.patienterCapsule")} <br />
            {t("mesReservations.patienterCapsule2")}
            </p>
          )}
          {showUnlock.phase === 2 && (
            <p className="text-white text-xl  font-bold text-center">
            {t("mesReservations.porteOuverte")}
            </p>
          )}
        </div>
      )}
      <DialogBox
        isOpened={openDialog}
        description={descriptionDialog}
        title={titleDialog}
        onValidated={() => {
          setIsCanceling(true);
        }}
        onClose={() => {
          setChoosedResa()
          setOpenDialog(false);
        }}
      />
    </div>
  );
};

export default MesReservations;
