/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import Toast from "../ui/Toast";
import { preRegister } from "../../api/Auth";
import { isvalidEmail } from "../../utils/utils";
import Button from "../ui/Button";
import { useOutsideClick } from "../../utils/hooks";
import Loader from "../ui/Loader";
import { useTranslation } from "react-i18next";

const PopupContainer = ({
  type,
  openModal,
  closeMenu,
  inReservation,
  inMobile
}) => {
  const {t} = useTranslation();
  const [email, setEmail] = useState("");
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [loader, setLoader] = useState(false);
  const [time, setTime] = useState(3000);
  // responseType => "success" or "error"
  const [responseType, setResponseType] = useState("success");

  const handleClickOutside = (event) => {
    const box = document.querySelector("#popup-container");
    const boxPopUp = document.querySelector(".mobile .box-popup");
    const boxReservation = document.querySelector(".paiementBox .boxpopup");

    if (
      box &&
      !ref.current.contains(event.target) &&
      !event.target.closest(".reserver-btn") &&
      !event.target.closest(".inscription") &&
      !event.target.closest(".connexion") &&
      !event.target.closest(".toast")
    ) {
      if (!boxPopUp?.contains(event.target)) {
        openModal();
        return;
      }

      if (boxReservation) {
        if (!boxReservation.contains(event.target)) {
          openModal();
        }
      } else {
      }

      //openModal();
    }
  };
  const ref = useOutsideClick(handleClickOutside);

  useEffect(() => {
    document.body.classList.add("overflow-hidden");
  }, []);

  // message of toast + type "error" or "success"
  const displayToast = (message, type) => {
    // prepare data for toast
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };

  // callback to close toast
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  // CLick buttons

  const handleClick = (e) => {
    e.preventDefault();

    /////// Inscription
    // not valid email (frontend check)
    if (!isvalidEmail(email)) {
      displayToast(t("other.enterValidEmail"), "error");
      setEmail("");
    }

    // valid email call preRegister(email)
    else {
      setLoader(true);

      // PreRegister
      let dataToSend={};
      if(inReservation){
        dataToSend={email,...inReservation}
        
      }
      else{
        dataToSend={email};
       
        
      }
      preRegister(dataToSend, (err, res) => {
        if (err) {
          if (!err.response) {
            displayToast(err.message, "error");
            setLoader(false);
          } else {
            displayToast(err.response.data.error, "error");
            setLoader(false);
          }

          // if true
        } else {
          setTimeout(() => {
            setLoader(false);
          }, 2000);

          setTime(5000)
          displayToast(t("menu.messageInscription"), "success");

          setTimeout(()=>{
            openModal();
            setEmail("");
            if (closeMenu) {
              closeMenu();
            }
          },5000)
        }
      });
    }
  };

  return (
    <div
      id="popup-container"
      className={`px-5 transition duration-150 ease-in-out  flex flex-col   overflow-x-hidden overflow-y-auto fixed  z-50 b-0 left-0 right-0 ${
        inReservation
          ? "bg-[rgba(0,0,0,.3)] top-0 h-full"
          : "bg-darkBlue lg:bg-[rgba(0,0,0,.3)] lg:h-full lg:top-0"
      }`}
    >
      <div className={`relative w-auto ${inMobile?'my-6':''} mx-auto max-w-3xl justify-center items-center`}>
        <div
          ref={ref}
          className={`border-0 box-popup slide-in-top
            rounded-lg shadow-lg relative flex flex-col w-full bg-white ${
              inReservation
                ? "top-1/2 left-1/2 translate-y-1/2 -translate-x-1/2 reserve-popup"
                : "lg:top-1/2 lg:left-1/2 lg:translate-y-1/2 lg:-translate-x-1/2"
            } xs:py-5 py-0 lg:top-1/2 lg:left-1/2 lg:translate-y-1/2 lg:-translate-x-1/2`}
        >
          {/* Close popUp  */}
          <span
            className="closeBtn"
            onClick={() => {
              openModal();
              setOpenToast(false);
              setMessage("");
            }}
          ></span>
          {/* Close button */}

          <div className="flex items-start justify-between p-5  rounded-t ">
            <p className="text-[14px] text-darkBlue font-bold m-auto text-center max-w-80">
              {type?.toLowerCase() === "login"
                ? t("reservationStep1.popUpLogin")
                : t("reservationStep1.textPopUp")}
            </p>
          </div>
          <div className=" px-5 py-2 items-center">
            <input
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              type="email"
              name="email"
              id="email"
              placeholder={
                type?.toLowerCase() === "login"
                  ? t("other.saiseEmail")
                  : t("other.saiseEmail")
              }
              className="p-6 h-[55px] text-darkBlue rounded-[20px] w-full border-solid border-2 border-darkGreey focus:outline-none focus:border-darkGreen"
            />
          </div>
          <div className="flex flex-col items-center justify-center p-6  rounded-b">
            <Button
              onClick={handleClick}
              btnContent={
                type?.toLowerCase() === "login"
                  ? t("menu.connexion")
                  : t("menu.inscription")
              }
              customStyle={"btn-principal text-white"}
            />
            <div
              className={`loader mt-4 absolute bottom-1 ${
                loader ? "block" : "hidden"
              }`}
            >
              <Loader />
            </div>
          </div>
        </div>
      </div>
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={time}
        />
      )}
    </div>
  );
};
export default PopupContainer;
