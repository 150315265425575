/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { MapContainer, Marker, TileLayer, Popup } from 'react-leaflet';
import markerIcon from "../../assets/images/marker-icon.png"
import { useTranslation } from 'react-i18next';
import L from 'leaflet';
import "leaflet/dist/leaflet.css"

const MapxBox = () => {
const {t} = useTranslation();
const zoom = 20;
const position = [45.71688099482367, 5.077718451427333];
const myIcon = L.icon({
  iconUrl: markerIcon,
  iconSize: [25, 38],
  iconAnchor: [25, 41],
  popupAnchor: [-10, -34],
  shadowSize: [41, 41]
});

return (
  <MapContainer center={position} zoom={zoom} scrollWheelZoom={false}
   dragging={!L.Browser.mobile} 
   tap={!L.Browser.mobile }
   >
    <TileLayer
      attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
    />
    <Marker position={position} icon={myIcon}>

<Popup><b>{t("capsulePage.mapInfo")}</b></Popup>
</Marker> 

  </MapContainer>
);
}

export default MapxBox;