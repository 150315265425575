/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useEffect, useMemo, useState } from "react";
import MaterialReactTable from "material-react-table";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { DateTime } from "luxon";
import { Chip, Stack, Typography, Box, Button } from "@mui/material";
import { capitalizeFirstLetter } from "../../../utils/utils";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import * as BUIx from "../../ui/Button";

const AdminReservation = ({ donnees }) => {

  const axiosPrivate = useAxiosPrivate();
  const {t}= useTranslation();
  

  // const reservations = [] 
  const [csvData, setCsvData] = useState([])// data to download as csv

  
  const [tableData, setTableData] = useState([]);

  useEffect(() => {
    const table = document.querySelector(".MuiTable-root");
    if (table) {
      table.style = "";
    }
    const controller = new AbortController();
    const getReservation = async () => {
      try {
        const res = await axiosPrivate.get("admin/dash/reservations", {
          headers: {
            'apikey': process.env.REACT_APP_SERVER_API_KEY,
          }
        });
        if (res) {
          const reservations = [];
          const csvDataArray = [];
          res.data.reverse().map((reservation, index) => {
            let status = "";
            let facture = "N/A";
            switch (reservation.status) {
              case 1:
                status = "À VENIR";
                break;
              case 2:
                status = "EN COURS";
                break;
              case 3:
                status = "PASSÉE";
                facture = reservation._id;
                break;
              case 4:
                status = "ANNULÉE";
                break;
              default:
                break;
            }
            // PASSÉE  ANNULÉE
            reservations.push({
              client: reservation?.name?.last
                ? `${capitalizeFirstLetter(`${reservation?.name?.first}`)} ${capitalizeFirstLetter(`${reservation?.name?.last}`)}`
                : "",
              lieu: reservation?.address
                ? reservation.address
                : "Aéroport de Lyon",
              date: DateTime.fromISO(reservation.startDate)
                .setLocale("fr")
                .toLocaleString(DateTime.DATE_SHORT),
              startTime: DateTime.fromISO(reservation.startDate)
                .setLocale("fr")
                .toLocaleString(DateTime.TIME_SIMPLE),
              endTime: DateTime.fromISO(reservation.endDate)
                .setLocale("fr")
                .toLocaleString(DateTime.TIME_SIMPLE),
              reservationId: reservation.reservationNumber,
              resaId: reservation._id,
              status: status,
              statusx: status,
              facture: facture,
              Ext_CustomerId: reservation?.Ext_CustomerId,
              user: {
                name: {
                  first: reservation?.name?.first ? capitalizeFirstLetter(reservation?.name?.first) : "",
                  last: reservation?.name?.last ? capitalizeFirstLetter(reservation?.name?.last) : "",
                },
                ...reservation?.userId,
              },
            });
            csvDataArray.push({
              // client: reservation?.name?.last
              //   ? `${capitalizeFirstLetter(`${reservation?.name?.first}`)} ${capitalizeFirstLetter(`${reservation?.name?.last}`)}`
              //   : "",
              // lieu: reservation?.address
              //   ? reservation.address
              //   : "Aéroport de Lyon",
              // date: DateTime.fromISO(reservation.startDate)
              //   .setLocale("fr")
              //   .toLocaleString(DateTime.DATE_SHORT),
              // startTime: DateTime.fromISO(reservation.startDate)
              //   .setLocale("fr")
              //   .toLocaleString(DateTime.TIME_SIMPLE),
              // endTime: DateTime.fromISO(reservation.endDate)
              //   .setLocale("fr")
              //   .toLocaleString(DateTime.TIME_SIMPLE),
              // reservationId: reservation.reservationNumber,
              // status: status,
              ...reservations[index],
              email: reservation?.userId?.email,
              newsletter: reservation?.userId?.newsLetterSub === true ? 'Active' : 'Désactive',
              facture: reservation?.userId?.invoiceSub === true ? 'Active' : 'Désactive',
              langue: reservation?.userId?.language ? reservation?.userId?.language : '',
              compte: reservation?.userId?.disabled === false ? 'Actif' : 'Désactiver',
              createdAt: reservation?.userId?.createdAt ? DateTime.fromISO(reservation?.userId?.createdAt).toLocaleString(DateTime.DATETIME_SHORT) : '' ,
              address1: reservation?.userId?.billingAddress[0] ? reservation?.userId?.billingAddress[0].address : '',
              ville1: reservation?.userId?.billingAddress[0] ? reservation?.userId?.billingAddress[0].city : '',
              pays1: reservation?.userId?.billingAddress[0] ? reservation?.userId?.billingAddress[0].country : '',
              address2: reservation?.userId?.billingAddress[1]? reservation?.userId?.billingAddress[1].address : '',
              ville2: reservation?.userId?.billingAddress[1]? reservation?.userId?.billingAddress[1].city : '',
              pays2: reservation?.userId?.billingAddress[1]? reservation?.userId?.billingAddress[1].country : '',
              address3: reservation?.userId?.billingAddress[2] ? reservation?.userId?.billingAddress[2].address : '',
              ville3: reservation?.userId?.billingAddress[2] ? reservation?.userId?.billingAddress[2].city : '',
              pays3: reservation?.userId?.billingAddress[2] ? reservation?.userId?.billingAddress[2].country : '',
              cardId1: reservation?.userId?.cards[0] ? reservation?.userId?.cards[0].cardExternalId : '',
              devise1: reservation?.userId?.cards[0] ? reservation?.userId?.cards[0].currency : '',
              cardId2: reservation?.userId?.cards[1] ? reservation?.userId?.cards[1].cardExternalId : '',
              devise2: reservation?.userId?.cards[1] ? reservation?.userId?.cards[1].currency : '',
              cardId3: reservation?.userId?.cards[2] ? reservation?.userId?.cards[1].cardExternalId : '',
              devise3: reservation?.userId?.cards[2] ? reservation?.userId?.cards[1].currency : '',
              walletId1: reservation?.userId?.wallets[0] ? reservation?.userId?.wallets[0].walletExternalId : '',
              deviseWallet1: reservation?.userId?.wallets[0] ? reservation?.userId?.wallets[0].currency : '',
              wallet2: reservation?.userId?.wallets[1] ? reservation?.userId?.wallets[1].walletExternalId : '',
              deviseWallet2: reservation?.userId?.wallets[1] ? reservation?.userId?.wallets[1].currency : '',
              walletId3: reservation?.userId?.wallets[2] ? reservation?.userId?.wallets[2].walletExternalId : '',
              deviseWallet3: reservation?.userId?.wallets[2] ? reservation?.userId?.wallets[2].currency : '',
            });
          });
          //   dispatch(getCouponSuccess(res.data));
          setTableData(reservations);
          setCsvData(csvDataArray);
        }
      } catch (error) {
        // // console.log(error.response.data.message);
      }
    };
    if (!donnees) {
      getReservation();
    } else {
      setTableData(donnees);
    }

    return () => {
      controller.abort();
    };
  }, []);

  const open = () => {
    // /capsule/open
  }

  const ouvrirCapsule = (id) => {
    // setShowUnlock({ value: true, phase: 1 });
    const open = async () => {
      // setLoader({ value: true, showedPlace: id });
      const res = await axiosPrivate.post("admin/dash/capsule/open", {
        reservationId: id,
      });

      try {
        if (res) {
          if (res.data.success) {
            // displayToast(t("reservationStep1.operation"), "success");
            // setShowUnlock({ value: true, phase: 2 });
            const DataReservations = res.data.reservations;
            // filterData(DataReservations);
            // setTimeout(() => {
            //   setShowUnlock({ value: false, phase: 1 });
            // }, 5000);
          } else {
            if (res.data.message) {
              // displayToast(res.data.message, "error");
              // setShowUnlock({ value: false, phase: 1 });
            }
          }
          // setLoader({ value: false, showedPlace: null });
        }
      } catch (error) {
        //catch error request
        // setShowUnlock({ value: false, phase: 1 });
        // displayToast("Error", "error");
        // setLoader({ value: false, showedPlace: null });
      }
    };
    //run
    open().catch((error) => {
      // catch axios error
      setTimeout(() => {
        // setShowUnlock({ value: false, phase: 1 });
        // setLoader({ value: false, showedPlace: null });
        // if (error) {
        //   displayToast(t(error.response.data.message), "error");
        // } else {
        // }
      }, 600);
    });
  };

  // downloadInvoice

  const downloadInvoice = (id) => {
    const getInvoice = async (id) => {
      try {
        const res = await axiosPrivate.get(`/admin/dash/invoice/?id=` + id, {
          responseType: "blob",
        });
        if (res) {
          const file = new Blob([res.data]);
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(file);
          link.download = "invoice.pdf";
          link.click();
        }
      } catch (error) {}
    };

    getInvoice(id).catch((error) => {
      
    });
  };

  // csv headers
  const csvHeaders = [
    {label: "Client", key: "client"},
    {label: "Lieu", key: "lieu"},
    {label: "Date", key: "date"},
    {label: "Heure de début", key: "startTime"},
    {label: "Heure de fin", key: "endTime"},
    {label: "N° résa", key: "reservationId"},
    {label: "Status", key: "status"},
    {label: "Actions", key: "statusx"},
    {label: "Email", key: "email"},
    {label: "NewsLetter", key: "newsletter"},
    {label: "Reception facture", key: "facture"},
    {label: "Langue", key: "langue"},
    {label: "Status du compte", key: "compte"},
    {label: "Date creation du compte", key: "createdAt"},
    {label: "Adresse de facturation 1", key: "address1"},
    {label: "Ville de facturation 1", key: "ville1"},
    {label: "Pays de facturation 1", key: "pays1"},
    {label: "Adresse de facturation 2", key: "address2"},
    {label: "Ville de facturation 2", key: "ville2"},
    {label: "Pays de facturation 2", key: "pays2"},
    {label: "Adresse de facturation 3", key: "address3"},
    {label: "Ville de facturation 3", key: "ville3"},
    {label: "Pays de facturation 3", key: "pays3"},
    {label: "Carte bancaire 1", key: "cardId1"},
    {label: "Devise CB 1", key: "devise1"},
    {label: "Carte bancaire 2", key: "cardId2"},
    {label: "Devise CB 2", key: "devise2"},
    {label: "Carte bancaire 3", key: "cardId3"},
    {label: "Devise CB 3", key: "devise3"},
    {label: "Porte monnaie 1", key: "walletId1"},
    {label: "Devise wallet 1 ", key: "deviseWallet1"},
    {label: "Porte monnaie 2", key: "walletId2"},
    {label: "Devise wallet 2 ", key: "deviseWallet2"},
    {label: "Porte monnaie 3", key: "walletId3"},
    {label: "Devise wallet 3 ", key: "deviseWallet3"},
  ]

  // // downloadResa in csv
  // const downloadResaCsv = {
  //   data: reservations,
  //   headers: csvHeaders,
  //   filename: 'reservations.csv'
  // }


  //should be memoized or stable
  const columns = useMemo(
    () => [
      {
        accessorKey: "client", //access nested data with dot notation
        header: "Client",
      },
      {
        accessorKey: "lieu",
        header: "Lieu",
      },
      {
        accessorKey: "date",
        header: "Date",
      },
      {
        accessorKey: "startTime",
        header: "Heure de début",
      },
      {
        accessorKey: "endTime",
        header: "Heure de fin",
      },
      {
        accessorKey: "reservationId",
        header: "N° résa",
      },
      {
        accessorKey: "status",
        header: "Status",
        Cell: ({ cell, column }, value) => (
          <Stack direction="row" spacing={1}>
            <Chip
              label={cell.getValue()}
              sx={{
                backgroundColor:
                  cell.getValue() === "À VENIR"
                    ? "rgba(8, 168, 193, 1)"
                    : cell.getValue() === "EN COURS"
                    ? "rgba(55, 184, 60, 1)"
                    : cell.getValue() === "PASSÉE"
                    ? "rgba(193, 106, 8, 1)"
                    : "rgba(193, 8, 8, 1)",
                color: "rgba(255, 255, 255, 1)",
                fontWeight: "bold",
                fontSize: "12px",
                width: "103px",
                height: "20px",
                textAlign: "center",
                borderRadius: "6px",
                opacity: 1,
              }}
            />
          </Stack>
        ),
      },
      {
        accessorKey: "facture",
        header: "Facture",
        // muiTableHeadCellProps: {
        //   align: 'center',
        // },
        // muiTableBodyCellProps: {
        //   align: 'center',
        // },
        Cell: ({ cell, column }, value) =>
          cell.getValue() === "N/A" ? (
            <p>N/A</p>
          ) : (
            <p
              className="text-[#2F3B59] font-bold hover:underline hover:cursor-pointer"
              onClick={() => downloadInvoice(cell.getValue())}
            >
              Facture
            </p>
          ),
      },
      {
        accessorKey: 'statusx',
        header: 'Actions',
        Cell: ({cell, column, }, value) => {
           // PASSÉE  ANNULÉE
           const selectedStatus = cell.getValue()
           const isCanOpen = selectedStatus !== 'PASSÉE' && selectedStatus !== 'ANNULÉE';
          return (
            <div>
              <Button onClick={() => {
                console.log('Column : ', cell.row.original)
                ouvrirCapsule(cell.row.original.resaId)
              }}
              sx={{
              // width: 360,
              backgroundColor: "lightgreen",
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",
              fontSize: 15,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "darkgreen",
                color: "white",
              },
              color: "rgba(47, 59, 89, 1)",
              marginBottom: 4,
              marginTop: 2,
            }}
            variant="contained">
                {t("mesReservations.ouvrireCapsule").toUpperCase()}
              </Button>
            </div>
          )
        }
      },

    ],
    []
  );

  const btnStyle = (textOrClass, startDate, endDate) => {
    return textOrClass === "text" ? t("mesReservations.ouvrireCapsule").toUpperCase() : "bg-lightGreen";
  };


  return (
    <div className="mt-6 box-border border-2 shadow-lg ml-[3%] max-w-95">
      <h2 className="text-darkBlue text-2xl font-semibold m-4">Reservations</h2>
      <MaterialReactTable
        columns={columns}
        data={tableData}
        initialState={{ density: "compact" }}
        enableColumnActions={false}
        enableFilters={true}
        enableHiding={false}
        enableDensityToggle={false}
        enableFullScreenToggle={false}
        muiSearchTextFieldProps={{
          placeholder: "Recherche",
          hover: "Afficher/Masquer",
          label: "Recherche",
          InputLabelProps: { shrink: true },
        }}
        muiTablePaginationProps={{
          labelRowsPerPage: "Réservations par page",
          labelDisplayedRows: ({ from, to, count }) => {
            return `${from}-${to} sur ${count}`;
          },
        }}
        renderDetailPanel={({ row }) => (
          // // console.log(row?.original?.user?.disabled) &&
          // // console.log(row?.original?.user?.billingAddress) &&
          <Box
            sx={{
              display: "grid",
              margin: "auto",
              // gridTemplateColumns: "1fr 1fr",
              width: "100%",
            }}
            className="lg:justify-center"
          >
            <div className="flex flex-col">
              <Typography
                variant="h6"
                sx={{ color: "#2F3B59", fontWeight: "bold" }}
              >
                A. Informations personnelles
              </Typography>
              {/* <p>{row?.original}</p> */}
              <div className="flex flex-col lg:flex-row lg:gap-4 mt-2  justify-between">
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4">Nom: </span>
                  {row?.original?.user?.name?.last}
                </Typography>
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                    Prenom:{" "}
                  </span>
                  {row?.original?.user?.name?.first}
                </Typography>
              </div>
              <div className="flex flex-col lg:flex-row lg:gap-4 lg:mt-2  justify-between">
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4">Email: </span>
                  {row?.original?.user?.email}
                </Typography>
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                    Phone:{" "}
                  </span>
                  {row?.original?.user?.phoneNumber}
                </Typography>
              </div>
              <div className="flex flex-col lg:flex-row lg:gap-4 lg:mt-2  justify-between">
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4">
                    Status:{" "}
                  </span>
                  {row?.original?.user?.disabled ? 
                    "Inactif"
                    : (row?.original?.user?.disabled === false ? "Actif" : "Supprimé")}
                </Typography>
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                    Langue:{" "}
                  </span>
                  {row?.original?.user?.language}
                </Typography>
              </div>
              <div className="flex flex-col lg:flex-row lg:gap-4 lg:mt-2  justify-between">
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4">
                    Newsletter:{" "}
                  </span>
                  {row?.original?.user?.newsLetterSub ? "Active" : "Inactive"}
                </Typography>
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                    Reception facture:{" "}
                  </span>
                  {row?.original?.user?.invoiceSub ? "Active" : "Inactive"}
                </Typography>
              </div>
            </div>
            <br />
            <div className="flex flex-col mt-2">
              <Typography
                variant="h6"
                sx={{ color: "#2F3B59", fontWeight: "bold" }}
              >
                B. Adresses
              </Typography>
              <div className="flex flex-col  lg:gap-4 mt-2  justify-between">
                {row?.original?.user?.billingAddress?.map((addr, index) => {
                  return (
                    <div
                      className="lg:flex lg:flex-row lg:gap-4 lg:mt-2  lg:justify-between"
                      key={addr?._id}
                    >
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-4">
                          Type:{" "}
                        </span>
                        {addr?.type === "INDIV" ? "Perso" : "Pro"}
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                          Adresse:{" "}
                        </span>
                        {addr?.address}
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-4">
                          Code Postal:{" "}
                        </span>
                        {addr?.zipCode}
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-4 lg:ml-0">
                          Ville:{" "}
                        </span>
                        {addr?.city}
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-4">
                          Pays:{" "}
                        </span>
                        {addr?.country}
                      </Typography>
                    </div>
                  );
                })}
              </div>
            </div>
            <br />
            <div className="flex flex-col mt-2">
              <Typography
                variant="h6"
                sx={{ color: "#2F3B59", fontWeight: "bold" }}
              >
                C. Moyens de paiement
              </Typography>
              <div className="flex flex-col mt-2">
                <Typography>
                  {!row?.original?.Ext_CustomerId && <span className="text-[#2F3B59] font-bold ml-4">
                    1- Wallets:
                  </span>}
                  {row?.original?.Ext_CustomerId && <span className="text-[#2F3B59] font-bold ml-4">
                    1- {row?.original?.Ext_CustomerId?.substring(row.original.Ext_CustomerId?.indexOf('_') + 1, row.original.Ext_CustomerId?.length)}
                  </span>}
                </Typography>
                {row?.original?.user?.wallets?.map((wallet, index) => {
                  // // console.log(index);
                  return (
                    <div
                      className="flex flex-col lg:flex-row lg:gap-4 mt-2  justify-between"
                      key={wallet?._id}
                    >
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-6 underline">
                          wallet-{index + 1}:
                        </span>
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-8 lg:ml-8">
                          WalletExternalID:{" "}
                        </span>
                        {wallet?.walletExternalId}
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-8 lg:ml-0">
                          Devise:{" "}
                        </span>
                        {wallet?.currency}
                      </Typography>
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col mt-2">
                {/* <Typography>
                  <span className="text-[#2F3B59] font-bold ml-4">
                    2- Code promo:
                  </span>
                </Typography>
                <Typography>
                  <span className="text-[#2F3B59] font-bold ml-6 underline">
                    {row?.original?.codePromo}
                  </span>
                </Typography> */}
                {/* {row?.original?.user?.cards?.map((card, index) => {
                  return (
                    <div
                      className="flex flex-col lg:flex-row lg:gap-4 mt-2  justify-between"
                      key={card?._id}
                    >
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-6 underline">
                          carte-{index + 1}:
                        </span>
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-8 lg:ml-8">
                          Alias:{" "}
                        </span>
                        {card?.alias}
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-8 lg:ml-0">
                          CardExternalID:{" "}
                        </span>
                        {card?.cardExternalId}
                      </Typography>
                      <Typography>
                        <span className="text-[#2F3B59] font-bold ml-8 lg:ml-0">
                          Devise:{" "}
                        </span>
                        {card?.currency}
                      </Typography>
                    </div>
                  );
                })} */}
              </div>
            </div>
          </Box>
        )}
        positionExpandColumn="first"
        muiTableHeadCellProps={{
          sx: {
            fontSize: 15,
            fontWeight: "bold",
            color: "rgba(47, 59, 89, 1)",
          },
        }}
        renderTopToolbarCustomActions={() => (
          <Button
            className=""
            sx={{
              // width: 360,
              backgroundColor: "lightgreen",
              textAlign: "center",
              textTransform: "uppercase",
              cursor: "pointer",
              fontSize: 15,
              fontWeight: "bold",
              "&:hover": {
                backgroundColor: "darkgreen",
                color: "white",
              },
              color: "rgba(47, 59, 89, 1)",
              marginBottom: 4,
              marginTop: 2,
            }}
            variant="contained"
          >
            <CSVLink data={csvData} headers={csvHeaders} filename={`reservations_${DateTime.now()}.csv`}>Télécharger</CSVLink>
          </Button>
        )}
      />
    </div>
  );
};

export default AdminReservation;
