import { priceReservationPer15min } from "./constans";

// Faq data FR: 

//part faq General
export const faqGeneral = ()=>{

    return(
      {
        title: "GENERALITES",
        rows: [
            {
                title: "Qu’est-ce qu’une eCAPSULE ?",
                content: `Une eCASPULE est un mini espace de coworking, en libre service, réservable permettant de s’isoler du bruit ambiant.`,
            },
            {
                title: "Où sont localisées les eCAPSULES ?",
                content:
                `Elles sont accessibles dans les espaces accueillant du public comme les aéroports, lobbies d’hôtels, espace de coworking.Pour le moment la eCAPSULE est localisée à l’aéroport de Lyon terminal 1`,
            },
            {
                title: "Quels sont les services proposés ?",
                content: `La eCAPSULE est une bulle de travail confortable, fermée et équipée (accès wifi, prises, ports USB et USB-C) qui vous permet de travailler en toute tranquillité.​​​ 
                Le système de ventilation ainsi que les films antibactérien et antimicrobien sur toutes les surfaces assurent une ambiance intérieure saine.`,
            },
            {
                title: "Comment la eCAPSULE est-elle nettoyée ?",
                content:`La eCAPSULE est nettoyée quotidiennement par nos prestataires en dehors des horaires d’ouverture. En cas d’accident durant la journée, faites-le nous savoir et nous enverrons une équipe.
                De plus, grâce aux films antimicrobien et antibactérien posés sur toutes les surfaces, les risques sont réduits.
                Aussi, les utilisateurs de la eCAPSUlE forment une communauté qui s’appuie sur ses membres pour nettoyer après leur passage.`,
            },
        ],
      }
    )
  }
  
  // part faq utlisation
  
  
  export const faqUtilisation = ()=>{
  
    return(
      {
        title: "UTILISATION",
        rows: [
            {
                title: "Comment réserver une eCAPSULE ?",
                content: `<p>Les réservations peuvent s’effectuer de deux façons :</p>
                <ul>
                <li>Scannez directement le QR Code situé sur la porte de la eCAPSULE, qui vous renverra sur le site de réservation.
                </li>
                <li>
                Effectuer votre réservation en avance en allant sur le site <a href="/">www.ecapsule.fr</a>
                </li>
                </ul>
                `,
            },
            {
                title: "Comment utiliser le wifi ?",
                content:
                `Après avoir réservé votre créneau d’utilisation de la eCAPSULE, vous allez recevoir un email de confirmation avec le lien de déverrouillage de la porte ainsi que le code wifi de la eCAPSULE.`,
            },
            {
                title: "Comment ouvrir la porte de la eCAPSULE ?",
                content: `Sur l’email et/ou le SMS de confirmation reçu à l’issu de votre réservation, vous disposez du lien de déverrouillage de la porte.  Vous pouvez aussi cliquer sur le bouton ouvrir la porte dans votre compte/ mes réservations/mes réservations en cours.
                Placez-vous devant celle-ci quand vous êtes prêt, cliquez sur le lien ou le bouton et attendez que le cadenas s’ouvre. Au clique de la porte, celle-ci est ouverte, vous pouvez entrer. `,
            },
            {
                title: "Mon lien de déverrouillage ne fonctionne pas ?",
                content:`<p>Contactez-nous à <span>contact@minutup.com </span> ou au <span>+335 82 95 09 69</span>  et nous pourrons vous ouvrir la porte à distance. </p>`,
            },
            {
              title: "Comment savoir si ma réservation est finie ?",
              content:`5min avant la fin de votre réservation, vous recevrez un SMS vous rappelant l’heure de fin pour que vous ayez le temps de ranger vos affaires et de vous préparer à sortir.
              A l’issu de votre réservation, la lumière de la eCAPSULE s’éteint, la vitre de la porte s’éclaircie. Vous pouvez sortir et fermer la porte derrière vous, celle-ci se verrouille automatiquement.`,
          },
          {
            title: "Et si j’ai besoin de plus de temps ?",
            content:`5min avant la fin de votre réservation, vous recevrez un SMS vous rappelant l’heure de fin, si le créneau d’après n’est pas réservé vous aurez la possibilité de prolonger votre réservation via la plateforme de réservation.`,
        },
        {
          title: "Si je dois sortir de la eCAPSULE durant ma réservation (ex : besoin de passer aux toilettes) ?",
          content:`Vous pouvez aller et venir dans la eCAPSULE durant toute votre réservation, la porte se verrouille automatiquement si vous sortez. Il suffit de l’ouvrir à nouveau à l’aide du lien contenu dans le SMS ou l’email de confirmation de réservation ou du bouton « ouvrir » dans le menu « mes réservations » « mes réservations en cours ».`,
      },
      {
        title:"Comment utiliser mon code promotionnel ?",
        content:`Le code promotionnel est à utiliser dans la phase 2 de la réservation et à remplir dans la case « code promotionnel », il est à renseigner avant le paiement.`
      },
      
        {
          title:"Quel est le coût d’une réservation ?",
          content:`
          <p>C’est vous qui fixez le temps d’utilisation de la eCAPSULE et donc le coût. Elle se réserve par quart d’heure.
          Le tarif est le suivant : </p>
          <ul>
          <li>15min : ${priceReservationPer15min}€ TTC</li>
          <li>30min : ${priceReservationPer15min*2}€ TTC</li>
          <li>1h : ${priceReservationPer15min*4}€ TTC</li>
          </ul>
          `
        }
  
        ],
      }
    )
  }
  
  
  // part faq MES RESERVATIONS
  
  export const faqReservations = ()=>{
    return(
      {
        title: "MES RESERVATIONS",
        rows: [
            {
                title: "Comment obtenir ma facture d’achat ?",
                content: `A l’issue de l’utilisation de la eCAPSULE, vous recevrez un email avec la facture d’achat avec les coordonnées renseignées lors de l’inscription.
  Vous pouvez retrouver aussi votre facture, une fois connecté à votre compte, dans le menu « mes réservations ».
  A tout moment vous pouvez renseigner une adresse pour la facturation depuis votre compte dans le menu « adresse de facturation ».
                `,
            },
            {
                title: "Comment accéder à mes réservations ?",
                content:
                `Depuis votre compte dans le menu « mes réservations » toutes vos réservations sont accessibles. Qu’elles soient à venir, en cours ou passées.`,
            },
            {
                title: "Quels sont les services proposés ?",
                content: `La eCAPSULE est une bulle de travail confortable, fermée et équipée (accès wifi, prises, ports USB et USB-C) qui vous permet de travailler en toute tranquillité.​​​ 
                Le système de ventilation ainsi que les films antibactérien et antimicrobien sur toutes les surfaces assurent une ambiance intérieure saine.`,
            },
            {
                title: "Comment annuler une réservation ?",
                content:`
                <p>Vous pouvez annuler à tout moment une réservation. Pour cela, depuis votre compte, aller dans le menu mes reservations/réservations à venir et cliquez sur le bouton annulé.
                Les annulations sont soumises à la tarification suivante :</p>
                <ul>
                <li>Supérieur à 12h00 avant la réservation (remboursement total).</li>
                <li>Entre 12h00 avant et 1h00 avant. (50% de remboursement).</li>
                <li>Avant 1h00 (pas de remboursement).</li>
                </ul>
                `,
            },
            {
              title: "Si j’ai encore une question ou un problème durant ma réservation ?",
              content:`
              <p>
              Vous pouvez nous contacter via <span>contact@minutup.com</span> ou au <span>+335 82 95 09 69</span>
              </p>
              `,
          },
        ],
      }
    )
  }
  
  
  // Faq data EN: 
  
  //part faq General
  export const faqGeneralEN = ()=>{
  
    return(
      {
        title: "GENERAL",
        rows: [
            {
                title: "What is an eCAPSULE ?",
                content: `An eCASPULE is a self-service, bookable mini coworking space that allows you to isolate yourself from the surrounding noise.`,
            },
            {
                title: "Where are the eCAPSULES located ?",
                content:
                `They are in public areas such as airports, hotel lobbies, coworking spaces, etc.
                For the time being, the eCAPSULE is located at Lyon Airport Terminal 1.`,
            },
            {
                title: "What services are offered ?",
                content: `The eCAPSULE is a comfortable enclosed work bubble with Wi-Fi, power sockets, and USB and USB-C ports, allowing you to work in peace. 
                The ventilation system and the antibacterial and antimicrobial films on all surfaces ensure a healthy indoor environment.`,
            },
            {
                title: "How is the eCAPSULE cleaned ?",
                content:`The eCAPSULE is cleaned daily by our service providers outside opening hours. If there is an accident during the day, please let us know and we will send a team.
                In addition, because of the antimicrobial and antibacterial films applied to all surfaces, the risk is reduced.
                eCAPSULE users also form a community that relies on members to clean up after themselves.`,
            },
        ],
      }
    )
  }
  
  // part faq utlisation
  
  
  export const faqUtilisationEN = ()=>{
  
    return(
      {
        title: "USE",
        rows: [
            {
                title: "How do you book an eCAPSULE ?",
                content: `<p>Bookings can be made in two ways :</p>
                <ul>
                <li>Scan the QR code on the door of the eCAPSULE, which will take you to the booking site.
                </li>
                <li>
                Make your booking in advance by going to <a href="/">www.ecapsule.fr</a>
                </li>
                </ul>
                `,
            },
            {
                title: "How do you use the Wi-Fi ?",
                content:
                `After booking your eCAPSULE slot, you will receive a confirmation email with the door unlock link and the eCAPSULE Wi-Fi code.`,
            },
            {
                title: "How do you open the eCAPSULE door ?",
                content: `On the confirmation email and/or text message received after your booking, you will find the link to unlock the door.  You can also click on the Open door button in your account - "My bookings" - "My current bookings". Stand in front of the door when you are ready, click on the link or button, and wait for the lock to open. When the door clicks open, you can enter.`,
            },
            {
                title: "My unlocking link doesn't work ?",
                content:`<p>Contact us at <span>contact@minutup.com </span> or <span>+335 82 95 09 69</span> and we can open the door for you remotely.</p>`,
            },
            {
              title: "How do I know if my booking is over ?",
              content:`5 minutes before the end of your booking, you will receive a text message reminding you of the end time so you have a chance to pack up and get ready to leave.
              When your booking is over, the light in the eCAPSULE goes out and the door window clears. You can leave and close the door behind you (it locks automatically).`,
          },
          {
            title: "What if I need more time ?",
            content:`5 minutes before the end of your booking, you will receive a text message reminding you of the end time. If the next slot is not booked, you will have the chance to extend your booking via the booking platform.`,
        },
        {
          title: "What happens if I need to leave the eCAPSULE during my booking (e.g., I need the toilet)?",
          content:`You can come and go to/from the eCAPSULE throughout your booking. The door locks automatically if you leave. Simply open it again using the link in the text message or email confirmation of the booking or the "open" button in the "My bookings" - "My current bookings" menu.`,
      },
      {
        title:"How do I use my promotional code ?",
        content:`The promotional code is to be used at stage 2 of the booking process and should be entered in the "promotional code" box before payment.`
      },
      
        {
          title:"How much does a booking cost ?",
          content:`
          <p>You decide how long you want to use the eCAPSULE for, and therefore the cost. It is booked by the quarter-hour.
          Pricing is as follows : </p>
          <ul>
          <li>15 mins : ${priceReservationPer15min}€ incl. VAT</li>
          <li>30 mins : ${priceReservationPer15min*2}€ incl. VAT</li>
          <li>1 hour : ${priceReservationPer15min*4}€ incl. VAT</li>
          </ul>
          `
        }
  
        ],
      }
    )
  }
  
  
  // part faq MES RESERVATIONS
  
  export const faqReservationsEN = ()=>{
    return(
      {
        title: "MY BOOKINGS",
        rows: [
            {
                title: "How do I get my purchase invoice ?",
                content: `Once you have used the eCAPSULE, you will receive an email with the purchase invoice including the contact details you provided during registration.
                You can also find your invoice in the "My bookings" menu in your account.
                At any time, you can enter a billing address in your account in the "Billing address" menu.`,
            },
            {
                title: "How do I access my bookings ?",
                content:
                `In your account, go to the "My bookings" menu and you will see all your bookings: past, present and future.`,
            },
            
            {
                title: "How do I cancel a booking ?",
                content:`
                <p>You can cancel a booking at any time. To do this, in your account, go to the "My bookings - Upcoming bookings" menu and click on the cancel button.
                Cancellations are subject to the following fees:
                </p>
                <ul>
                <li>More than 12 hours before the booking. (full refund).</li>
                <li>Between 12 hours and 1 hour before the booking. (50% refund).</li>
                <li>Less than 1 hour before the booking (no refund).</li>
                </ul>
                `,
            },
            {
              title: "What if I have a question or problem during my booking ?",
              content:`
              <p>
              You can contact us at <span>contact@minutup.com</span> or <span>+335 82 95 09 69</span>
              </p>
              `,
          },
        ],
      }
    )
  }