/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { priceReservationPer15min } from "../../../data/constans";

///Hours

const hours = new Map([
  ["14h:00", ["14h00", "14h15", "14h30", "14h45", "15h00", "15h15"]],
  ["15h:30", ["15h30", "15h45", "16h00", "16h15", "16h30", "16h45"]],
  ["17h:00", ["17h00", "17h15", "17h30", "17h45", "18h00", "18h15"]],
  ["18h:30", ["18h30", "18h45", "19h00", "19h15", "19h30", "19h45"]],
  ["20h:00", ["20h00", "20h15", "20h30", "20h45", "21h00", "21h15"]],
  ["21h:30", ["21h30", "21h45", "22h00", "22h15", "22h30", "22h45"]],
  ["23h:00", ["23h00", "23h15", "23h30", "23h45", "00h00", "00h15"]],
  ["00h:30", ["00h30", "00h45", "01h00", "01h15", "01h30", "01h45"]],
  ["02h:00", ["02h00", "02h15", "02h30", "02h45", "03h00", "03h15"]],
  ["03h:30", ["03h30", "03h45", "04h00", "04h15", "04h30", "04h45"]],
  ["05h:00", ["05h00", "05h15", "05h30", "05h45", "06h00", "06h15"]],
  ["06h:30", ["06h30", "06h45", "07h00", "07h15", "07h30", "07h45"]],
  ["08h:00", ["08h00", "08h15", "08h30", "08h45", "09h00", "09h15"]],
  ["09h:30", ["09h30", "09h45", "10h00", "10h15", "10h30", "10h45"]],
  ["11h:00", ["11h00", "11h15", "11h30", "11h45", "12h00", "12h15"]],
  ["12h:30", ["12h30", "12h45", "13h00", "13h15", "13h30", "13h45"]],
]);




  function shiftHours(hours) {
  const hourMap = new Map();
  const hourKeys = [...hours.keys()];

const now = new Date();
const parisTime = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));

let currentHour = parisTime.getHours();
let currentMinute =   parisTime.getMinutes();

 // let currentHour = new Date().getHours();
  // let currentMinute =   new Date().getMinutes();

  let start = hourKeys.findIndex((hour) => {
    const [hourVal, minuteVal] = hour.split(":").map((val) => parseInt(val));
    const nextKey = hourKeys[(hourKeys.indexOf(hour) + 1) % hourKeys.length];
    const [nextHourVal] = nextKey.split(":").map((val) => parseInt(val));
    if (hourVal <= currentHour && currentHour < nextHourVal) {
      return true;
    } else if (hourVal === currentHour && minuteVal <= currentMinute) {
      return true;
    } else if (hourVal === 23 && currentHour === 0) {
      return true;
    } else {
      return false;
    }
  });

  // Check if the current time is greater than the last time slot in the previous hour key
  const [lastHourVal, lastMinuteVal] = hourKeys[(start - 1 + hourKeys.length) % hourKeys.length].split(":").map((val) => parseInt(val));
  if (currentHour === lastHourVal && currentMinute >= lastMinuteVal) {
    start++;
  }

  for (let i = 0; i < hours.size; i++) {
    const index = start % hourKeys.length;
    const key = hourKeys[index];
    if (key) {
      hourMap.set(key, hours.get(key));
    }
    start++;
  }

  return hourMap;
  }
  

const sortedHours = shiftHours(hours);





export function calculateTimeDifference(startTime, endTime, format) {
  if (startTime !== null && endTime !== null) {
    // Expected output example : 08h00 => ["08","00"] "hh":"mm"
    const start = startTime.split("h");
    const end = endTime.split("h");

    let startMinutes = parseInt(start[0]) * 60 + parseInt(start[1]);
    let endMinutes = parseInt(end[0]) * 60 + parseInt(end[1]);

    let difference = endMinutes - startMinutes;
    let hours = Math.floor(difference / 60);

    let minutes = difference % 60;

    if (format === "duration") {
      return (
        (hours > 0 ? hours + "h" : "") + (minutes > 0 ? minutes + "m" : "")
      );
    } else if (format === "price")
      return ((difference / 15) * priceReservationPer15min).toFixed(2);
  }
}

// Retrive not available hours per day:

export const getNotAvailableHours = (date) => {
  let intervals = [];
  for (const [value] of Object.entries(date)) {
    let [startHour, startMinute] = value.start.split(":").map(Number);
    let [endHour, endMinute] = value.end.split(":").map(Number);

    while (
      startHour < endHour ||
      (startHour === endHour && startMinute <= endMinute)
    ) {
      intervals.push(
        `${startHour}h${startMinute < 10 ? "0" + startMinute : startMinute}`
      );
      startMinute += 15;
      if (startMinute >= 60) {
        startMinute = 0;
        startHour += 1;
      }
    }
  }
  const keyValue = intervals.reduce((obj, hour) => {
    obj[hour] = hour;
    return obj;
  }, {});

  return keyValue;
};

export const START_AND_END_HOURS_KEY = 'START_AND_END_HOURS_KEY';

export default sortedHours;