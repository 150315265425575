import { createSlice } from "@reduxjs/toolkit";
import { getNotAvailableHours, getTodayDate } from "../utils/utils";

const initialState = {
  availableReservations: null,
  hours: null,
  error: false,
  reservationDetails: null,
  registredReservations:[]
};

export const reservationSlice = createSlice({
  name: "reservations",
  initialState,
  reducers: {
    getReservations: (state, action) => {
      state.availableReservations = action.payload;

      if (action.payload[getTodayDate()]) {
        state.hours = getNotAvailableHours(action.payload[getTodayDate()]);
       
      }
    },

    ReservationsFailure: (state, action) => {
      state.error = action.payload;
    },
    hoursNotAvailablePerDay: (state, action) => {
    

      state.hours = getNotAvailableHours(action.payload);
    },

    setReservationDetails: (state, action) => {
      state.reservationDetails = action.payload;
    },
    setRegistredReservations: (state,action)=>{
      state.registredReservations= action.payload;
    }
  },
});

export const {
  getReservations,
  ReservationsFailure,
  hoursNotAvailablePerDay,
  setReservationDetails,
  setRegistredReservations
} = reservationSlice.actions;

export default reservationSlice.reducer;
