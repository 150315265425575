/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { ArrowRightIcon, CheckIcon } from "../../../data/Icons";
import { useSelector, useDispatch } from "react-redux";
import Button from "../../ui/Button";
// import AddMethod from "./AddMethod";
import { setReservationDetails } from "../../../redux/reservationSlice";
import { setAddresses } from "../../../redux/billingAddressSlice";
import { useState, useRef, useEffect } from "react";
import AddFacture from "./AddFacture";
import Toast from "../../ui/Toast";
import Loader from "../../ui/Loader";
import Modal from "../../ui/Modal";
import { setRegistredReservations } from "../../../redux/reservationSlice";
import {
  finishedPayment,
  getPaymentSuccess,
} from "../../../redux/paymentSlice";
import useAxiosPrivate from "../../../hooks/useAxiosPrivate";
import { frenchFormat } from "../../../utils/utils";
import Cgu from "../../pages/Cgu";
import { useTranslation } from "react-i18next";
import { loadStripe } from "@stripe/stripe-js";
import {
  EmbeddedCheckoutProvider,
  EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { STRIPE_PH } from "../../../api/apiCalls";

const stripePromise = loadStripe(STRIPE_PH);

const PaiementBox = ({
  open,
  handleClose,
  handleOpen,
  showConfirmationBox,
  onChangeToStepThree = () => {},
}) => {
  const { t } = useTranslation();
  const [clientSecret, setClientSecret] = useState("");
  const [newsletterChecked, setNewsletterChecked] = useState(false);
  const [openModal, setIsModalOpen] = useState({ value: false, type: null });
  const [cguChecked, setCguChecked] = useState(true);
  const [coupon, setCoupon] = useState("");
  const [confirmedCoupon, setConfirmedCoupon] = useState("");
  const [loader, setLoader] = useState(false);
  const axiosPrivate = useAxiosPrivate();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [isCanceled, setIsCanceled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  // const [clientSecret, setClientSecret] = useState('');
  /* const reservationDetails = useSelector(
    (state) => state.reservations.reservationDetails
  ); */
  const reservationInfo = JSON.parse(
    localStorage.getItem("reservationDetails")
  );
  const { addresses } = useSelector((state) => state.billingAddress);
  // const choosedCard = useSelector((state) => state.payment.choosedCard);
  // const alias = choosedCard.length !== 0 ? choosedCard[0]?.alias : "";
  // const label = choosedCard.length !== 0 ? choosedCard[0]?.label : "";
  const [displayPayOrFacture, setDisplay] = useState(false);
  const dispatch = useDispatch();
  const [disabledButton, setDisabledButton] = useState(false);
  //Toast props  // responseType => "success" or "error"
  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const time = 3000;
  // Modal cgu

  const [price, setPrice] = useState(reservationInfo?.price);

  const [couponApplied, setCouponApplied] = useState(false);

  const handleOpenModal = (modalType) => {
    setIsModalOpen({ value: true, type: modalType });
  };

  const handleCloseModal = () => {
    setIsModalOpen({ value: false, type: null });
  };

  // Toast
  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  //Div height
  const divHeight = useRef(null);

  const close = () => {
    handleClose();
    setDisplay(false);
  };

  // get user infos
  useEffect(() => {
    if (currentUser.cards) {
      dispatch(getPaymentSuccess(currentUser?.cards));
    }

    if (currentUser.billingAddress) {
      dispatch(setAddresses(currentUser.billingAddress));
    }
  }, []);

  const erroCgu = (type) => {
    if (!cguChecked) {
      displayToast(t("reservationStep2.accepterTerms"), "error");
    } else {
      setDisplay(type);
      handleOpen();
    }
  };

  const handleCancel = () => {
    if (isSubmitting) {
      setIsSubmitting(false)
    } else {
      setIsCanceled(true);
    }
  };

  const handleSubmit = () => {
    checkIfDatesAreAlwaysValid()
    // setIsSubmitting(true);
    // if (onChangeToStepThree) {
    //   onChangeToStepThree()
    // }
  };

  const checkIfDatesAreAlwaysValid = async () => {
      const res = await axiosPrivate.post("reservation/checkIfDatesAreAlwaysValid", {
        reservationId: reservationInfo.id,
        // endDate: reservationInfo.end,
      });
      if (res?.data?.success) {
        processPayment()
        // displayToast(t("reservationStep1.operation"), "success");
        // const reservationInfo = {
        //   start: startHour,
        //   end: endHour,
        //   price: calculateTimeDifference(startHour, endHour, "price"),
        //   duration: calculateTimeDifference(startHour, endHour, "duration"),
        //   date: formateDate(selectedDay),
        //   id: res?.data?.id,
        //   checkout_url: res?.data?.checkout_url,
        //   checkoutId: res?.data?.checkoutId,
        //   clientSecret: res?.data?.clientSecret,
        //   currency: res?.data?.currency,
        // };
        // setTimeout(() => {
          // localStorage.setItem(
          //   "reservationDetails",
          //   JSON.stringify(reservationInfo)
          // );
          // dispatch(setReservationDetails(reservationInfo));

        //   setLoader(false);
        // }, 500);
      } else if (!res?.data?.success) {
        displayToast(t("other.creneauNonDisponible"), "error");
        setLoader(false);
      }
  }

  const processPayment = async () => {
    try {
      const resPayment = await axiosPrivate.patch(
        'reservation/updateAmountAndProcessPayment',
        {
          reservationId: reservationInfo?.id,
          amount: price,
        }
      )
      if (resPayment?.data?.success && resPayment?.data?.clientSecret) {
        setClientSecret(resPayment?.data?.clientSecret)
        localStorage.setItem('checkoutId', resPayment?.data?.checkoutId)
        localStorage.setItem('reservationDetails', JSON.stringify({...reservationInfo, checkoutId: resPayment?.data?.checkoutId}))
        setLoader(false)
        setIsSubmitting(true)
      } else if (resPayment?.data?.errorCode === 'PAYMENT_ERROR') {
        displayToast('Paiement échoué, veuillez réessayer plus tard', 'error')
        setLoader(false)
      }
    } catch (error) {
      // console.log('error?.response?.data : ', error?.response?.data)
      if (error?.response?.data) {}
      displayToast('Paiement échoué, veuillez réessayer plus tard', 'error')
      setLoader(false)
    }
  }

  const handleCoupon = async () => {
    //setLoader(true)
    try {
      const res = await axiosPrivate.post("reservation/checkCodePromo", {
        libelle: coupon,
        pendingReservationId: reservationInfo.id,
      });

      if (res.data.success) {
        setConfirmedCoupon(res.data.libelle);
        setPrice(res.data.newAmount);
        setCouponApplied(true);
        //setLoader(false)
        displayToast(t("reservationStep2.promoCodeMessage"), "success");
        handleCloseModal();
      }
    } catch (error) {
      if (error.response.status === 401) {
        displayToast(t(error.message), "error");
      } else {
        displayToast(t(error.response.data.message), "error");
      }
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    if (!isCanceled) {
      return () => {
        controller.abort();
      };
    }
    // cancel reservation
    const annulerReservation = async () => {
      try {
        const res = await axiosPrivate.delete(
          `reservation/cancelPending/?id=${reservationInfo.id}`
        );

        if (res) {
          displayToast(t("reservationStep2.popUpText"), "success");
          setTimeout(() => {
            dispatch(setReservationDetails(null));
            localStorage.removeItem("reservationDetails");
          }, 1500);
        }
      } catch (error) {
        if (error.response.status !== 401) {
          displayToast(t("other.cantDoIt"), "error");
        } else displayToast(t(error.message), "error");
      }
    };
    annulerReservation();
  }, [isCanceled]);

  useEffect(() => {
    // console.log("reservationInfo : ", reservationInfo);
  }, [reservationInfo]);

  useEffect(() => {
    // const controller = new AbortController();
    // if (!isSubmitting) {
    //   return () => {
    //     controller.abort();
    //   };
    // }
    // // Final step reserver
    // const reserveFinal = async (data) => {
    //   try {
    //     const res = await axiosPrivate.post("user/pay", {
    //       isTermsOfUseAccepted: data.isTermsOfUseAccepted,
    //       pendingReservationId: data.pendingReservationId,
    //       cardId: data.cardId,
    //       currency: data.currency,
    //       newsLetterSub: data.isnewsletterChecked,
    //     });
    //     if (res.data.success) {
    //       if (res.data.redirectURL) {
    //         window.location.replace(`${res.data.redirectURL}`);
    //       } else {
            // let res2;
            // if (res.data.transactionId) {
            //   res2 = await axiosPrivate.post("reservation", {
            //     pendingReservationId: data.pendingReservationId,
            //     transactionId: res.data.transactionId,
            //     currency: data.currency
            //   });
            // } else {
            //   res2 = await axiosPrivate.post("reservation", {
            //     pendingReservationId: data.pendingReservationId,
            //   });
            // }
            // if (res2.data.success) {
            //   displayToast(
            //     t("reservationStep2.reservationEnregistree"),
            //     "success"
            //   );
            //   setLoader(false)
              // dispatch(setRegistredReservations([]));
            //   setTimeout(() => {
                // showConfirmationBox();
                // localStorage.setItem("finishedPayment", true);
                // dispatch(finishedPayment(true));
            //   }, 1500);
            // }
    //       }
    //     }
    //     else {
    //       displayToast(t(res.data.message), "error");
    //       setDisabledButton(false);
    //       setLoader(false);
    //       setIsSubmitting(false);
    //     }
    //   } catch (error) {
    //     if (error.response.status !== 401) {
    //       displayToast(
    //         t(error.response.data.message ) || "Échec de paiement...",
    //         "error"
    //       );
    //       setDisabledButton(false);
    //       setLoader(false);
    //       setIsSubmitting(false);
    //     } else {
    //       displayToast(t(error.message));
    //       setDisabledButton(false);
    //       setLoader(false);
    //       setIsSubmitting(false);
    //     }
    //   }
    // };
    // if (!cguChecked) {
    //   displayToast(
    //     t("compte.acceptConditions"),
    //     "error"
    //   );
    //   return;
    // }
    // const dataToApi = {
    //   isTermsOfUseAccepted: cguChecked,
    //   pendingReservationId: reservationInfo.id,
    //   cardId: choosedCard[0]?.cardExternalId,
    //   currency: choosedCard[0]?.currency,
    //   externalId: choosedCard[0]?._id,
    //   isnewsletterChecked: newsletterChecked,
    // };
    // setDisabledButton(true);
    // setLoader(true);
    // reserveFinal(dataToApi);
  }, [isSubmitting]);

  const handleNewsletterChange = (e) => {
    setNewsletterChecked(e.target.checked);
  };

  const handleCguChange = (e) => {
    setCguChecked(e.target.checked);
  };

  const onCompletePayment = () => {
    // console.log("Complete payment ");
    localStorage.setItem('checkoutId', reservationInfo.checkoutId)
  };

  const finalizePayment = async () => {
    let res2;
            // if (res.data.transactionId) {
              res2 = await axiosPrivate.post("reservation", {
                pendingReservationId: reservationInfo.id,
                transactionId: '',
                currency: reservationInfo.currency,
                checkoutId: reservationInfo.checkoutId,
              });
            // } else {
            //   res2 = await axiosPrivate.post("reservation", {
            //     pendingReservationId: data.pendingReservationId,
            //   });
            // }
            if (res2.data.success) {
              displayToast(
                t("reservationStep2.reservationEnregistree"),
                "success"
              );
              setLoader(false)
              dispatch(setRegistredReservations([]));
              setTimeout(() => {
                showConfirmationBox();
                localStorage.setItem("finishedPayment", true);
                dispatch(finishedPayment(true));
              }, 1500);
            }
  }

  if (reservationInfo) {
    return (
      <div className="relative">
        {/* Control if show ajouter un moyen de paiement or adresse etc.. */}
        {/* {open && displayPayOrFacture === "pay" && (
          <div className="relative">
            <AddMethod close={close} />{" "}
          </div>
        )} */}

        {open && displayPayOrFacture === "facture" && (
          <div>
            <AddFacture close={close} />
          </div>
        )}
        {!open && (
          <div className="paiementBox" ref={divHeight}>
            {/* Réservation infos */}
            <p className="text-darkBlue mb-2 text-sm sm:text-base flex flex-wrap font-medium">
              {t("reservationStep2.reservationLe")}&nbsp;
              <span className="font-semibold text-darkBlue">
                {frenchFormat(reservationInfo?.start?.substring(6)).replace(
                  /-/g,
                  "/"
                )}
              </span>
              &nbsp;{t("reservationStep2.de")}&nbsp;
              <span className="font-semibold text-darkBlue">
                {reservationInfo?.start?.substring(0, 5)}
              </span>
              &nbsp;{t("reservationStep1.to")}&nbsp;
              <span className="font-semibold text-darkBlue">
                {reservationInfo?.end?.substring(0, 5)}
              </span>
              {reservationInfo?.start?.substring(6) !==
                reservationInfo?.end?.substring(6) && (
                <span className="font-semibold text-darkBlue">
                  &nbsp;(
                  {frenchFormat(reservationInfo?.end?.substring(6)).replace(
                    /-/g,
                    "/"
                  )}
                  )
                </span>
              )}
            </p>
            {/* Durée et prix */}
            <div className="flex justify-between items-start flex-wrap">
              <h3 className="text-darkBlue font-semibold text-xs sm:text-base mb-2">
                {t("reservationStep1.duree")}
              </h3>
              <span className="text-darkBlue text-xs sm:text-base font-bold">
                {reservationInfo?.duration}
              </span>
            </div>

            <div className="flex justify-between items-start flex-wrap">
              <h3 className="text-darkBlue font-semibold text-xs sm:text-base">
                {t("reservationStep1.prixParagraph")}
              </h3>
              <span className="text-darkBlue text-xs sm:text-base font-bold">
                {price + "€"}
              </span>
            </div>

            <div id="checkout">
              {isSubmitting &&
                clientSecret &&
                clientSecret !== "" && (
                  <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{
                      clientSecret: clientSecret,
                      onComplete: onCompletePayment,
                    }}
                  >
                    <EmbeddedCheckout />
                  </EmbeddedCheckoutProvider>
                )}
            </div>

            {/* Nom prénom, MOyen de paiement, adresse de facturation, code promo */}
            {!isSubmitting && <div className="border-y border-solid border-darkShadeBlue -mx-2 lg:-mx-8 px-4 py-4 mb-5 mt-6">
              {/* Moyen de paiement */}
              {/* <div
                className="flex justify-between items-center mb-4 cursor-pointer    border-style3"
                onClick={() => {
                  erroCgu("pay");
                }}
              >
                <div>
                  <h3 className="font-medium	 text-darkBlue">
                    {t("reservationStep2.moyenPaiement")}
                  </h3>
                  {alias && label ? (
                    <div className="flex items-center flex-wrap flex-row  italic">
                      <h4 className="font-small mr-2	text-xs sm:text-base text-lightGreen ">
                        {label}
                      </h4>
                      <p className="text-xs sm:text-base">{alias}</p>
                    </div>
                  ) : (
                    <button
                      className="mt-1"
                      onClick={() => {
                        erroCgu("pay");
                      }}
                    >
                      + {t("reservationStep2.ajouter")}
                    </button>
                  )}
                </div>
                <div
                  onClick={() => {
                    erroCgu("pay");
                  }}
                >
                  <ArrowRightIcon />
                </div>
              </div> */}
              {/* Adresse facturation */}
              {!isSubmitting && <div
                className="flex cursor-pointer justify-between items-center mb-4 text-darkBlue font-medium border-style3"
                onClick={() => {
                  erroCgu("facture");
                }}
              >
                <div>
                  <h3>{t("reservationStep2.adresseFacturation")}</h3>
                  {addresses?.length > 0 ? (
                    <h3 className="text-sm flex items-center flex-wrap">
                      <span className="mr-1">
                        {" "}
                        <h3>{t("reservationStep2.adresseEnregistree")}</h3>
                      </span>
                      <span className="text-lightGreen">
                        {addresses[addresses.length - 1].label}
                      </span>{" "}
                      <CheckIcon />
                    </h3>
                  ) : (
                    <button
                      className="mt-1"
                      onClick={() => {
                        erroCgu("facture");
                      }}
                    >
                      + {t("reservationStep2.ajouter")}
                    </button>
                  )}
                </div>
                <div
                  onClick={() => {
                    erroCgu("facture");
                  }}
                >
                  <ArrowRightIcon />
                </div>
              </div>}
              {/* Code promo */}
              {!isSubmitting && <input
                placeholder={t("reservationStep2.codePromo")}
                className="text-darkBlue block w-full placeholder-darkBlue font-medium border-style3 focus:outline-none focus:border-darkGreen "
                value={confirmedCoupon}
                readOnly
                onClick={() => {
                  handleOpenModal("coupon");
                }}
              />}
            </div>}
            {/* Checkboxs */}

            {!currentUser.newsLetterSub && !isSubmitting && (
              <div className="items-center ">
                <input
                  className="accent-lightGreen h-3.5 max-w-3.5 w-full mr-2"
                  name="newsletter"
                  type="checkbox"
                  checked={newsletterChecked}
                  onChange={handleNewsletterChange}
                />
                <label className="text-darkBlue text-xs	font-semibold	">
                  {t("reservationStep2.accepteCommunication")}
                </label>
              </div>
            )}
            {!isSubmitting && <div className="items-center mt-3">
              <input
                name="cgu"
                className="accent-lightGreen mr-2 h-3.5 max-w-3.5 w-full"
                type="checkbox"
                checked={cguChecked}
                onChange={handleCguChange}
              />
              <label className="text-darkBlue  text-xs font-semibold">
                {t("reservationStep2.accepteConditions")}
                <span
                  className="text-lightGreen font-semibold cursor-pointer"
                  onClick={() => {
                    handleOpenModal("cgu");
                  }}
                >
                  {" "}
                  {t("reservationStep2.conditions")}
                </span>
              </label>
            </div>}

            <div className="flex flex-col  items-center justify-center my-4 flex-wrap">
              {!isSubmitting && <Button
                /*disabled={
                  loader ||
                  disabledButton ||
                  !cguChecked 
                  // ||
                  // !reservationInfo?.clientSecret ||
                  // reservationInfo?.clientSecret === ""
                  // (price > 0 &&
                  //   ())
                }*/
                btnContent={t("reservationStep2.regler")}
                customStyle={
                  "btn-principal w-full xs:w-3/5 mt-2 inline-block text-white rounded-lg px-10 sm:px-14"
                }
                onClick={handleSubmit}
              />}

              <Button
                disabled={disabledButton}
                btnContent={t("reservationStep2.precedent")}
                customStyle={
                  "bg-redDanger w-full xs:w-3/5	mt-2 hover:bg-redHover inline-block text-white rounded-lg px-10 sm:px-14"
                }
                onClick={handleCancel}
              />
            </div>
          </div>
        )}

        {openToast && (
          <Toast
            responseType={responseType}
            message={message}
            open={openToast}
            close={handleCloseToast}
            duration={time}
          />
        )}
        {loader && (
          <div className="flex items-center justify-center my-2">
            <Loader />
          </div>
        )}
        {/* Modal cgu/ coupon */}
        <Modal
          isOpen={openModal.value}
          onClose={handleCloseModal}
          full={openModal.type === "cgu" ? true : false}
          title={
            openModal.type === "cgu"
              ? "Conditions d'utilisation"
              : t("reservationStep2.ajouterCoupon")
          }
        >
          {openModal.type === "cgu" ? (
            <div className="max-h-50vh">
              <Cgu full={true} />
            </div>
          ) : (
            <div>
              <input
                type="text"
                onChange={(e) => {
                  setCoupon(e.target.value);
                }}
                value={coupon}
                name="coupon"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-lightGreen focus:border-lightGreen block w-full p-2.5 "
                placeholder={t("reservationStep2.entrerCodePromo")}
              />
              <Button
                disabled={disabledButton || !coupon || couponApplied}
                btnContent={t("reservationStep2.confirmer")}
                customStyle={
                  "btn-principal w-full mt-2 inline-block text-white rounded-lg px-10 sm:px-14"
                }
                type="button"
                onClick={handleCoupon}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  } else {
    return null;
  }
};

export default PaiementBox;
