/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */

import { publicRequest} from './requestMethods'

//to trigger the send mail action
export const preRegister = async (data,callback) => {

  
  try {
    const res = await publicRequest.post("auth/sendToken", data);
    
    if (res) {
     
      return callback(null, res);
    }
   
  } catch (error) {
    return callback(error, null);
  }
};

export const emailPasswordRegister = async (data,callback) => {

  
  try {
    const res = await publicRequest.post("auth/register", data);
    
    if (res) {
     
      return callback(null, res);
    }
   
  } catch (error) {
    return callback(error, null);
  }
};

export const validateEmailWithCode = async (data,callback) => {

  
  try {
    const res = await publicRequest.post("auth/validateEmail", data);
    
    if (res) {
     
      return callback(null, res);
    }
   
  } catch (error) {
    return callback(error, null);
  }
};

export const loginEmailWithPwd = async (data,callback) => {

  
  try {
    const res = await publicRequest.post("auth/login", data);
    
    if (res && res?.data?.email) {
      localStorage.setItem('user',JSON.stringify(res.data))
      
      return callback(null, res.data);
    } else {
      return callback(res.data, null);
    }
   
  } catch (error) {
    return callback(error, null);
  }
};

export const sendPwdResetrequest = async (data,callback) => {

  
  try {
    const res = await publicRequest.post("auth/sendTempPwd", data);
    
    if (res && res?.data?.success === true) {
      // localStorage.setItem('user',JSON.stringify(res.data))
      
      return callback(null, res.data);
    } else {
      return callback(res.data, null);
    }
   
  } catch (error) {
    return callback(error, null);
  }
};

export const resetPasswordNow = async (data,callback) => {

  
  try {
    const res = await publicRequest.put("auth/resetPassword", data);
    
    if (res && res?.data?.success === true) {
      // localStorage.setItem('user',JSON.stringify(res.data))
      
      return callback(null, res.data);
    } else {
      return callback(res.data, null);
    }
   
  } catch (error) {
    return callback(error, null);
  }
};


/*to get the accessToken, refreshToken and know if it"s login or registry operation */

export const register = async (data,callback, dispatch) => {
    try {
      const res = await publicRequest.post('auth/sign',
        data
      )
      if (res) {
        localStorage.setItem('user',JSON.stringify(res.data))
        
        return callback(null, res);
      }
    } catch (error) {
    
      // dispatch(loginFailure())
      return callback(error, null);
    }
}


// refreshToken

// Logout user : 

export const logout = async (refreshToken,callback, dispatch) => {
 
  try {
    const res = await publicRequest.post("auth/logout", { refreshToken });
    if (res) {
      // dispatch(logoutUser())
      return callback(null, res);
    }
   
  } catch (error) {
    return callback(error, null);
  }
};