import { priceReservationPer15min } from "../data/constans";
// Regex

// Valide email regex

export const isvalidEmail = (email) => {
  const isValidEmail = /\S+@\S+\.\S+/;
  if (isValidEmail.test(email)) {
    return true;
  } else {
    return false;
  }
};

// VAlid phone number (void or 10 numbers) regex

export const isValidPhoneNumber = (phone, format) => {
  const condition =
    format === 1
      ? /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/
      : /^\d{9,10}$/;
  const test = condition.test(phone);

  if (test) {
    return false;
  } else {
    return true;
  }
};

// Valid name bettween 20 characters and accept latin regex

export const isValidName = (name) => {
  const isValidName = /^[\p{L}'-]+([\s][\p{L}'-]+)*$/u;
  if (isValidName.test(name)) {
    return true;
  } else {
    return false;
  }
};

// valid card number simple regex

export const isValidNumberCard = (numberCard) => {
  const isValidCardNumber = /^[0-9]{13,16}$/;

  if (isValidCardNumber.test(numberCard)) {
    return true;
  }
  return false;
};

// Valid CVC :

export const isValidCvc = (cvc) => {
  const validCvc = /^[0-9]{3,4}$/;

  if (validCvc.test(parseInt(cvc))) {
    return true;
  } else {
    return false;
  }
};

// Is valid mm/aa :

export const isValidCardDate = (cardDate) => {
  const isValidCardDate = /^(0[1-9]|1[0-2])\/([0-9]{2})$/;

  if (isValidCardDate.test(cardDate)) {
    return true;
  }
  //  Expired
  return false;
};

// reduce time by 15minutes
function reduceTimeBy15Minutes(timeString) {
  // Parse time string into minutes
  const [hours, minutes] = timeString.split(":").map(Number);
  const totalMinutes = hours * 60 + minutes;

  // Subtract 15 minutes (20) after
  const newTotalMinutes = Math.max(0, totalMinutes - 15);

  // Format back into time string
  const newHours = Math.floor(newTotalMinutes / 60)
    .toString()
    .padStart(2, "0");
  const newMinutes = (newTotalMinutes % 60).toString().padStart(2, "0");
  return `${newHours}:${newMinutes}`;
}

/// Reservation:

// Retrive not available hours per day:

export const getNotAvailableHours = (date) => {
  const dates = JSON.parse(JSON.stringify(date));
  for (const time in dates) {
    let end = dates[time].end;
    dates[time].end = reduceTimeBy15Minutes(end);
  }

  let intervals = [];
  for (const [hour, value] of Object.entries(dates)) {
    const flag=false;
    if(flag){
      // console.log(hour)
    }
    let [startHour, startMinute] = value.start.split(":").map(Number);
    let [endHour, endMinute] = value.end.split(":").map(Number);

    while (
      startHour < endHour ||
      (startHour === endHour && startMinute <= endMinute)
    ) {
      intervals.push(
        `${startHour < 10 ? "0" + startHour : startHour}h${
          startMinute < 10 ? "0" + startMinute : startMinute
        }`
      );

      startMinute += 15;
      if (startMinute >= 60) {
        startMinute = 0;
        startHour += 1;
      }
    }
  }
  const keyValue = intervals.reduce((obj, hour) => {
    obj[hour] = hour;
    return obj;
  }, {});

  return keyValue;
};

/// Formate datePicker to a valid date

export const formateDate = (theDate) => {
  if (theDate.length === 16) {
    return theDate.substring(0, 10);
  }
  const date = new Date(theDate);
  const offset = date.getTimezoneOffset() * 60 * 1000;
  const correctedDate = new Date(date.getTime() - offset);
  const formattedDate = correctedDate.toISOString().substring(0, 10);
  return formattedDate;
};

// Compate dates

export const compareDates = (date1, date2) => {
  let d1 = new Date(date1);
  let d2 = new Date(date2);

  if (d1 >= d2) {
    return true;
  } else {
    return false;
  }
};

// Get today date in this format yyyy-mm-dd

export const getTodayDate = () => {
  const now = new Date();
  const today = new Date(
    now.toLocaleString("en-US", { timeZone: "Europe/Paris" })
  );
  //const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, "0");
  const day = today.getDate().toString().padStart(2, "0");
  const todayDate = `${year}-${month}-${day}`;
  return todayDate;
};

//  date => mounth and day names english/french format

// example: "Friday, November 11, 2023" "vendredi 11 novembre 2023"

export const formatDateLocaleLang = (dataDate, lang) => {
  const date = new Date(dataDate);
  const options = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  if (lang === "fr") {
    const fr = new Intl.DateTimeFormat("fr", options);
    let formattedDate = fr.format(date);
    return formattedDate;
  } else if (lang === "en") {
    const enUS = new Intl.DateTimeFormat("en-US", options);
    let formattedDate = enUS.format(date);
    return formattedDate;
  }
};

/// Calculate time differance

export const calculateTimeDifference = (start, end, typeData) => {
  const startParts = start.split(" ");
  const endParts = end.split(" ");

  const startTime = startParts[0].split("h");
  const startDate = startParts[1].split("-");
  const endTime = endParts[0].split("h");
  const endDate = endParts[1].split("-");

  const startDateObject = new Date(
    startDate[0],
    startDate[1] - 1,
    startDate[2],
    startTime[0],
    startTime[1]
  );
  const endDateObject = new Date(
    endDate[0],
    endDate[1] - 1,
    endDate[2],
    endTime[0],
    endTime[1]
  );
  const timeDifference = endDateObject - startDateObject;
  const hours = Math.floor(timeDifference / (1000 * 60 * 60));
  const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

  let result = "";
  if (hours > 0) result += `${hours}h`;
  if (minutes > 0) result += `${minutes}min`;

  if (typeData === "duration") {
    return result;
  } else {
    // (difference / 15) * priceReservationPer15min).toFixed(2)
    const hoursToMinutes = hours >= 1 ? hours * 60 : 0;
    const totalPrice = (
      ((hoursToMinutes + minutes) / 15) *
      priceReservationPer15min
    ).toFixed(2);

    return totalPrice > 0 ? totalPrice : "";
  }
};

// Valide date??

export const isValidDate = (startDate, endDate) => {
  const startDateArray = startDate.split(" ");
  const endDateArray = endDate.split(" ");
  const startDateTime = startDateArray[0].split("h");
  const endDateTime = endDateArray[0].split("h");
  const startDateFull = new Date(
    `${startDateArray[1]}T${startDateTime[0]}:${startDateTime[1]}:00`
  );
  const endDateFull = new Date(
    `${endDateArray[1]}T${endDateTime[0]}:${endDateTime[1]}:00`
  );
  return startDateFull < endDateFull;
};

// Get the time of the moment in this format 08h00

export const momentTime = () => {
  const today = new Date();
  const hours = today.getHours();
  const minutes = today.getMinutes();
  const time =
    (hours < 10 ? "0" : "") + hours + "h" + (minutes < 10 ? "0" : "") + minutes;
  return time;
};

// Has time passed?

export function hasTimePassed(dateStr) {
  const now = new Date();
  const currentDate = new Date(
    now.toLocaleString("en-US", { timeZone: "Europe/Paris" })
  );
  //const currentDate = new Date();
  const currentTimestamp = currentDate.getTime();
  const [timeHour, timeMinutes, year, month, day] = dateStr
    .split(" ")[0]
    .split("h")
    .concat(dateStr.split(" ")[1].split("-").map(Number));
  const inputTimestamp = new Date(
    year,
    month - 1,
    day,
    timeHour,
    timeMinutes
  ).getTime();
  return currentTimestamp >= inputTimestamp;
}

// Check if in interval:

export const checkIfIntervalAvailable = (startDate, endDate, intervals) => {
  if (!intervals) {
    return true;
  }

  let [startH, startM] = startDate.split(":")[0].split("h");
  let [endH, endM] = endDate.split(":")[0].split("h");
  let [startYear, startMonth, startDay] = startDate.split(" ")[1].split("-");
  let [endYear, endMonth, endDay] = endDate.split(" ")[1].split("-");

  startH = parseInt(startH);
  startM = parseInt(startM);
  endH = parseInt(endH);
  endM = parseInt(endM);
  startYear = parseInt(startYear);
  startMonth = parseInt(startMonth);
  startDay = parseInt(startDay);
  endYear = parseInt(endYear);
  endMonth = parseInt(endMonth);
  endDay = parseInt(endDay);
  let startTimestamp = new Date(
    startYear,
    startMonth - 1,
    startDay,
    startH,
    startM
  ).getTime();
  let endTimestamp = new Date(
    endYear,
    endMonth - 1,
    endDay,
    endH,
    endM
  ).getTime();

  /* if (startTimestamp > endTimestamp) {
    return false;
  }*/

  for (let i = startTimestamp; i <= endTimestamp; i += 1000 * 60 * 15) {
    let date = new Date(i);
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let dateString = `${year}-${month < 10 ? "0" + month : month}-${
      day < 10 ? "0" + day : day
    }`;
    let timeString = `${hours < 10 ? "0" + hours : hours}:${
      minutes < 10 ? "0" + minutes : minutes
    }`;
    if (intervals[dateString] && intervals[dateString][timeString]) {
      return false;
    }
  }

  return true;
};

/// Transform new backend date format to the old formate

export const transformSchedule = (schedule) => {
  const transformedSchedule = {};

  schedule.forEach((event) => {
    const startDateTime = event.startDate;
    const endDateTime = event.endDate;

    const dateKey = startDateTime.slice(0, 10);

    if (!transformedSchedule[dateKey]) {
      transformedSchedule[dateKey] = {};
    }

    const startTimeKey = startDateTime.slice(11, 16);
    const endTimeKey = endDateTime.slice(11, 16);

    transformedSchedule[dateKey][startTimeKey] = {
      start: startTimeKey,
      end: endTimeKey,
    };
  });

  return transformedSchedule;
};

// Get current (hour and minutes)

export const getStartAndEndHours = () => {
  //const now = new Date(Date.parse(new Date().toLocaleString("fr-FR", { timeZone: "Europe/Paris" })));

  ////////////////

  const nooow = new Date();
  const now = new Date(
    nooow.toLocaleString("en-US", { timeZone: "Europe/Paris" })
  );

  //const now = new Date()

  // Round minutes up to nearest quarter hour
  const minutes = now.getMinutes() + 1;
  const roundedMinutes = Math.ceil(minutes / 15) * 15;

  // Calculate start and end times
  const startDate = new Date(now);
  startDate.setMinutes(roundedMinutes);
  startDate.setSeconds(0);
  startDate.setMilliseconds(0);

  const endDate = new Date(startDate);

  endDate.setMinutes(startDate.getMinutes() + +15);

  // Format dates as strings in desired format
  const startDateString = `${
    startDate.getHours() > 9 ? startDate.getHours() : "0" + startDate.getHours()
  }h${startDate.getMinutes().toString().padStart(2, "0")}`;
  const endDateString = `${
    endDate.getHours() > 9 ? endDate.getHours() : "0" + endDate.getHours()
  }h${endDate.getMinutes().toString().padStart(2, "0")}`;

  return { start: startDateString, end: endDateString };
};

// french Format
export const frenchFormat = (date) => {
  const [year, month, day] = date.split("-");
  return `${day}-${month}-${year}`;
};

// transform this format 2023-02-25T17:30:00.000Z ===> to 17h30 (get hh(h)mm )

export const formatTime = (dateStr) => {
  const hours = parseInt(dateStr.slice(11, 13));
  const minutes = dateStr.slice(14, 16);
  const formattedTime = `${hours.toString().padStart(2, "0")}h${minutes}`;
  return formattedTime;
};

export const isWithin5Minutes = (dateString) => {
  const date = new Date(dateString);
  const now = new Date();

  const dateHours = date.getUTCHours();
  const dateMinutes = date.getUTCMinutes();
  const nowHours = now.getHours();
  const nowMinutes = now.getMinutes();

  const diffInMinutes = Math.abs(
    dateHours * 60 + dateMinutes - (nowHours * 60 + nowMinutes)
  );

  return diffInMinutes <= 5;
};

export const isTimeDifferance15MIn = (startDate, endDate) => {
  if (startDate.substring(5) !== endDate.substring(5)) {
    return false;
  }

  // get format 15h00 etc
  const startHour = startDate.substring(0, 5);
  const endHour = endDate.substring(0, 5);
  const [hours1, minutes1] = startHour
    .split("h")
    .map((str) => parseInt(str, 10));
  const [hours2, minutes2] = endHour.split("h").map((str) => parseInt(str, 10));

  const totalMinutes1 = hours1 * 60 + minutes1;
  const totalMinutes2 = hours2 * 60 + minutes2;
  const diffInMinutes = Math.abs(totalMinutes2 - totalMinutes1);
  return diffInMinutes === 15;
};

// Minus 15 minutes

export const getMinus15Minutes = (dateString) => {
  // Split the date string into hours and minutes
  const [hours, minutes] = dateString.split("h").map(Number);

  // Convert the time to minutes
  const totalMinutes = hours * 60 + minutes;

  // Subtract 15 minutes
  const minus15Minutes = totalMinutes - 15;

  // Convert the result back to hours and minutes
  const resultHours = Math.floor(minus15Minutes / 60);
  const resultMinutes = minus15Minutes % 60;

  // Format the result as "hh'h'mm"
  const formattedMinutes =
    resultMinutes < 10 ? `0${resultMinutes}` : `${resultMinutes}`;
  const formattedDate =
    resultHours < 10
      ? `0${resultHours}h${formattedMinutes}`
      : `${resultHours}h${formattedMinutes}`;

  return formattedDate;
};

// add 15minutes

// timeString = 15h00 (example) output = 15h15
export const add15Minutes = (timeString) => {
  const [hours, minutes] = timeString.trim().split("h");
  const newMinutes = parseInt(minutes) + 15;
  const newHours = parseInt(hours) + Math.floor(newMinutes / 60);
  const finalHours = newHours % 24;
  const finalMinutes = newMinutes % 60;
  return `${String(finalHours).padStart(2, "0")}h${String(
    finalMinutes
  ).padStart(2, "0")}`;
};

/// isCurrentTimeBetween

export function isCurrentTimeBetween(startDate, endDate) {
  
if(startDate && endDate){
  const dateStart = startDate.substring(0, 10).replace(/-/g, "/");
  const dateEnd = endDate.substring(0, 10).replace(/-/g, "/");
  const hourStart = startDate.substring(11);
  const hourEnd = endDate.substring(11);
  //const currentTime = new Date();

  const now = new Date();
  const currentTime = new Date(
    now.toLocaleString("en-US", { timeZone: "Europe/Paris" })
  );
  const startDateTime = new Date(dateStart + " " + hourStart);
  const endDateTime = new Date(dateEnd + " " + hourEnd);
  return currentTime >= startDateTime && currentTime <= endDateTime;
}
}

export function isStartDateCloseToOneHour(dateString) {
  const [date, time] = dateString.split(" ");
  const [year, month, day] = date.split("-");
  const [hour, minute] = time.split(":");

  const startDateTime = new Date(Date.UTC(year, month - 1, day, hour, minute));
  //const currentDateTime = new Date();

  const now = new Date();
  const currentDateTime = new Date(
    now.toLocaleString("en-US", { timeZone: "Europe/Paris" })
  );

  // Set the timezone to Paris time
  //const parisTimezoneOffset = 60 * 60 * 1000;
  //  + parisTimezoneOffset
  const parisCurrentDateTime = new Date(currentDateTime.getTime());
  const diffInMs = Math.abs(startDateTime - parisCurrentDateTime);
  const diffInHours = diffInMs / (1000 * 60 * 60);

  return diffInHours <= 1;
}

//string format or date format ==> string yyyy/mm/dd
export const getTomorrowDate = (dateFormat)=>{
  const now = new Date();
  const today = new Date(now.toLocaleString('en-US', { timeZone: 'Europe/Paris' }));
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const year = tomorrow.getFullYear();
  const month = (tomorrow.getMonth() + 1).toString().padStart(2, "0");
  const day = tomorrow.getDate().toString().padStart(2, "0");
  const tomorrowDate = `${year}-${month}-${day}`;
  if(dateFormat==="string"){
    return tomorrowDate;
  }
  return tomorrow;

}


//ccapitalize name

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1)
}