
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import data from "../../data/data"
import { AirIcon, EuroIcon, Location2Icon, Phone2Icon, PlaceIcon, TimeIcon } from '../../data/Icons';
import MapxBox from "./MapBox";
import { useTranslation } from "react-i18next";
import { priceReservationPer15min } from "../../data/constans";
const CapsuleDetails = ()=>{
  const {t} = useTranslation();
    const {fr:{capsuleDetails:{details}}} = data;
    const {fr:{capsuleDetails:{around}}} = data;



    return(
        <div className="mt-5 ">

{/* Video */}
   <h2 className="detail-title mt-8">{t("capsulePage.video")}</h2>
        <div className="responsive-video mb-5 md:max-w-80 lg:max-w-[600px]">
        <iframe src="https://www.youtube-nocookie.com/embed/vD8Y_lb9Fo4" title="Ecapsule lyon" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
</div>

        {/* More details about capsule */}
        <div >
        {/* Details: */}
        <h2 className="detail-title">{t("capsulePage.informations")}</h2>
        <div className="capsule-detail-items text-midGreey">
        {/* Time */}
        <div className="detail-item ">
          <span> <TimeIcon/> </span>
          <p>{t("capsulePage.disponible2")}</p>
        </div>
        {/* Location */}
        <div className="detail-item ">
          <span> <Location2Icon/> </span>
          <p>{t("capsulePage.mapTitle")}</p>
        </div>
       {/*  Location more infos        */}
        <div className="detail-item ">
          <span> <PlaceIcon/> </span>
          <p>{t("capsulePage.mapInfo")}</p>
        </div>
        {/* Price */}
        <div className="detail-item ">
          <span> <EuroIcon/> </span>
          <p>{t("capsulePage.prixParagraph")+" "+priceReservationPer15min} €</p>
        </div>
        {/* Phone */}
        <div className="detail-item ">
          <span><Phone2Icon/></span>
          <p>{details.phone}</p>
        </div>
        {/* Air */}
        <div className="detail-item ">
          <span><AirIcon/></span>
          <p>{t("capsulePage.air")}</p>
        </div>
        </div>
        {/* Around */}
        <h2 className="detail-title mt-8">{t("capsulePage.alentours")}</h2>
        <div className="around-content">
        <ul>
        {around.content.map((item,index)=>{
            return(
                <li className="text-darkGreey text-xs sm:text-base mb-3" key={index}>- {t("capsulePage."+item)}</li>
            )
        })}
        </ul>
        </div>
        </div>


        {/* Map */}

      <div className="mapBox">
<h2 className="detail-title">{t("capsulePage.emplacement")}</h2>
<MapxBox/>
</div>
        
        </div>
    )
}


export default CapsuleDetails;