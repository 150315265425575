/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { useState, useEffect } from "react";
import { CloseIcon } from "../../data/Icons";
import {useOutsideClick} from "../../utils/hooks";
import Loader from "./Loader";
const Modal = ({ isOpen, onClose, children, title,full, runLoader }) => {
 
  const [isShowing, setIsShowing] = useState(false);

  const closeModal = () => {
    setIsShowing(false);
    onClose();
  };

  // Click outside
  const handleClickOutside = (event) => {
  
    if (
      ref.current && !event.target.closest(".btn-sendMail") && 
      !event.target.closest(".showPassword")
     && !ref.current.contains(event.target) &&
      !event.target.closest(".toast") && !event.target.closest(".MuiDateCalendar-root")){
        setIsShowing(false);
        onClose();
    }
  };
  const ref = useOutsideClick(handleClickOutside);


  useEffect(() => {
      setIsShowing(isOpen);
  }, [isOpen]);

  useEffect(() => {
    if (isShowing && document.querySelector("#modal-box")) {
      document.body.classList.add("overflow-y-hidden");
    } else {
      document.body.classList.remove("overflow-y-hidden");
    }
  }, [isShowing]);
  
    return (
      <>
        {isShowing && (
          <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center z-50" id="modal-box">
            <div className="absolute top-0 left-0 w-full h-full bg-gray-900 opacity-50"></div>
            <div ref={ref} className={`bg-white modalResHeight  scale-in-center ${full?"w-95 h-90vh xs:w-11/12  md:w-2/3":"w-11/12 md:max-w-md"}  mx-auto rounded shadow-2xl
z-50 overflow-y-auto`}>
              <div className="py-4 text-left px-6 ">
                <div className="flex  justify-between items-center pb-3">
                 {title && <h2 className="font-bold text-darkBlue uppercase">{title}</h2>}
                  <button
                    className="text-2xl border border-grey  p-1 font-bold leading-none"
                    onClick={closeModal}
                  >
                     
                     <CloseIcon color="text-darkBlue"/>
                  </button>
        
                </div>
                <hr/>
                <div className="my-5">
                {children}
                {runLoader && (
        <div className="flex items-center justify-center mt-5 -mb-5">
          <Loader />
        </div>
      )}
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

export default Modal;