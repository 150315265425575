/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { ProfileIcon } from "../../data/Icons";
import { useState, useEffect } from "react";
import Button from "../ui/Button";
import ModalTwoSteps from "../widgets/ModalTwoSteps";
import { useForm } from "react-hook-form";
import Toast from "../ui/Toast";
import {isValidName } from "../../utils/utils";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import { useTranslation } from "react-i18next";


const Compte = ({ close }) => {
  const {t}= useTranslation();
  const [showModalRemove, setModalRemove] = useState(false);
  const { register, handleSubmit, setValue, resetField, watch,  } = useForm();
  const [dataFromApi, setDataFromApi] = useState(null);
  const axiosPrivate = useAxiosPrivate();
  const currentUser = JSON.parse(localStorage.getItem("user"));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [newUserData, setNewUserData] = useState(null);
  const [modalType,setModalType]= useState({modalType:"",title:""})

  //Toast props  // responseType => "success" or "error"

  const [openToast, setOpenToast] = useState(false);
  const [message, setMessage] = useState("");
  const [responseType, setResponseType] = useState("success");
  const handleCloseToast = () => {
    setOpenToast(false);
  };

  const displayToast = (message, type) => {
    setMessage(message);
    setResponseType(type);
    setOpenToast(true);
  };


  useEffect(() => {
    const controller = new AbortController();
    
    
    if (currentUser) {
      setDataFromApi(currentUser);
      return;
    }
    
    const fetchData = async () => {
      try {
        const res = await axiosPrivate.get("user");

        if (res) {
          setDataFromApi(res.data);
        }
      } catch (error) {
        return;
      }
    };

    fetchData();

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    if (dataFromApi) {
      setValue("first", dataFromApi.name?.first || "");
      setValue("last", dataFromApi.name?.last || "");
      setValue("email", dataFromApi.email || "");
      setValue("phoneNumber", (dataFromApi.phoneNumber?"+"+dataFromApi.phoneNumber:""));
      setValue("newsLetterSub", dataFromApi.newsLetterSub || false);
      setValue("invoiceSub", dataFromApi.invoiceSub || false);
    }
  }, [dataFromApi, setValue]);

  const handleSend = (data) => {
    setIsSubmitting(true);
    setNewUserData({ ...data });
  };

  useEffect(() => {
    const controller = new AbortController();

    if (!isSubmitting) {
      return () => controller.abort();
    }

    ///update user function
    
    const updateUser = async (data) => {
      let dataToSend;
    if(currentUser.name){
      dataToSend={newsLetterSub: data.newsLetterSub,
        invoiceSub: data.invoiceSub,}
    }
    else{
      dataToSend= {
        name: { first: data.name?.first?.trim() , last: data.name?.last?.trim()  },
        email: data.email,
        newsLetterSub: data.newsLetterSub,
        invoiceSub: data.invoiceSub,
      };
    }

      try {
        const res = await axiosPrivate.patch("user", {
          ...dataToSend
        });

        if (res) {
          displayToast(t("compte.informationsSaved"), "success");
          const currentUser = JSON.parse(localStorage.getItem("user"));
          currentUser.name = currentUser.name || {};
          currentUser.name.first = res.data.name.first;
          currentUser.name.last =res.data.name.last;
          currentUser.newsLetterSub = res.data.newsLetterSub;
          currentUser.invoiceSub = res.data.invoiceSub;
          localStorage.setItem("user", JSON.stringify(currentUser));
          const inModal = document.querySelector("#authentication-modal");

          if (inModal) {
            setTimeout(() => {
              close();
            }, 2000);
          }
        }
      } catch (error) {
        if (error.response.status !== 401) {
          displayToast(t("compte.errorMsgRequiredNomPrenom"), "error");
        }
        displayToast(t(error.message), "error")
      }
    };

    // Conditions:
    const IsvalidFirst = isValidName(watch("first"));
    const IsvalidLast = isValidName(watch("last"));
    const inputFields = [
      { name: t("compte.prenom"), isValid: IsvalidFirst, key: "first" },
      { name: t("compte.nom"), isValid: IsvalidLast, key: "last" },

    ];

    const invalidFields = inputFields.filter((field) => !field.isValid);

    // if invalid nom or prénom
    if ((invalidFields.length)) { 
      let errorMessage = t("compte.veuillezEntrer");
      invalidFields.forEach((field, index) => {
        errorMessage += field.name;
        if (index < invalidFields.length - 1) {
          errorMessage += ", ";
        }
      });
      displayToast(errorMessage, "error");

      invalidFields.forEach((field) => {
        resetField(field.key);
      });
      setIsSubmitting(false);
    }
    // if lastName="" and firstName!="" (opposite also) ==> do something
    else if((watch("first")!=="" && watch("last")==="") || ((watch("last")!=="" && watch("first")===""))){
      displayToast(t("compte.errorMsgRequiredNomPrenom"), "error");
      setIsSubmitting(false)
      
    }


    // if no errors.
   
    
    else {
      const currentUser = JSON.parse(localStorage.getItem("user"));
      updateUser({
        name: {
          first: newUserData?.first
            ? newUserData?.first
            : currentUser?.name?.first,
          last: newUserData?.last ? newUserData?.last : currentUser?.name?.last,
        },
        email: newUserData?.email,
        phoneNumber: newUserData?.phoneNumber,
        newsLetterSub: newUserData?.newsLetterSub,
        invoiceSub: newUserData?.invoiceSub,
      });
    }
    
  }, [isSubmitting, newUserData]);

  
  return (
    <div className="min-h-50">
      <div className="flex flex-col items-center justify-center py-4 bg-whisperGreey  -mt-5">
        <div className="bg-white rounded-full p-5">
          <ProfileIcon/>
        </div>
        
          <h2 className="mt-2 text-darkBlue">
            {/* dataFromApi.name.first */}
            <span className="inline capitalize">{watch("first")} </span>
            {/* dataFromApi.name.last */}
            <span className="inline capitalize">{watch("last")}</span>
          </h2>
          
      </div>
      <div className="width-padding text-xl ">
        <form onSubmit={handleSubmit(handleSend)} className="mb-10 form-account lg:py-20 sm:max-w-70 mx-auto">
          <h3 className="text-darkBlue font-bold my-4">
            {t("compte.informations")} {document.querySelector("#authentication-modal") && "(facultatif)"}
          </h3>
          <div className="grid gap-10 md:grid-cols-2">
            <div>
              {/* First NAME */}
              <label
                htmlFor="first"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                {t("compte.prenom")}
              </label>
              <input 
                type="text"
                id="first"
                // readOnly={dataFromApi?.name?.last?true:undefined}
                maxLength={20}
                {...register("first")}
                name="first"
                className="bg-gray-50 border capitalize text-gray-900 text-sm rounded-lg focus:border-darkGreen block w-full p-2.5 "
              />
            </div>
            <div className="">
              {/* LAST NAME */}
              <label
                htmlFor="last"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                {t("compte.nom")}
              </label>
              <input
                type="text"
                name="last"
                maxLength={20}
                // readOnly={dataFromApi?.name?.last?true:undefined}
                {...register("last")}
                id="last"
                className="bg-gray-50 border capitalize text-gray-900 text-sm rounded-lg focus:border-darkGreen block w-full p-2.5 "
              />
            </div>
            <div className="">
              {/* EMAIL */}
              <label
                htmlFor="email"
                className="block mb-2 text-sm font-medium text-gray-900"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                readOnly
                {...register("email")}
                id="email"
                className="bg-gray-50 border  text-gray-900 text-sm rounded-lg focus:border-darkGreen block w-full p-2.5 "
              />
            </div>
            {/* PHONE */}
            <div className="">
              <label
                htmlFor="phoneNumber"
                className="block mb-2 text-sm font-medium text-gray-900 "
              >
                {t("compte.telephone")}
              </label>
             
              <input
                type="tel"
                name="phoneNumber"
                maxLength={10}
                // readOnly
                {...register("phoneNumber")}
                id="phoneNumber"
                className="bg-gray-50 border  text-gray-900 text-sm rounded-lg 
                focus:border-darkGreen block w-full p-2.5 "
                onClick={(()=>{
                  setModalType({modalType:"modalPhone",title:t("compte.modifPhone")})
                  setModalRemove(true);

                })}
              /> 
   
            </div>
          </div>
          <div className="flex flex-wrap items-start  flex-col mb-6 mt-4 gap-y-5">
            {/* NewsLetter */}
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                {...register("newsLetterSub")}
                name="newsLetterSub"
              />
              <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                peer-checked:after:translate-x-full input-checkbox peer-checked:after:border-white
                 after:content-[''] after:absolute after:top-1/2 after:left-[2px]
                  after:bg-white after: after:border after:rounded-full
                 after:-translate-y-1/2  after:h-5 after:w-5 after:transition-all  peer-checked:bg-darkGreen"></div>
              <span className="ml-3 label-sub text-sm font-medium text-gray-900 ">
              {t("compte.inscrireNewsletter")}
              </span>
            </label>
            {/* FACTURE */}
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                {...register("invoiceSub")}
                name="invoiceSub"
              />
               <div className="w-11 h-6 bg-gray-200 rounded-full peer peer-focus:ring-4 peer-focus:ring-blue-300
                peer-checked:after:translate-x-full peer-checked:after:border-white
                 after:content-[''] after:absolute after:top-1/2 after:left-[2px]
                  after:bg-white after: after:border after:rounded-full input-checkbox
                 after:-translate-y-1/2  after:h-5 after:w-5 after:transition-all  peer-checked:bg-darkGreen"></div>
              <span className="ml-3 label-sub  text-sm font-medium text-gray-900 ">
              {t("compte.receptionFacture")}
              </span>
            </label>
          </div>
          <div className="grid btns-account gap-1 md:gap-2   lg:max-w-80 xl:max-w-70  semimd:grid-cols-2">
          <Button
            btnContent= {t("compte.enregistrer")}
            customStyle={
              "btn-principal rounded-lg	 text-white mt-5 "
            }
            type="submit"
          />
          <Button
            onClick={() => {
              setModalType({modalType:"modalRemove",title:t("compte.supprimerCompte")+"?"})
              setModalRemove(true);
            }}
            btnContent={t("compte.supprimerCompte")}
            customStyle={
              "bg-redDanger rounded-lg	 removeAccount hover:bg-redHover block lg:inline-block  text-white mt-5 lg:ml-4"
            }
            type="button"
          />
          </div>
        </form>
      </div>

      <ModalTwoSteps
        show={showModalRemove}
        type={modalType}
        newPhone={(newData)=>{
          setValue("phoneNumber",newData)
        }}
        onClose={() => {
          setModalRemove(false);
        }}
      />
      {openToast && (
        <Toast
          responseType={responseType}
          message={message}
          open={openToast}
          close={handleCloseToast}
          duration={5000}
        />
      )}
    </div>
  );
};

export default Compte;
