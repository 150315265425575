/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import { finishedPayment } from "../../redux/paymentSlice";
import { useNavigate } from "react-router";
import { BackIcon, ClockIcon } from "../../data/Icons";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setReservationDetails } from "../../redux/reservationSlice";
import { frenchFormat } from "../../utils/utils";
import { useTranslation } from "react-i18next";

const BookingConfirmation = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const reservationDetails = useSelector(
    (state) => state.reservations.reservationDetails
  );
  const dispatch = useDispatch();

  return (
    <div>
      <div className="flex justify-between items-center flex-wrap">
        <div className="flex items-center gap-3">
          <ClockIcon />
          <div className="text-darkBlue font-semibold">
            <h2>{t("reservationStep3.merci")}</h2>
            <p>{t("reservationStep3.votreReservation")}</p>
          </div>
        </div>
        <div
          className="cursor-pointer "
          onClick={() => {
            dispatch(finishedPayment(false));
            dispatch(setReservationDetails(null));
            localStorage.removeItem("reservationDetails");
            localStorage.removeItem("finishedPayment");
            navigate("/reservations", { replace: true });
            window.scrollTo(0, 0);
          }}
        >
          <BackIcon />
        </div>
      </div>
      <div className="border-t border-darkGreey -mx-2 lg:-mx-8 mt-4 pt-6 pb-10 px-4 text-darkBlue">
        <p className="font-semibold	">
          {t("reservationStep3.activerOuverture1")}{" "}
          {reservationDetails?.start.substring(0, 5)}{" "}
          {t("reservationStep3.activerOuverture2")}
        </p>
        <p className="font-semibold	 mt-4">
          {t("reservationStep3.recevoirSms")}{" "}
        </p>

        <h2 className="mt-5 font-semibold	">
          {t("reservationStep3.votreReservationMot")}
        </h2>
        <p className="font-bold mt-4">{t("capsulePage.mapTitle")}</p>
        <p className="text-darkBlue mb-2 mt-4 text-sm sm:text-base flex flex-wrap font-medium">
          {t("reservationStep3.le")}&nbsp;
          <span className="font-bold  text-darkBlue">
            {frenchFormat(reservationDetails?.start.substring(6)).replace(
              /-/g,
              "/"
            )}
          </span>
          &nbsp;{t("reservationStep2.de")}&nbsp;
          <span className="font-bold  text-darkBlue">
            {reservationDetails?.start.substring(0, 5)}
          </span>
          &nbsp;{t("reservationStep1.to")}&nbsp;
          <span className="font-bold  text-darkBlue">
            {reservationDetails?.end.substring(0, 5)}
          </span>
          {reservationDetails?.start.substring(6) !==
            reservationDetails?.end.substring(6) && (
            <span className="font-bold  text-darkBlue">
              &nbsp;(
              {frenchFormat(reservationDetails?.end.substring(6)).replace(
                /-/g,
                "/"
              )}
              )
            </span>
          )}
        </p>

        <p className="mt-10 font-semibold">{t("reservationStep3.equipe")}</p>
      </div>
    </div>
  );
};

export default BookingConfirmation;
