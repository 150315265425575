import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  cards: [],
  choosedCard: {},
  loading: false,
  error: false,
  finishPaymentProcess:false
};

export const paymentSlice = createSlice({
  name: "payment",
  initialState,
  reducers: {
    
    getPaymentStart: (state) => {
        state.loading = true
    },

    getPaymentSuccess: (state, action) => {
      state.loading = false;
      if (action.payload) { 
        state.cards = [...state.cards, ...action.payload].filter((value, index, self) =>
          index === self.findIndex((t) => (
            t.cardExternalId === value.cardExternalId
          ))
        )
        state.choosedCard = state.cards.slice(-1)
      }
    },

    getPaymentFailure: (state, action) => {
      state.loading = false
      state.error = true
    },

    deletePaymentStart: (state) => {
        state.loading = true
    },

    deletePaymentSuccess: (state, action) => {
        state.loading = false
        state.cards.splice(
            state.cards.findIndex((item) => item.cardExternalId === action.payload),
            1
        )
        if (action.payload === state.choosedCard.cardExternalId) {
            state.choosedCard = state.cards.slice(-1)
        }
    },

    deletePaymentFailure: (state) => {
        state.loading = false
        state.error = true
    },

    addPaymentStart: (state) => {
        state.loading = true
    },

    addPaymentSuccess: (state, action) => {
        state.loading = false
        state.cards.push(action.payload)
        state.choosedCard = state.cards.slice(-1)
    },

    addPaymentFailure: (state) => {
        state.loading = false
        state.error = true
    },

    finishedPayment: (state,action)=>{
      state.finishPaymentProcess=action.payload;
     
    } 
  },
});

export const {
  getPaymentStart,
  getPaymentSuccess,
  getPaymentFailure,
  deletePaymentStart,
  deletePaymentSuccess,
  deletePaymentFailure,
  addPaymentStart,
  addPaymentSuccess,
  addPaymentFailure,
  finishedPayment
} = paymentSlice.actions;

export default paymentSlice.reducer;
